import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import toastr from "toastr";

// Customer Redux States
import * as TYPES from "./actionTypes";
import * as ACTIONS from "./actions";
import * as API from "helpers/backend_helper";

function* onGetSettings() {
  try {
    const response = yield call(API.getSettings);

    if (response.status == "failure") {
      return yield put(ACTIONS.getSettingsFail(response.message));
    }

    yield put(ACTIONS.getSettingsSuccess(response.data));
  } catch (error) {
    yield put(ACTIONS.getSettingsFail(error));
  }
}

function* onAirDrop({ payload: { data, callback } }) {
  try {
    const response = yield call(API.airDrop, data);
    if (response.status == "failure") {
      callback(response);
      return yield put(ACTIONS.airDropFail(response.message));
    }
    callback(response);
    yield put(ACTIONS.airDropSuccess(response.data));
  } catch (error) {
    yield put(ACTIONS.airDropFail(error));
  }
}

function* onAirDropList({ payload }) {
  try {
    const response = yield call(API.airDropList, payload);
    if (response.status == "failure") {
      return yield put(ACTIONS.airDropListFail(response.message));
    }
    yield put(ACTIONS.airDropListSuccess(response));
  } catch (error) {
    yield put(ACTIONS.airDropListFail(error));
  }
}

function* onPutSettings({ payload }) {
  try {
    const response = yield call(API.putSettings, payload);

    if (response.status == "failure") {
      toastr.error(response.message);
      return yield put(ACTIONS.putSettingsFail(response.message));
    }

    toastr.success(response.message);

    yield put(ACTIONS.getSettings());
    yield put(ACTIONS.putSettingsSuccess());
  } catch (error) {
    yield put(ACTIONS.putSettingsFail(error));
  }
}

function* onPostDeployContract({ payload: { data, callback } }) {
  try {
    const response = yield call(API.postDeployContract, data);

    if (response.status == "failure") {
      return yield put(ACTIONS.postDeployContractFail(response.message));
    }

    callback && callback(response);
    yield put(ACTIONS.postDeployContractSuccess(response.data));
    yield put(ACTIONS.getSettings());
  } catch (error) {
    yield put(ACTIONS.postDeployContractFail("Server Error"));
  }
}

function* onPostDeployContractForMultisend({ payload: { data, callback } }) {
  try {
    const response = yield call(API.postDeployContractForMultisend, data);

    if (response.status == "failure") {
      return yield put(
        ACTIONS.postDeployContractForMultisendFail(response.message)
      );
    }

    callback && callback(response);
    yield put(ACTIONS.postDeployContractForMultisendSuccess(response.data));
    yield put(ACTIONS.getSettings());
  } catch (error) {
    yield put(ACTIONS.postDeployContractForMultisendFail("Server Error"));
  }
}

function* onPostDeployContractGov({ payload: { data, callback } }) {
  try {
    const response = yield call(API.postDeployContractGov, data);

    if (response.status == "failure") {
      return yield put(ACTIONS.postDeployContractFailGov(response.message));
    }

    callback && callback(response);
    yield put(ACTIONS.postDeployContractSuccessGov(response.data));
    yield put(ACTIONS.getSettings());
  } catch (error) {
    yield put(ACTIONS.postDeployContractFailGov("Server Error"));
  }
}
function* onPostDeployContractPpg({ payload: { data, callback } }) {
  try {
    const response = yield call(API.postDeployContractPpg, data);

    if (response.status == "failure") {
      return yield put(ACTIONS.postDeployContractFailPpg(response.message));
    }

    callback && callback(response);
    yield put(ACTIONS.postDeployContractSuccessPpg(response.data));
    yield put(ACTIONS.getSettings());
  } catch (error) {
    yield put(ACTIONS.postDeployContractFailPpg("Server Error"));
  }
}
function* onPostCompileContract({ payload: { data, callback } }) {
  try {
    const response = yield call(API.postCompileContract, data);

    if (response.status == "failure") {
      return yield put(ACTIONS.postCompileContractFail(response.message));
    }

    callback && callback(response);
    yield put(ACTIONS.postCompileContractSuccess(response.data));
    yield put(ACTIONS.getSettings());
  } catch (error) {
    console.log(error, 123);
    yield put(ACTIONS.postCompileContractFail("Server Error"));
  }
}
function* onPostCompileContractPpg({ payload: { data, callback } }) {
  try {
    const response = yield call(API.postCompileContractPpg, data);

    if (response.status == "failure") {
      return yield put(ACTIONS.postCompileContractFailPpg(response.message));
    }

    callback && callback(response);
    yield put(ACTIONS.postCompileContractSuccessPpg(response.data));
    yield put(ACTIONS.getSettings());
  } catch (error) {
    console.log(error, 123);
    yield put(ACTIONS.postCompileContractFailPpg("Server Error"));
  }
}

function* onPostCompileContractGov({ payload: { data, callback } }) {
  try {
    const response = yield call(API.postCompileContractGov, data);

    if (response.status == "failure") {
      return yield put(ACTIONS.postCompileContractFailGov(response.message));
    }

    callback && callback(response);
    yield put(ACTIONS.postCompileContractSuccessGov(response.data));
    yield put(ACTIONS.getSettings());
  } catch (error) {
    console.log(error, 123);
    yield put(ACTIONS.postCompileContractFailGov("Server Error"));
  }
}

function* settingsSaga() {
  yield takeEvery(TYPES.GET_SETTINGS, onGetSettings);
  yield takeEvery(TYPES.AIR_DROP, onAirDrop);
  yield takeEvery(TYPES.AIR_DROP_LIST, onAirDropList);
  yield takeEvery(TYPES.PUT_SETTINGS, onPutSettings);
  yield takeLatest(TYPES.POST_CONTRACT_DEPLOY, onPostDeployContract);
  yield takeLatest(
    TYPES.POST_CONTRACT_DEPLOY_MULTISEND,
    onPostDeployContractForMultisend
  );
  yield takeLatest(TYPES.POST_COMPILE_CONTRACT, onPostCompileContract);
  yield takeLatest(TYPES.POST_COMPILE_CONTRACT_PPG, onPostCompileContractPpg);
  yield takeLatest(TYPES.POST_CONTRACT_DEPLOY_PPG, onPostDeployContractPpg);
  yield takeLatest(TYPES.POST_CONTRACT_DEPLOY_GOV, onPostDeployContractGov);
  yield takeLatest(TYPES.POST_COMPILE_CONTRACT_GOV, onPostCompileContractGov);
}

export default settingsSaga;
