import React from "react";
import { Container } from "reactstrap";

function Privacycontent() {
  return (
    <section className="terms_conditions">
      <Container fluid>
        <div className="terms_page_content mt-5 px-5">
          <h1>Privacy Policy</h1>

          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            Pellentesque vel sodales mauris. Nunc accumsan mi massa, ut maximus
            magna ultricies et:
          </p>

          <ul>
            <li>
              Integer quam odio, ullamcorper id diam in, accumsan convallis
              libero. Duis at lacinia urna.
            </li>
            <li>
              Mauris eget turpis sit amet purus pulvinar facilisis at sed lacus.
            </li>
            <li>
              Quisque malesuada volutpat orci, accumsan scelerisque lorem
              pulvinar vitae.
            </li>
            <li>
              Vestibulum sit amet sem aliquam, vestibulum nisi sed, sodales
              libero.
            </li>
          </ul>

          <h6>Aenean accumsan aliquam justo, et rhoncus est ullamcorper at</h6>

          <p>
            Donec posuere dictum enim, vel sollicitudin orci tincidunt ac.
            Maecenas mattis ex eu elit tincidunt egestas. Vivamus posuere nunc
            vel metus bibendum varius. Vestibulum suscipit lacinia eros a
            aliquam. Sed dapibus arcu eget egestas hendrerit. Vivamus
            consectetur metus at nulla efficitur mattis. Aenean egestas eu odio
            vestibulum vestibulum. Duis nulla lectus, lacinia vitae nibh vitae,
            sagittis interdum lacus. Mauris lacinia leo odio, eget finibus
            lectus pharetra ut. Nullam in semper enim, id gravida nulla.
          </p>

          <h6>
            Fusce gravida auctor justo, vel lobortis sem efficitur id. Cras eu
            eros vitae justo dictum tempor.
          </h6>

          <p>
            Sed sollicitudin, diam nec tristique tincidunt, nulla ligula
            facilisis nunc, non condimentum tortor leo id ex. Vivamus
            consectetur metus at nulla efficitur mattis. Aenean egestas eu odio
            vestibulum vestibulum. Duis nulla lectus, lacinia vitae nibh vitae,
            sagittis interdum lacus. Mauris lacinia leo odio, eget finibus
            lectus pharetra ut. Nullam in semper enim, id gravida nulla.
          </p>

          <p>
            Donec posuere dictum enim, vel sollicitudin orci tincidunt ac.
            Maecenas mattis ex eu elit tincidunt egestas. Vivamus posuere nunc
            vel metus bibendum varius. Vestibulum suscipit lacinia eros a
            aliquam. Sed dapibus arcu eget egestas hendrerit.Donec posuere
            dictum enim, vel sollicitudin orci tincidunt ac. Maecenas mattis ex
            eu elit tincidunt egestas. Vivamus posuere nunc vel metus bibendum
            varius. Vestibulum suscipit lacinia eros a aliquam. Sed dapibus arcu
            eget egestas hendrerit. efficitur
          </p>

          <p>
            Fusce gravida auctor justo, vel lobortis sem efficitur id. Cras eu
            eros vitae justo dictum tempor. Vivamus posuere nunc vel metus
            bibendum varius. Vestibulum suscipit lacinia eros a aliquam. Sed
            dapibus arcu eget egestas hendrerit.Donec posuere dictum enim, vel
            sollicitudin orci tincidunt ac.
          </p>
        </div>
      </Container>
    </section>
  );
}

export default Privacycontent;
