import axios from "axios";
import { post, del, get, put } from "./api_helper";
import * as url from "./url_helper";
import authHeader from "./jwt-token-access/auth-token-header";

// Login Method
export const postLogin = (data) => post(url.POST_LOGIN, data);
export const changeStatus = (data) => post(url.CHANGE_STATUS, data);

// Register
export const postRegisterEmail = (data) => post(url.POST_REGISTER_EMAIL, data);
export const postRegister = (data) => post(url.POST_REGISTER, data);
export const postResetPassword = (data) => post(url.POST_APP_RESET_PASSWORD, data);

// Forgot Password
export const postForgetPwd = (data) => post(url.POST_FORGOT_PASSWORD, data);
export const postResetPwd = (data) => post(url.POST_RESET_PASSWORD, data);

// Profile
export const getCurrentUser = () => get(url.GET_CURRENT_USER);
export const putProfile = (data) => post(url.PUT_PROFILE, data);
export const putChangePassword = (data) => post(url.CHANGE_PASSWORD, data);

// File
export const postFile = (data) => {
  const formData = new FormData();

  Object.entries(data).map(([key, value]) => formData.append(key, value));

  const config = {
    headers: { ...authHeader(), "content-type": "multipart/form-data" },
  };

  return post(url.POST_FILE, formData, config);
};
// File
export const postItemFile = (data) => {
  const formData = new FormData();

  Object.entries(data).map(([key, value]) => formData.append(key, value));

  const config = {
    headers: { ...authHeader(), "content-type": "multipart/form-data" },
  };

  return post(url.POST_ITEM_FILE, formData, config);
};
export const postRemoveFile = (data) => post(url.POST_REMOVE_FILE, data);

// Customers
export const getCustomers = (data) => post(url.GET_USERS, data);
export const removeCustomer = (data) => post(url.REMOVE_USER, data);

export const getCustomer = (id) => get(url.VIEW_USER + "/" + id);

// Customers
export const airDrop = (data) => post(url.AIR_DROP, data);
export const airDropList = (data) => post(url.AIR_DROP_LIST, data);

// Settings
export const getSettings = () => get(url.GET_SETTINGS);
export const putSettings = (data) => post(url.PUT_SETTINGS, data);
export const postDeployContract = (data) =>
  post(url.POST_CONTRACT_DEPLOY, data);
export const postDeployContractForMultisend = (data) =>
  post(url.POST_CONTRACT_DEPLOY_MULTISEND, data);
export const postCompileContract = (data) =>
  post(url.POST_COMPILE_CONTRACT, data);
export const postDeployContractPpg = (data) =>
  post(url.POST_CONTRACT_DEPLOY_PPG, data);
export const postDeployContractGov = (data) =>
  post(url.POST_CONTRACT_DEPLOY_GOV, data);
export const postCompileContractPpg = (data) =>
  post(url.POST_COMPILE_CONTRACT_PPG, data);
export const postCompileContractGov = (data) =>
  post(url.POST_COMPILE_CONTRACT_GOV, data);

// Stake
export const getStakes = (data) => post(url.GET_STAKES, data);
export const getStakeStats = (data) => post(url.GET_STAKE_STATS, data);

// Documents
export const getDocuments = (data) => post(url.GET_DOCUMENTS, data);
export const postDocument = (data) => post(url.ADD_DOCUMENT, data);
export const getDocument = (id) => get(url.GET_DOCUMENT + "/" + id);
export const putDocument = (data) => post(url.PUT_DOCUMENT, data);
export const deleteDocument = (data) => post(url.DELETE_DOCUMENT, data);

// Content Page
export const getContentPages = (data) => post(url.GET_CONTENT_PAGES, data);
export const postContentPage = (data) => post(url.ADD_CONTENT_PAGE, data);
export const getContentPage = (id) => get(url.GET_CONTENT_PAGE + "/" + id);
export const putContentPage = (data) => post(url.PUT_CONTENT_PAGE, data);
export const deleteContentPage = (data) => post(url.DELETE_CONTENT_PAGE, data);
export const putContentPagesStatus = (data) =>
  post(url.PUT_CONTENT_PAGES_STATUS, data);

//collections
export const getCollections = (data) => post(url.GET_COLLECTIONS, data);
export const postCollection = (data) => post(url.POST_COLLECTION, data);
export const putCollection = (data) => post(url.PUT_COLLECTION, data);
export const deleteCollection = (data) => post(url.DELETE_COLLECTION, data);
export const getCollection = (id) => get(url.GET_COLLECTION + "/" + id);
export const putCollectionsStatus = (data) =>
  post(url.PUT_COLLECTION_STATUS, data);
export const updateStoreCollection = (data) =>
  post(url.UPDATE_STORE_COLLECTION, data);
export const putStoreCollectionDiscount = (data) =>
  post(url.PUT_COLLECTION_DISCOUNT, data);

export const compileDeployCollection = (data) =>
  post(url.COMPILE_DEPLOY_COLLECTIONS, data);

// Activities
export const getActivities = (data) => post(url.GET_ACTIVITIES, data);

// Faqs
export const getFaqs = (data) => post(url.GET_FAQS, data);
export const postFaq = (data) => post(url.ADD_FAQ, data);
export const getFaq = (id) => get(url.GET_FAQ + "/" + id);
export const putFaq = (data) => post(url.PUT_FAQ, data);
export const deleteFaq = (data) => post(url.DELETE_FAQ, data);
export const putFaqsStatus = (data) => post(url.PUT_FAQS_STATUS, data);

// Items
export const getItems = (data) => post(url.GET_ITEMS, data);
export const updateItems = (data) => post(url.UPDATE_NFT, data);
export const addBackgroundImage = (data) =>
  post(url.ADD_BACKGROUND_IMAGE, data);
export const viweBackgroundImage = (data) =>
  get(url.VIEW_BACKGROUND_IMAGE + data);

export const listBackgroundImage = (data) => get(url.LIST_BACKGROUND_IMAGE);

export const updateBackgroundImage = (data) =>
  post(url.UPDATE_BACKGROUND_IMAGE, data);

export const deleteBackgroundImage = (data) =>
  post(url.DELETE_BACKGROUND_IMAGE, data);

export const postItem = (data) => post(url.POST_ITEM, data);
export const deployItem = (data) => post(url.DEPLOY_ITEM, data);
export const deleteItem = (data) => post(url.DELETE_ITEM, data);
export const deleteItem2 = (data) => post(url.DELETE_ITEM_2 + data);
export const deleteBlog = (data) => post(url.DELETE_BLOG, data);
export const getItem = (id) => get(url.GET_ITEM + "/" + id);
export const putItemsStatus = (data) => post(url.PUT_ITEM_STATUS, data);
export const getNotifications = (data) => post(url.GET_NOTIFICATIONS, data);

export const putNotifications = (data) => post(url.PUT_NOTIFICATIONS, data);

//categories
export const getFdCategories = (data) => post(url.API_CATEGORY, data);

export const postFdCategory = (storeType, data) =>
  post(url.API_CATEGORY + url.ADD_FD_CATEGORY, data);

export const getFdCategory = (id) =>
  get(url.API_CATEGORY + url.GET_FD_CATEGORY + "/" + id);

// export const getFdCategory = (id) => get(url.VIEW_USER + "/" + id)
export const putFdCategory = (data) =>
  post(url.API_CATEGORY + url.PUT_FD_CATEGORY, data);

export const deleteFdCategory = (storeType, data) =>
  post(url.API_CATEGORY + url.DELETE_FD_CATEGORY, data);

export const putFdCategoriesStatus = (storeType, data) =>
  post(url.PUT_FD_CATEGORIES_STATUS, data);

export const putFdCategorySort = (data) => post(url.PUT_FD_CATEGORY_SORT, data);

export const exportFdCategories = (storeType, id) =>
  get(urlHelper(url.EXPORT_FD_CATEGORIES, storeType, id));

export const getAllParkingOwnersApi = (data) =>
  post(url.GET_ALL_OWNERS_LIST, data);
export const addParkingOwnerApi = (data) => post(url.ADD_PARKING_OWNER, data);
export const updateParkingOwnerApi = (data) =>
  post(url.UPDATE_PARKING_OWNER, data);
export const removeParkingOwnerApi = (data) =>
  post(url.REMOVE_PARKING_OWNER, data);
export const getParkingOwnerByIdApi = (id) =>
  get(url.GET_PARKING_OWNER_BY_ID + "/" + id);

export const getOwnerParkingByIdApi = (id) =>
  get(url.GET_OWNER_BY_PARKING_ID + "/" + id);

//subscription
export const getSubscriptionsApi = (data) => post(url.GET_SUBSCRIPTIONS, data);
export const removeSubscriptionsApi = (data) =>
  post(url.REMOVE_SUBSCRIPTION, data);
export const cancelSubscriptionApi = (data) =>
  post(url.CANCEL_SUBSCRIPTION, data);
export const addSubscriptionPlanApi = (data) =>
  post(url.ADD_SUBSCRIPTION_PLAN, data);
export const deleteSubscriptionPlanApi = (data) =>
  post(url.DELETE_SUBSCRIPTION_PLAN, data);
export const updateSubscriptionPlanApi = (data) =>
  post(url.UPDATE_SUBSCRIPTION_PLAN, data);
export const getSubscriptionsPlanApi = (data) =>
  post(url.GET_SUBSCRIPTIONS_PLAN, data);
export const getSubscriptionPlanApi = (_id) =>
  get(url.GET_SUBSCRIPTION_PLAN + "/" + _id);

export const assignSubscriptionToUserApi = (data) =>
  post(url.ASSIGN_SUBSCRIPTION_TO_USER, data);

//sensor
export const getSensorsListApi = (data) => post(url.GET_SENSORS_LIST, data);
export const addSensorApi = (data) => post(url.ADD_SENSOR, data);
export const getSensorApi = (id) => get(url.GET_SENSOR + "/" + id);
export const updateSensorApi = (data) => post(url.UPDATE_SENSOR, data);
export const removeSensorApi = (data) => post(url.REMOVE_SENSOR, data);

//company
export const getCompaniesListApi = (data) => post(url.GET_COMPANIES, data);
export const addCompanyApi = (data) => post(url.ADD_COMPANY, data);
export const getCompanyApi = (id) => get(url.GET_COMPANY + "/" + id);
export const updateCompanyApi = (data) => post(url.UPDATE_COMPANY, data);
export const removeCompanyApi = (data) => post(url.REMOVE_COMPANY, data);

//country
export const getCountryListApi = (data) => post(url.GET_COUNTRIES, data);
export const addCountryApi = (data) => post(url.ADD_COUNTRY, data);
export const getCountryApi = (id) => get(url.GET_COUNTRY + "/" + id);
export const updateCountryApi = (data) => post(url.UPDATE_COUNTRY, data);
export const removeCountryApi = (data) => post(url.REMOVE_COUNTRY, data);
//city
export const getCityListApi = (data) => post(url.GET_CITIES, data);
export const addCityApi = (data) => post(url.ADD_CITY, data);
export const getCityApi = (id) => get(url.GET_CITY + "/" + id);
export const updateCityApi = (data) => post(url.UPDATE_CITY, data);
export const removeCityApi = (data) => post(url.REMOVE_CITY, data);
//zone
export const getZoneListApi = (data) => post(url.GET_ZONES, data);
export const addZoneApi = (data) => post(url.ADD_ZONE, data);
export const getZoneApi = (id) => get(url.GET_ZONE + "/" + id);
export const updateZoneApi = (data) => post(url.UPDATE_ZONE, data);
export const removeZoneApi = (data) => post(url.REMOVE_ZONE, data);

//parkingStructures
export const getParkingStructuresApi = (data) =>
  post(url.GET_PARKING_STRUCTURES, data);
export const addParkingStructureApi = (data) =>
  post(url.ADD_PARKING_STRUCTURE, data);
export const getParkingStructureApi = (data) =>
  get(url.GET_PARKING_STRUCTURE + data);
export const updateParkingStructureApi = (data) =>
  post(url.UPDATE_PARKING_STRUCTURE, data);
export const removeParkingStructureApi = (data) =>
  post(url.DELETE_PARKING_STRUCTURE, data);

//contact us
export const getContactUsApi = (data) => post(url.GET_CONTACTUS_LIST, data);
export const addContactUsApi = (data) => post(url.ADD_CONTACT_US, data);

export const getSubscriptionUsersApi = (data) =>
  post(url.GET_SUBSCRIPTION_USERS, data);

export const getUserBookingsApi = (data) => post(url.GET_USER_BOOKINGS, data);
export const getOwnerBookingsApi = (data) => post(url.GET_OWNER_BOOKINGS, data);
export const getBookingsApi = (data) => post(url.GET_BOOKINGS, data);

export const updateUserStatusApi = (data) => post(url.UPDATE_USER_STATUS, data);
export const getBookingDetailsApi = (id) =>
  get(url.GET_BOOKING_DETAILS + "/" + id);

//permit cards
export const getPermiCardsApi = (data) => post(url.LIST_PERMIT_CARDS, data);
export const assignPermitCardApi = (data) => post(url.ASSIGN_PERMIT_CARD, data);
export const removePermitCardApi = (data) => post(url.REMOVE_PERMIT_CARD, data);
export const getUserVehicalsApi = (data) => post(url.GET_USER_VEHICALS, data);

///DASHBOARD
export const getGraphData = (query) => get(url.GET_GRAPH_DATA + query);
export const getStatsData = (query) => get(url.GET_STATS_DATA + query);
export const getTransaction = (query) => get(url.GET_TRANSACTION + query);

export const importSensor = (data) => post(url.IMPORT_SENSOR, data);

//get carpark code

export const getCarkParkCode = (data) => post(url.GET_CARPARK_CODE, data);
