import React,{useState,useEffect} from "react";
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"
import { withTranslation } from "react-i18next"
import AsyncSelect from 'react-select/async';
import {
    getParkingOwnersRequest,
} from "store/actions"

const OwnerSelector = ({
    users,
    totalUsers,
    getParkingOwnersRequest,
    loading,
    onChangeHandler,
    defaultSelected = [],
    role,
    user,
    isMulti=false,
    placeholder="Select...",
    ...props}) =>{
     
    const [filter, setFilter] = useState({
        orderBy: "date_created_utc",
        order: -1,
        page: 0,
        limit: 30,
        search: "",
        ownerId : role==="VENDOR" ? user?._id : undefined
      })
      const [selected,setSelected] = useState(defaultSelected);

    useEffect(()=>{
        setSelected(defaultSelected)
    },[defaultSelected])

    const collectionOptions = (users) => ((users || []).map((item)=> ({value : item?._id,label : item?.email})));  
    
    const defaultOptions = (users || []).map((item)=> ({value : item?._id,label : item?.email}));  

    useEffect(() => {
        getParkingOwnersRequest({ ...filter });
      }, [JSON.stringify(filter)]);
    


  const loadOptions = (inputValue,callback) =>{
    getParkingOwnersRequest({...filter,search:inputValue},({data})=>{
        const options = collectionOptions(data);
        setTimeout(()=>{
            callback(options);  
        },1000);
        setFilter({...filter,search:inputValue})
    });
}

  const onChangeSelector = (data) =>{
    if(isMulti){
    if(data?.length){
        // const ids= [];
        // data.forEach((a)=>{
        //     ids.push(a.value);
        // },[])
        // console.log("allIds",ids);
        onChangeHandler && onChangeHandler(data);
        setSelected(data);
    }else{
        onChangeHandler && onChangeHandler([]);
        setSelected([]);
    }
  }else{
    onChangeHandler(data);
  }
}

    return(
           // <Select options={userOptions} />
           <AsyncSelect 
                isMulti={isMulti} 
                placeholder={placeholder}
                value={selected}
                cacheOptions
                defaultOptions={defaultOptions}  
                loadOptions={loadOptions} 
                onChange={(data)=>onChangeSelector(data)}
                // onInputChange={(value)=>onInputChange(value)}

          />
    );
}

  
const mapStateToProps = ({ customers }) => ({
    loading: customers.loading,
    users: customers.parkingOwners,
    totalUsers: customers.totalParkingOwners,
  });
  
  const mapDispatchToProps = (dispatch) => {
    return {
      getParkingOwnersRequest: (data,callback) => {
        dispatch(getParkingOwnersRequest(data,callback));
      }
    };
  };
  
  export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(withTranslation()(OwnerSelector)),
  )



