/** Super Admin apis start */

// LOGIN
export const POST_LOGIN = "/admin/adminLogin";
export const CHANGE_PASSWORD = "/admin/changepassword";

/** Super Admin apis end */
export const POST_FORGOT_PASSWORD = "/admin/forgotPassword";
export const POST_RESET_PASSWORD = "/admin/resetPassword";

// Profile
export const GET_CURRENT_USER = "/store/me";
export const PUT_PROFILE = "/admin/updateprofile";

// SIGNUP
export const POST_REGISTER_EMAIL = "/store/signupemail";
export const POST_STORE_NAME = "/store/check";
export const POST_REGISTER = "/store/signup";
export const POST_APP_RESET_PASSWORD = "/user/resetpassword/bylink";


// File
export const POST_FILE = "/file/add";
export const POST_ITEM_FILE = "/file/ipfs";
export const POST_REMOVE_FILE = "/file/remove";

// Air drop
export const AIR_DROP = "/setting/admin/airdrop";
export const AIR_DROP_LIST = "/setting/airdrop/list";

// Settings
export const API_SETTINGS = "/setting";
export const GET_SETTINGS = "/admin/setting/view";
export const PUT_SETTINGS = "/admin/setting/update";
export const POST_CONTRACT_DEPLOY = "/setting/admin/deploycontract";
export const POST_CONTRACT_DEPLOY_MULTISEND =
  "/setting/admin/deployContractForMultisend";
export const POST_COMPILE_CONTRACT = "/setting/admin/compiletoken";
export const POST_COMPILE_CONTRACT_PPG = "/setting/admin/compiletokenppg";
export const POST_COMPILE_CONTRACT_GOV = "/setting/admin/compilegovernance";
export const POST_CONTRACT_DEPLOY_PPG =
  "/setting/admin/deployppgContractForToken";
export const POST_CONTRACT_DEPLOY_GOV =
  "/setting/admin/deploygovContractForToken";

// Users
export const GET_USERS = "/admin/getUsersWithFilter";
export const REMOVE_USER = "/admin/deleteUser";
export const VIEW_USER = "/admin/getUserById";
export const CHANGE_STATUS = "/user/admin/update/status";

// Stake
export const GET_STAKES = "/stake/admin/list";
export const GET_STAKE_STATS = "/stake/admin/stats";

// DOCUMENTS
export const GET_DOCUMENTS = "/document/admin/list";
export const ADD_DOCUMENT = "/document/admin/add";
export const GET_DOCUMENT = "/document/admin/view";
export const PUT_DOCUMENT = "/document/admin/update";
export const DELETE_DOCUMENT = "/document/admin/remove";

// CONTENT_PAGES
export const GET_CONTENT_PAGES = "/admin/contents/list";
export const ADD_CONTENT_PAGE = "/admin/contents/add";
export const GET_CONTENT_PAGE = "/admin/contents/view";
export const PUT_CONTENT_PAGE = "/admin/contents/update";
export const DELETE_CONTENT_PAGE = "/admin/contents/remove";
export const PUT_CONTENT_PAGES_STATUS = "/pages/updatestatus/all";

//collections
export const GET_COLLECTIONS = "/collection/admin/list";
export const VIEW_COLLECTION = "/collection/admin/view";
export const GET_COLLECTION = "/collection/admin/view";
export const COMPILE_DEPLOY_COLLECTIONS =
  "/collection/admin/compile-and-deploy-contract";
export const POST_COLLECTION = "/collection/admin/add";
export const PUT_COLLECTION = "/super-admin/billingplan/update";
export const DELETE_COLLECTION = "/super-admin/billingplan/remove";
export const PUT_COLLECTION_STATUS =
  "/super-admin/billingplan/updatestatus/all";
export const UPDATE_STORE_COLLECTION = "/super-admin/store/updatePlan";
export const PUT_COLLECTION_DISCOUNT = "/super-admin/billingplan/discount";

// Activity
export const GET_ACTIVITIES = "/item/activity";

// FAQS
export const GET_FAQS = "/admin/getAllFaqs";
export const ADD_FAQ = "/admin/addFaq";
export const GET_FAQ = "/admin/getFaqsById";
export const PUT_FAQ = "/admin/editFaq";
export const DELETE_FAQ = "/admin/deleteFaq";
export const PUT_FAQS_STATUS = "/faq/updatestatus/all";

// Items
export const GET_ITEMS = "/item/admin/list";
export const GET_ITEM = "/item/admin/view";
export const GET_NOTIFICATIONS = "/item/admin/notifications";
export const PUT_NOTIFICATIONS = "/item/admin/readNotification";

export const CREATE_ITEM = "/item/admin/readNotification";
export const POST_ITEM = "/item/admin/add";
export const ADD_BACKGROUND_IMAGE = "/level/admin/add";
export const UPDATE_NFT = "/item/admin/update";
export const VIEW_BACKGROUND_IMAGE = "/level/admin/view/";
export const LIST_BACKGROUND_IMAGE = "/level/admin/list";
export const UPDATE_BACKGROUND_IMAGE = "/level/admin/update";
export const DELETE_BACKGROUND_IMAGE = "/level/admin/remove";
export const DEPLOY_ITEM = "/item/admin/mint";
export const DELETE_BLOG = "/blog/admin/remove";

// Category
export const ADD_CATEGORY = "/category/admin/add";
export const VIEW_CATEGORIES = "/category/admin/list";
export const DELETE_ITEM = "/category/admin/remove";
export const DELETE_ITEM_2 = "/item/admin/delete/";
export const PUT_ITEM_STATUS = "/category/admin/updatestatus/all";
export const VIEW_CATEGORY = "/category/admin/view";
export const UPDATE_CATEGORY = "/category/admin/update";

//category
export const API_CATEGORY = "/categoryes";
export const GET_FD_CATEGORIES = "";
export const ADD_FD_CATEGORY = "/add";
export const GET_FD_CATEGORY = "/view";
export const PUT_FD_CATEGORY = "/update";
export const DELETE_FD_CATEGORY = "/archive";
export const PUT_FD_CATEGORIES_STATUS = "/categoryes/updatestatus/all";
export const PUT_FD_CATEGORY_SORT = "/categoryes/sortorder";
export const EXPORT_FD_CATEGORIES = "/store/export/vendor/categories";

//parking owners
export const GET_ALL_OWNERS_LIST = "/admin/getAllOwnerList";
export const GET_PARKING_OWNER_BY_ID = "/admin/getOwnerById";
export const REMOVE_PARKING_OWNER = "/admin/removeOwner";
export const UPDATE_PARKING_OWNER = "/admin/editOwner";
export const ADD_PARKING_OWNER = "/admin/addOwner";
export const GET_OWNER_BY_PARKING_ID = "/admin/getOwnerByParkingId";


//subscription
export const GET_SUBSCRIPTIONS = "/news/admin/list";
export const REMOVE_SUBSCRIPTION = "/news/admin/remove";
export const CANCEL_SUBSCRIPTION = "/vendor/cancelSubscription";

export const ADD_SUBSCRIPTION_PLAN = "/user-subscription/create";
export const UPDATE_SUBSCRIPTION_PLAN = "/user-subscription/update";
export const DELETE_SUBSCRIPTION_PLAN = "/user-subscription/delete";
export const GET_SUBSCRIPTIONS_PLAN = "/user-subscription/getWithFilters";
export const GET_SUBSCRIPTION_PLAN = "/user-subscription/detail";
export const ASSIGN_SUBSCRIPTION_TO_USER = "/user-subscription/assignSubscription";


export const ADD_SENSOR = "/admin/sensor/create";
export const GET_SENSORS_LIST = "/admin/sensor/list";
export const GET_SENSOR = "/admin/sensor";
export const UPDATE_SENSOR = "/admin/sensor/update";
export const REMOVE_SENSOR = "/admin/sensor/remove";

export const ADD_COMPANY = "/company/add";
export const UPDATE_COMPANY = "/company/update";
export const GET_COMPANY = "/company/view";
export const REMOVE_COMPANY = "/company/remove";
export const GET_COMPANIES = "/company/getCompaniesWithFilter";

export const ADD_COUNTRY = "/country/add";
export const UPDATE_COUNTRY = "/country/update";
export const GET_COUNTRY = "/country/view";
export const REMOVE_COUNTRY = "/country/remove";
export const GET_COUNTRIES = "/country/getCountriesWithFilter";

export const ADD_CITY = "/city/add";
export const UPDATE_CITY = "/city/update";
export const GET_CITY = "/city/view";
export const REMOVE_CITY = "/city/remove";
export const GET_CITIES = "/city/getCitiesWithFilter";

export const ADD_ZONE = "/zone/add";
export const UPDATE_ZONE = "/zone/update";
export const GET_ZONE = "/zone/view";
export const REMOVE_ZONE = "/zone/remove";
export const GET_ZONES = "/zone/getZonesWithFilter";


// parking structures

export const ADD_PARKING_STRUCTURE = "/parkingStructure/add";
export const UPDATE_PARKING_STRUCTURE = "/parkingStructure/update";
export const GET_PARKING_STRUCTURE = "/parkingStructure/view/";
export const DELETE_PARKING_STRUCTURE = "/parkingStructure/remove";
export const GET_PARKING_STRUCTURES = "/parkingStructure/getParkingStructureWithFilter";

//contact us
export const GET_CONTACTUS_LIST = "/contactUs/list";
export const ADD_CONTACT_US = "/contactUs/add";

export const GET_SUBSCRIPTION_USERS = "/subscription/users";

export const GET_USER_BOOKINGS = "";
export const GET_OWNER_BOOKINGS = "";
export const GET_BOOKINGS = "/booking/list";

export const UPDATE_USER_STATUS = "/admin/blockUnblockUser";
export const GET_BOOKING_DETAILS = "/booking/view";

export const LIST_PERMIT_CARDS = "/permitcard/list";
export const ASSIGN_PERMIT_CARD = "/permitcard/assignCard";
export const REMOVE_PERMIT_CARD = "/permitcard/removeCard";

export const GET_USER_VEHICALS = "vehical/listForAdmin";




export const GET_GRAPH_DATA = "/adminDashboard/graph";
export const GET_STATS_DATA = "/adminDashboard/stats";
export const GET_TRANSACTION = "/user/transactions";
export const IMPORT_SENSOR = "/adminDashboard/upload/sensor";


//get carpark code
export const GET_CARPARK_CODE = "/sensor/list";


