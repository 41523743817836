import PropTypes from "prop-types";
import React, { useEffect } from "react";

import {
  Switch,
  BrowserRouter as Router,
  Route,
  withRouter,
} from "react-router-dom";
import { connect } from "react-redux";

// Import Routes all
import { userRoutes, authRoutes } from "./routes/allRoutes";

// Import all middleware
import Authmiddleware from "./routes/middleware/Authmiddleware";

// layouts Format
import VerticalLayout from "./components/VerticalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert";

import Loader from "pages/Utility/Loader";
import Pages404 from "pages/Utility/pages-404";
import Pages500 from "pages/Utility/pages-500";

// Import scss
// Import css
import "assets/css/style.scss";
import "assets/css/responsive.scss";
import "./assets/scss/theme.scss";
import "./assets/scss/custom1.scss";
import 'react-time-picker/dist/TimePicker.css';
import "toastr/build/toastr.min.css";

import 'bootstrap/dist/css/bootstrap.min.css';

import {
  currentUser,
  getSettings,
  getLanguages,
  reloadOnDemand,
} from "store/actions";

const App = (props) => {
  useEffect(() => {
    if (!!props.authToken) {
      props?.getLanguages();

      // props?.currentUser && props.currentUser()
      props?.getSettings && props.getSettings();
    }
  }, [props.authToken]);

  function getLayout() {
    let layoutCls = VerticalLayout;

    switch (props.layout.layoutType) {
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  }

  const Layout = getLayout();

  return (
    <React.Fragment>
      <Router>
        <Switch>
          <Route path="/loading" component={Loader} />
          <Route path="/pages-500" component={Pages500} />

          {!props.authToken &&
            authRoutes.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                layout={NonAuthLayout}
                component={route.component}
                key={idx}
              />
            ))}

          {props.authToken && (
            <Switch>
              {userRoutes().map(
                (route, idx) => (
                  <Authmiddleware
                    path={route.path}
                    layout={Layout}
                    component={route.component}
                    key={idx}
                    exact
                  />
                )
              )}

              <Route component={Pages404} />
            </Switch>
          )}
        </Switch>
      </Router>

      {props?.alert && (
        <SweetAlert
          custom
          title="Access Denied!"
          onConfirm={props.reloadOnDemand}
        >
          You not have access! Reload Now!
        </SweetAlert>
      )}
    </React.Fragment>
  );
};

App.propTypes = {
  layout: PropTypes.any,
  authToken: PropTypes.string,
  currentUser: PropTypes.func,
  getSettings: PropTypes.func,
  user: PropTypes.object,
};

const mapStateToProps = ({ Layout, Alert, Login }) => ({
  layout: Layout,
  alert: Alert.alert,
  authToken: Login.authToken,
  user: Login.user,
});

export default connect(mapStateToProps, {
  currentUser,
  getSettings,
  getLanguages,
  reloadOnDemand,
})(withRouter(App));
