import {
   
    GET_SENSORS_REQUEST,
    GET_SENSORS_SUCCESS,
    GET_SENSORS_FAIL,
    ADD_SENSOR_REQUEST,
    ADD_SENSOR_SUCCESS,
    ADD_SENSOR_FAIL,
    GET_SENSOR_REQUEST,
    GET_SENSOR_SUCCESS,
    GET_SENSOR_FAIL,
    UPDATE_SENSOR_REQUEST,
    UPDATE_SENSOR_SUCCESS,
    UPDATE_SENSOR_FAIL,
    REMOVE_SENSOR_REQUEST,
    REMOVE_SENSOR_SUCCESS,
    REMOVE_SENSOR_FAIL,
  } from "./actionTypes"
  
  const INIT_STATE = {
    sensors: [],
    totalSensors: 0,
    error: "",
    loading: false,
    singleSensorDetails : null,
    deleteSensorLoading : false,
    addUpdateSensorLoading : false
  }
  
  const Sensors = (state = INIT_STATE, action) => {
    switch (action.type) {     
  
      case GET_SENSORS_REQUEST:
        return {
          ...state,
          error: action.payload,
          loading: true,
        }
  
      case GET_SENSORS_SUCCESS:
        return {
          ...state,
          error: "",
          loading: false,
          sensors : action.payload.data,
          totalSensors : action.payload.totalCount
        }
  
      case GET_SENSORS_FAIL:
        return {
          ...state,
          error: action.payload,
          loading: false,
        }

        case ADD_SENSOR_REQUEST:
        return {
            ...state,
            error: action.payload,
            addUpdateSensorLoading: true,
        }
    
        case ADD_SENSOR_SUCCESS:
        return {
            ...state,
            error: "",
            addUpdateSensorLoading: false,
            // sensors : [action.payload.data,...state.sensors],
            // totalSensors : action.payload.totalCount + 1
        }
    
        case ADD_SENSOR_FAIL:
        return {
            ...state,
            error: action.payload,
            addUpdateSensorLoading: false,
        }

        case GET_SENSOR_REQUEST:
        return {
          ...state,
          error: action.payload,
          loading: false,
        }
  
      case GET_SENSOR_SUCCESS:
        return {
          ...state,
          error: "",
          loading: false,
          singleSensorDetails : action.payload.data        }
      /* Update ContentPages Status END */
  
      case GET_SENSOR_FAIL:
        return {
          ...state,
          error: action.payload,
          loading: false,
        }
  
        case UPDATE_SENSOR_REQUEST:
          return {
            ...state,
            error: action.payload,
            addUpdateSensorLoading: true,
          }
    
        case UPDATE_SENSOR_SUCCESS:
          return {
            ...state,
            error: "",
            addUpdateSensorLoading: false,
          }
        case UPDATE_SENSOR_FAIL:
          return {
            ...state,
            error: action.payload,
            addUpdateSensorLoading: false,
          }
          case REMOVE_SENSOR_REQUEST:
            return {
              ...state,
              error: action.payload,
              deleteSensorLoading: true,
            }
      
          case REMOVE_SENSOR_SUCCESS:
            return {
              ...state,
              error: "",
              deleteSensorLoading: false,
          /* Update ContentPages Status END */
            }
          case REMOVE_SENSOR_FAIL:
            return {
              ...state,
              error: action.payload,
              deleteSensorLoading: false,
            }    
  
      default:
        return state
    }
  }
  
  export default Sensors
  