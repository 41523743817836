import React from 'react'
import { Container } from 'reactstrap'

import Welcomegreet from 'components/Welcomegreet'
import Welcomecards from 'components/Welcomecards'
import BookingsList from './List'

function Bookings() {
  return (
    <React.Fragment>
    <div className="page-content">
         <Container fluid>
              <Welcomegreet/>
              <Welcomecards/>
              <BookingsList/>
         </Container>
    </div>
    </React.Fragment>
  )
}

export default Bookings