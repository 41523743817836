import usFlag from "../assets/images/flags/us.jpg"
import bulgaria from "../assets/images/flags/bul.png"

const languages = {
  // sp: {
  //   label: "Spanish",
  //   flag: spain,
  // },
  // gr: {
  //   label: "German",
  //   flag: germany,
  // },
  // rs: {
  //   label: "Italian",
  //   flag: italy,
  // },
  bul: {
    label: "Bulgarian",
    flag: bulgaria,
  },
  en: {
    label: "English",
    flag: usFlag,
  },
}

export default languages
