import * as CONST from "./actionTypes";

const INIT_STATE = {
  customers: [],
  totalCustomers: 0,
  error: "",
  keys_error: "",
  loading: false,
  keys_loading: false,
  customer: {},
  address: {},
  plans: [],
  transactions: {
    data: [],
    totalcount: 0,
  },
  hideThingsError: "",
  parkingOwners: [],
  parkingOwnerDetails: null,
  totalParkingOwners: 0,
  updateStatusLoader: false,
};

const Customers = (state = INIT_STATE, action) => {
  switch (action.type) {
    /** Loading */
    case CONST.CUSTOMER_LOADING:
      // case CONST.CHANGE_STATUS:
      return {
        ...state,
        loading: true,
      };
    /* Get Customers */
    case CONST.GET_CUSTOMERS:
      return {
        ...state,
        loading: true,
      };

    case CONST.GET_CUSTOMERS_SUCCESS:
      return {
        ...state,
        customers: action.payload.data || [],
        totalCustomers: action.payload.totalcount || 0,
        loading: false,
      };

    case CONST.GET_CUSTOMERS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    /* Get Customers END */

    /* Get Customer */
    case CONST.GET_CUSTOMER:
      return {
        ...state,
        error: "",
        loading: true,
        customer: {},
      };

    case CONST.GET_CUSTOMER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        customer: {},
      };

    case CONST.GET_CUSTOMER_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
        customer: { ...action.payload.data, wallets: action.payload.wallets },
        transactions: [],
      };
    /* Add Customer END */

    /** Get Plans Start*/
    case CONST.GET_ALL_PLANS_SUCCESS:
      return {
        ...state,
        plans: [...action.payload],
      };
    case CONST.GET_ALL_PLANS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    /** Get Plans End*/

    case CONST.UPDATE_STORE_STATUS_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
      };
    case CONST.UPDATE_STORE_STATUS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case CONST.UPDATE_STORE_PLAN_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
      };
    case CONST.UPDATE_STORE_PLAN_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case CONST.GET_STORE_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        transactions: { ...action.payload },
        error: "",
        loading: false,
      };
    case CONST.GET_STORE_TRANSACTIONS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case CONST.POST_STORE_CHARGE_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
      };
    case CONST.POST_STORE_CHARGE_FAIL:
      return {
        ...state,
        error: "",
        loading: false,
      };
    case CONST.POST_STORE_REFUND_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
      };
    case CONST.POST_STORE_REFUND_FAIL:
      return {
        ...state,
        error: "",
        loading: false,
      };
    case CONST.STORE_USER_LOGIN_SUCCESS:
      return {
        ...state,
      };

    /* Give discount on store plan */
    case CONST.PUT_STORE_PLAN_DISCOUNT:
      return {
        ...state,
        loading: true,
        error: "",
      };

    case CONST.PUT_STORE_PLAN_DISCOUNT_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
      };

    case CONST.PUT_STORE_PLAN_DISCOUNT_FAIL:
    case CONST.CHANGE_STATUS_FAIL:
    case CONST.CHANGE_STATUS_SUCCESS:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    /* Give discount on store plan END */

    /* Store Check mail */
    case CONST.POST_CHECK_MAIL:
      return {
        ...state,
        error: "",
        loading: true,
      };

    case CONST.POST_CHECK_MAIL_SUCCESS: {
      return {
        ...state,
        error: "",
        loading: false,
      };
    }

    case CONST.POST_CHECK_MAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    /* Store check mail END */

    /* Delete Store */
    case CONST.DELETE_STORE:
      return {
        ...state,
        error: "",
        loading: true,
      };

    case CONST.DELETE_STORE_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
      };

    case CONST.DELETE_STORE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    /* Delete Store END */

    /* PUT STORE KEYS */
    case CONST.PUT_STORE_KEYS:
      return {
        ...state,
        keys_error: "",
        keys_loading: true,
      };

    case CONST.PUT_STORE_KEYS_SUCCESS:
      return {
        ...state,
        keys_error: "",
        keys_loading: false,
      };

    case CONST.PUT_STORE_KEYS_FAIL:
      return {
        ...state,
        keys_error: action.payload,
        keys_loading: false,
      };
    /* PUT STORE KEYS END */

    /* POST Hide THings */
    case CONST.POST_HIDE_THINGS:
      return {
        ...state,
        loading: true,
        hideThingsError: "",
      };

    case CONST.POST_HIDE_THINGS_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case CONST.POST_HIDE_THINGS_FAIL:
      return {
        ...state,
        loading: false,
        payload: action.payload,
      };
    /* POST Hide Things END */
    /** */

    //parking owners
    case CONST.GET_PARKING_OWNERS_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };

    case CONST.GET_PARKING_OWNERS_SUCCESS:
      return {
        ...state,
        loading: false,
        parkingOwners: action.payload.data,
        totalParkingOwners: action.payload.totalCount,
      };

    case CONST.GET_PARKING_OWNERS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CONST.GET_PARKING_OWNER_BY_ID_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
        parkingOwnerDetails: null,
      };

    case CONST.GET_PARKING_OWNER_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        parkingOwnerDetails: action.payload.data,
      };

    case CONST.GET_PARKING_OWNER_BY_ID_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CONST.ADD_PARKING_OWNER_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };

    case CONST.ADD_PARKING_OWNER_SUCCESS:
      return {
        ...state,
        loading: false,
        parkingOwners: [...state.parkingOwners],
      };

    case CONST.ADD_PARKING_OWNER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CONST.UPDATE_PARKING_OWNER_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };

    case CONST.UPDATE_PARKING_OWNER_SUCCESS:
      return {
        ...state,
        loading: false,
        parkingOwners: [...state.parkingOwners],
      };

    case CONST.UPDATE_PARKING_OWNER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CONST.REMOVE_PARKING_OWNER_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };

    case CONST.REMOVE_PARKING_OWNER_SUCCESS:
      console.log("action.payload._id", action.payload._id);
      return {
        ...state,
        loading: false,
        parkingOwners: state.parkingOwners.filter(
          (item) => item?._id !== action.payload._id
        ),
        totalParkingOwners:
          state.totalParkingOwners >= 1
            ? state.totalParkingOwners - 1
            : state.totalParkingOwners,
      };

    case CONST.REMOVE_PARKING_OWNER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CONST.UPDATE_USER_STATUS_REQUEST:
      return {
        ...state,
        updateStatusLoader: true,
        error: "",
      };

    case CONST.UPDATE_USER_STATUS_SUCCESS:
      return {
        ...state,
        updateStatusLoader: false,
        customer:
          state.customer?._id === action.payload.data?._id
            ? { ...state.customer, status: action.payload.data.status }
            : state.customer,
        parkingOwnerDetails:
          state.parkingOwnerDetails?._id === action.payload.data._id
            ? {
                ...state.parkingOwnerDetails,
                status: action.payload.data.status,
              }
            : state.parkingOwnerDetails,
      };

    case CONST.UPDATE_USER_STATUS_FAIL:
      return {
        ...state,
        updateStatusLoader: false,
        error: action.payload,
      };

    case CONST.REMOVE_CUSTOMER:
      return {
        ...state,
        loading: true,
        error: "",
      };

    case CONST.REMOVE_CUSTOMER_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case CONST.REMOVE_CUSTOMER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

      case CONST.GET_OWNER_BY_PARKING_ID_REQUEST:
        return {
          ...state,
          loading: true,
          error: "",
          parkingOwnerDetails: null,
        };
  
      case CONST.GET_OWNER_BY_PARKING_ID_SUCCESS:
        return {
          ...state,
          loading: false,
          parkingOwnerDetails: action.payload.data,
        };
  
      case CONST.GET_OWNER_BY_PARKING_ID_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      

    default:
      return state;
  }
};

export default Customers;
