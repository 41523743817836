import * as TYPES from "./actionTypes";

const INIT_STATE = {
  documents: [],
  totalDocuments: 0,
  error: "",
  loading: false,
  document: {},
};

const Documents = (state = INIT_STATE, action) => {
  switch (action.type) {
    /* Get Documents */
    case TYPES.GET_DOCUMENTS:
      return {
        ...state,
        loading: true,
      };

    case TYPES.GET_DOCUMENTS_SUCCESS:
      console.log("action.payload", action.payload);
      return {
        ...state,
        documents: action.payload.data || [],
        totalDocuments: action.payload.totalCount || 0,
        loading: false,
        error: "",
      };

    case TYPES.GET_DOCUMENTS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    /* Get Documents END */

    /* Add Document */
    case TYPES.ADD_DOCUMENT:
      return {
        ...state,
        error: "",
        loading: true,
      };

    case TYPES.ADD_DOCUMENT_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case TYPES.ADD_DOCUMENT_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
      };
    /* Add Document END */

    /* Get Document */
    case TYPES.GET_DOCUMENT:
      return {
        ...state,
        error: "",
        loading: true,
        document: {},
      };

    case TYPES.GET_DOCUMENT_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        document: {},
      };

    case TYPES.GET_DOCUMENT_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
        document: action.payload,
      };
    /* Add Document END */

    /* Update Document */
    case TYPES.PUT_DOCUMENT:
      return {
        ...state,
        error: "",
        loading: true,
      };

    case TYPES.PUT_DOCUMENT_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case TYPES.PUT_DOCUMENT_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
      };
    /* Update Document END */

    /* Delete Document */
    case TYPES.DELETE_DOCUMENT:
      return {
        ...state,
        error: "",
        loading: true,
      };

    case TYPES.DELETE_DOCUMENT_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case TYPES.DELETE_DOCUMENT_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
      };
    /* Delete Document END */

    case TYPES.DOCUMENT_API_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    default:
      return state;
  }
};

export default Documents;
