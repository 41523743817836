import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { Button, Form } from "reactstrap";


import Welcomegreet from "components/Welcomegreet";

//i18n
import { withTranslation } from "react-i18next";

import {  Col, Container, Row, Spinner } from "reactstrap";

//Import Breadcrumb




import SearchInput from "components/Common/SearchInput";

import { getContactUsListRequest } from "store/actions";
import UsersColumns from "./Columns";
import CustomInfoModal from "components/Modals/CustomInfoModal";
// import UsersConfirmModal from "./ConfirmModal";


const ParkingOwners = (props) => {
  const {
    history,
    contactUsList,
    totalContactUs,
    getContactUsListRequest,
    loading,
  } = props;
  const [confirmModal, setConfirmModal] = useState({
    isOpen: false,
    userId: null,
  });
  const [showAddModal,setShowAddModal] = useState(false); 

  const [filter, setFilter] = useState({
    orderBy: "created_at",
    order: -1,
    page: 1,
    limit: 10,
  });
  const [searchText, setSearchText] = useState("");
  const [showInfoModal,setShowInfoModal] = useState({
    show : false,
    data : null
  });
  const pageOptions = {
    sizePerPage: 10,
    totalSize: totalContactUs,
    custom: true,
    onPageChange: (page) => setFilter((prevState) => ({ ...prevState, page })),
  };

  useEffect(() => {
    getContactUsListRequest({ ...filter });
  }, [JSON.stringify(filter)]);


  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { searchText, sortField, sortOrder }) => {
    if (type === "search") {
      setSearchText(searchText);
    }

    if (type === "sort") {
      if (sortField == "createdAt") {
        sortField = "date_created_utc";
      }

      if (sortOrder == "desc") {
        sortOrder = -1;
      } else if (sortOrder == "asc") {
        sortOrder = 1;
      }

      setFilter((prevState) => ({
        ...prevState,
        orderBy: sortField,
        order: sortOrder,
      }));
    }
  };

  const toggleConfirmModal = (userId) => {
    setConfirmModal((prevState) => ({ isOpen: !prevState.isOpen, userId }));
  };

  // const removeConfirm = () => {
  //   const { userId, isOpen } = confirmModal;

  //   const onComplete = () => {
  //     getContactUsListRequest(filter);
  //   };

  //   setConfirmModal({ isOpen: !isOpen, userId: null });
  //   removeParkingOwnerRequest({ _id: userId }, onComplete);
  // };

  const onSearch = (event) => {
    event.preventDefault();

    setFilter((prevState) => ({ ...prevState, search: searchText }));
  };

  const onShowInfoModal = (data) =>{
      setShowInfoModal({
        show : true,
        data : data
      })
  }

  // const onFinishAddEdit = () =>{
  //     setRowData(null);
  //     getContactUsListRequest(filter);
  // }
  

  return (
    <React.Fragment>
      <CustomInfoModal
        show={showInfoModal?.show}
        onClose={()=>setShowInfoModal({show : false,data : null})}
        title={props.t("information")}
      >
      
           <p><b>{props.t("name")}:</b>{showInfoModal?.data?.name}</p>
           <p><b>{props.t("email")}:</b>{showInfoModal?.data?.email}</p>
           <p><b>{props.t("phone")}:</b>{showInfoModal?.data?.phone}</p>
           <p><b>{props.t("message")}:</b>{showInfoModal?.data?.message}</p>  
      </CustomInfoModal>

      <div className="page-content">
        <Container fluid>
          <Welcomegreet />
          <div className="fillter_ssection mt-5">
            <Form>
              <div className="latest_cust_table mt-4">
                {/* <Newlist/> */}
                 
                <Row>
                  <Col xs="12"> 
                        <PaginationProvider
                          pagination={paginationFactory(pageOptions)}
                        >
                          {({ paginationProps, paginationTableProps }) => (
                            <ToolkitProvider
                              keyField="_id"
                              data={contactUsList}
                              columns={UsersColumns(onShowInfoModal,props.t)}
                              bootstrap4
                              search
                            >
                              {(toolkitProps) => (
                                <React.Fragment>
                             <Row>
                              <Col lg={6} md={12} sm={12}>
                              <div className="custom-flex-sm d-flex align-items-center">
                                      
                                      <div className="search d-flex align-items-center">
                                        <div className="pl-3">
                                          <SearchInput
                                            {...toolkitProps.searchProps}
                                            triggerSearch={onSearch}
                                            placeholder={props.t("search")}
                                            searchText={searchText}
                                          />
                                        </div>
                                      </div>
                                    </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive spinner-content mt-3">
                                  <BootstrapTable
                                    responsive
                                    remote
                                    bordered={false}
                                    striped={false}
                                    classes={
                                      "table table-centered table-nowrap"
                                    }
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    onTableChange={handleTableChange}
                                    {...paginationTableProps}
                                    defaultSorted={[
                                      {
                                        dataField: "createdAt",
                                        order: "desc",
                                      },
                                    ]}
                                    sort={{
                                      sortCaret: (order) =>
                                        order === "asc" ? (
                                          <span className="caret">
                                            <i className="bx bx-caret-up" />
                                          </span>
                                        ) : order === "desc" ? (
                                          <span className="caret">
                                            <i className="bx bx-caret-down" />
                                          </span>
                                        ) : null,
                                    }}
                                  />

                                  {loading && (
                                    <div className="spinner">
                                      <Spinner color="primary" />
                                    </div>
                                  )}
                                </div>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
            </Col>
          </Row>
              </div>
            </Form>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};



const mapStateToProps = ({ Support }) => ({
  loading: Support.loading,
  contactUsList: Support.contactUsList,
  totalContactUs: Support.totalContactUs,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getContactUsListRequest: (data,callback) => {
      dispatch(getContactUsListRequest(data,callback));
    }
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ParkingOwners))
);
