import React from 'react'
import { Container } from 'reactstrap'

import Welcomegreet from 'components/Welcomegreet'
import Termscontent from './termscontent'


function Termsconditions() {
  return (
    <React.Fragment>
    <div className="page-content">
         <Container fluid>
              <Welcomegreet/>
              <Termscontent/>
         </Container>
    </div>
    </React.Fragment>
  )
}

export default Termsconditions