import {

  CHANGE_PASSWORD,
  CHANGE_PASSWORD_FAIL,
  CHANGE_PASSWORD_SUCCESS,
} from "./actionTypes"

const initialState = {
  error: "",
  success: "",
  loading: false,
}

const profile = (state = initialState, action) => {
  switch (action.type) {
  
    case CHANGE_PASSWORD:
      state = { ...state, loading: true }
      break
    case CHANGE_PASSWORD_SUCCESS:
      state = { ...state, success: action.payload, loading: false }
      break
    case CHANGE_PASSWORD_FAIL:
      state = { ...state, error: action.payload, loading: false }
      break

    default:
      state = { ...state }
      break
  }
  return state
}

export default profile
