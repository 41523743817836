import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";

import { Card, CardBody, Col, Container, Row, Spinner } from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb2";
import ListColumns from "./ListColumns";

const CustomerSupport = ({
  accessLevel,
  history,
  loading,
  ...props
}) => {
  const [filter, setFilter] = useState({
    orderBy: "_id",
    order: -1,
    page: 0,
    limit: 20,
    search: "",
    fieldName: "",
    fieldValue: "",
  });

  const pageOptions = {
    sizePerPage: 20,
    totalSize: 0,
    custom: true,
    onPageChange: (page) => setFilter((prevState) => ({ ...prevState, page })),
  };

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { searchText, sortField, sortOrder }) => {
    if (type === "sort") {
      if (sortField == "createdAt") {
        sortField = "created_date";
      }

      if (sortOrder == "desc") {
        sortOrder = -1;
      } else if (sortOrder == "asc") {
        sortOrder = 1;
      }

      setFilter((prevState) => ({
        ...prevState,
        orderBy: sortField,
        order: sortOrder,
      }));
    }
  };

  const onFilterChange = (value) => {
    setFilter((prevState) => ({
      ...prevState,
      fieldName: "type",
      fieldValue: value,
    }));
  };

  const toggleDetailsModal = (details) => {
    setdetailsModal((prevState) => ({
      isOpen: !prevState.isOpen,
      details,
    }));
  };

  return (
    <React.Fragment>

      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Customer Support"
            breadcrumbItem={props.t("Customer Support")}
            breadcrumbItems={[{ title: props.t("Customer Support") }]}
          />

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="_id"
                        data={ []}
                        columns={ListColumns(
                          toggleDetailsModal,
                          history,
                          props.t
                        )}
                        bootstrap4
                        search
                      >
                        {(toolkitProps) => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col sm="4">
                                <div className="form-group row mb-2 mr-2">
                                  <label className="col-md-4 pr-0 col-form-label">
                                    {props.t("filter_by")}:
                                  </label>

                                  <div className="col-md-8">
                                    <select
                                      className="custom-select w-100"
                                      onChange={(e) =>
                                        onFilterChange(e.target.value)
                                      }
                                      value={filter.fieldValue}
                                    >
                                      <option value="">
                                        {props.t("Recent")}
                                      </option>
                                      <option value="withdraw">
                                        {props.t("Withdraw")}
                                      </option>
                                      <option value="deposit">
                                        {props.t("Deposit")}
                                      </option>
                                    </select>
                                  </div>
                                </div>
                              </Col>
                            </Row>

                            <Row>
                              <Col xl="12">
                                <div className="table-responsive spinner-content">
                                  <BootstrapTable
                                    responsive
                                    remote
                                    bordered={false}
                                    striped={false}
                                    classes={
                                      "table table-centered table-nowrap"
                                    }
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    onTableChange={handleTableChange}
                                    {...paginationTableProps}
                                    defaultSorted={[
                                      {
                                        dataField: "createdAt",
                                        order: "desc",
                                      },
                                    ]}
                                    sort={{
                                      sortCaret: (order) =>
                                        order === "asc" ? (
                                          <span className="caret">
                                            <i className="bx bx-caret-up" />
                                          </span>
                                        ) : order === "desc" ? (
                                          <span className="caret">
                                            <i className="bx bx-caret-down" />
                                          </span>
                                        ) : null,
                                    }}
                                  />

                                  {loading && (
                                    <div className="spinner">
                                      <Spinner color="primary" />
                                    </div>
                                  )}
                                </div>
                              </Col>
                            </Row>
                            <Row className="align-stakes-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

CustomerSupport.propTypes = {
  
};

const mapStateToProps = ({ CustomerSupport }) => ({
   loading: CustomerSupport?.loading,
   list : CustomerSupport?.list
});

const mapDispatchToProps = (dispatch) => ({
  // onGetStakes: (data) => dispatch(getStakes(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CustomerSupport))
);
