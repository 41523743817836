import { call, put, takeEvery } from "redux-saga/effects";

// Activity Redux States
import { GET_ACTIVITIES } from "./actionTypes";
import { getActivitiesFail, getActivitiesSuccess } from "./actions";

import { getActivities } from "helpers/backend_helper";

function* fetchActivities({ payload }) {
  try {
    const response = yield call(getActivities, payload);

    if (response.status == "failure") {
      return yield put(getActivitiesFail(response.message));
    }

    yield put(getActivitiesSuccess(response));
  } catch (error) {
    yield put(getActivitiesFail(error));
  }
}

function* onAddNewActivity({ payload: { item, history } }) {
  try {
    const response = yield call(postActivity, item);

    if (response.status == "failure") {
      window.scrollTo(0, 0);

      return yield put(addActivityFail(response.message));
    }

    history && history.goBack();

    yield put(addActivitySuccess());
  } catch (error) {
    yield put(addActivityFail("Internal Error!"));
  }
}

function* fetchActivity({ payload }) {
  try {
    const response = yield call(getActivity, payload);

    if (response.status == "failure") {
      return yield put(getActivityFail(response.message));
    }

    yield put(getActivitySuccess(response.data));
  } catch (error) {
    yield put(getActivityFail(error));
  }
}

function* onPutActivity({ payload: { data, history } }) {
  try {
    const response = yield call(putActivity, data);

    if (response.status == "failure") {
      return yield put(putActivityFail(response.message));
    }

    history && history.goBack();

    yield put(putActivitySuccess());
  } catch (error) {
    yield put(putActivityFail("Internal Error!"));
  }
}

function* onDeleteActivity({ payload: { data, callback } }) {
  try {
    const response = yield call(deleteActivity, data);

    if (response.status == "failure") {
      return yield put(deleteActivityFail(response.message));
    }

    yield put(deleteActivitySuccess());
    callback && callback();
  } catch (error) {
    yield put(deleteActivityFail("Internal Error!"));
  }
}

function* onPutActivitiesStatus({ payload: { data, callback } }) {
  try {
    const response = yield call(putActivitiesStatus, data);

    if (response.status == "failure") {
      return yield put(putActivitiesStatusFail(response.message));
    }

    yield put(putActivitiesStatusSuccess());
    callback && callback();
  } catch (error) {
    yield put(putActivitiesStatusFail("Internal Error!"));
  }
}

function* ActivitiesSaga() {
  yield takeEvery(GET_ACTIVITIES, fetchActivities);
  // yield takeEvery(ADD_ACTIVITY, onAddNewActivity)
  // yield takeEvery(GET_ACTIVITY, fetchActivity)
  // yield takeEvery(PUT_ACTIVITY, onPutActivity)
  // yield takeEvery(DELETE_ACTIVITY, onDeleteActivity)
  // yield takeEvery(PUT_ACTIVITIES_STATUS, onPutActivitiesStatus)
}

export default ActivitiesSaga;
