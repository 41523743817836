import { CountryCodeSelector } from "components/Common/CountryCodeSelector";
import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Row,
  Col,
  Spinner,
} from "reactstrap";
import {
  addSubscriptionPlanRequest,
  updateSubscriptionPlanRequest,
  getOwnerByParkingIdRequest,
  getOwnerByParkingIdFail
} from "store/actions";
import CKEditor from "react-ckeditor-component";
import ParkingSelector from "components/ParkingSelector";
import AsyncSelect from "react-select/async";

const AddSubscription = ({ data, actionType, onFinish, ...props }) => {

  console.log("Pinga ", data);

  const [fields, setFields] = useState({
    name: "",
    description: "",
    type: "month",
    duration: 1,
    price: 0,
  });

  const [selectedCollection, setSelectedCollection] = useState(null);
  // let { parkingOwnerDetails } = actionType == 'add_data' ? {} : useSelector(({ ParkingOwnerDetails }) => ParkingOwnerDetails);
  let { parkingOwnerDetails: parkingOwner } = useSelector(({ ParkingOwnerDetails }) => ParkingOwnerDetails);
  let parkingOwnerDetails = selectedCollection ? parkingOwner : null;
  if (actionType === "edit_data") {
    parkingOwnerDetails = data?.parkingId?.owner_id === parkingOwner?._id ? parkingOwner : null;
  }

  // const [isOwnerDetails, setIsOwnerDetails] = useState(parkingOwnerDetails )
  useEffect(() => {
    if (actionType == 'add_data') {
      setFields({
        name: "",
        description: "",
        type: "month",
        duration: 1,
        price: 0,
      });

    }

    if (data && actionType == 'edit_data') {
      setFields({
        name: data?.name,
        description: data?.description,
        type: data?.type,
        duration: data?.duration,
        price: data?.price,
      });

      setSelectedCollection([
        { value: data?.parkingId?._id, label: data?.parkingId?.building_name },
      ]);
      if (data?.parkingId?._id) {
        dispatch(getOwnerByParkingIdRequest(data?.parkingId?._id));

      }
    } else {
      // dispatch(getOwnerByParkingIdFail(data?.parkingId?._id));
      setFields({
        name: "",
        description: "",
        type: "month",
        duration: 1,
        price: 0,
      });
    }
  }, [data]);

  const { loading } = useSelector(({ Subscriptions }) => ({
    loading: Subscriptions?.addUpdateLoading,
  }));
  const dispatch = useDispatch();

  const onFieldsChange = (e) => {
    const { name, value } = e.target;
    setFields((prev) => ({ ...prev, [name]: value }));
  };
  const onChangeCollectionFilter = (data) => {
    dispatch(getOwnerByParkingIdRequest(data?.value));
    setFields((prev) => ({ ...prev, parkingId: data?.value }));
    setSelectedCollection(data);
  };
  const onSubmit = (e) => {
    e.preventDefault();
    if (data?._id) {
      dispatch(
        updateSubscriptionPlanRequest(
          { _id: data?._id, parkingId: selectedCollection?.value ? selectedCollection?.value : selectedCollection[0].value, ...fields },
          () => {
            props.onhide();
            onFinish();
          }
        )
      );
    } else {
      dispatch(
        addSubscriptionPlanRequest(
          { ...fields, parkingId: selectedCollection?.value },
          () => {
            props.onhide();
            onFinish();
          }
        )
      );
    }
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      className="addslot_modalfrom"
      centered
    >
      <Modal.Body>
        <div className="Add_slot_form">
          <Form onSubmit={onSubmit}>
            <div className="form_header position-relative">
              <a
                href="javascript:void(0)"
                className="close_btn"
                onClick={props.onhide}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="21"
                  viewBox="0 0 24 21"
                  fill="none"
                >
                  <path
                    d="M0.94 21L10.12 8.25L10.09 12.48L1.27 -1.43051e-06H7.96L13.6 8.13L10.75 8.16L16.3 -1.43051e-06H22.72L13.9 12.24V8.04L23.2 21H16.36L10.63 12.45H13.36L7.72 21H0.94Z"
                    fill="#2E3A59"
                  />
                </svg>
              </a>
              <h3>
                {data?._id ? props.t("update_plan") : props.t("add_plan")}
              </h3>
            </div>

            <div className="form_layout mt-5">
              <Row>
                <Col lg={6} md={12} sm={12}>
                  <FormGroup>
                    <Label for="exampleEmail">{props.t("name")}</Label>
                    <Input
                      type="text"
                      name="name"
                      value={fields.name}
                      id="exampleEmail"
                      placeholder=""
                      onChange={onFieldsChange}
                    />
                  </FormGroup>
                </Col>
                <Col lg={6} md={6} sm={12}>
                  <FormGroup>
                    <Label for="exampleEmail">{props.t("owner_subscription_msg")}</Label>
                    {/* <Input
                      type="select"
                      name="parkingId"
                      id="exampleEmail"
                      value={fields.parkingId}
                      // placeholder="BG"
                      onChange={onFieldsChange}
                    /> */}
                    {data?.parkingId ? (
                      <AsyncSelect
                        isMulti={false}
                        value={selectedCollection}
                        cacheOptions
                        defaultOptions={selectedCollection}
                      />
                    ) : (
                      <ParkingSelector
                        placeholder="select parking"
                        isMulti={false}
                        defaultSelected={selectedCollection}
                        onChangeHandler={onChangeCollectionFilter}
                      />
                    )}
                    {
                      parkingOwnerDetails &&
                      <>
                        <Label for="exampleEmail">{props.t("owner")}</Label>
                        <Input
                          type="text"
                          name="parkingId"
                          id="exampleEmail"
                          value={parkingOwnerDetails?.name || 'N/A'}
                          disabled

                        />
                      </>

                    }
                  </FormGroup>
                </Col>

                <Col lg={12} md={12} sm={12}>
                  <FormGroup>
                    <Label for="exampleEmail">{props.t("description")}</Label>
                    <CKEditor
                      content={fields.description}
                      events={{
                        change: (evt) => {
                          const data = evt?.editor?.getData();
                          onFieldsChange({
                            target: { value: data, name: "description" },
                          });
                        },
                      }}
                      config={{
                        height: 250,
                        toolbar: [
                          [
                            "Undo",
                            "Redo",
                            "Font",
                            "FontSize",
                            "Styles",
                            "Format",
                            "-",
                            "Maximize",
                            "-",
                            "Source",
                          ],
                          [
                            "Bold",
                            "Italic",
                            "Underline",
                            "Strike",
                            "-",
                            "RemoveFormat",
                            "-",
                            "NumberedList",
                            "BulletedList",
                          ],
                          [
                            "Link",
                            "Unlink",
                            "-",
                            "JustifyLeft",
                            "JustifyCenter",
                            "JustifyRight",
                            "JustifyBlock",
                            "-",
                            "Outdent",
                            "Indent",
                            "-",
                            "TextColor",
                            "BGColor",
                          ],
                          [
                            "Image",
                            "Table",
                            "HorizontalRule",
                            "SpecialChar",
                            "-",
                            "Blockquote",
                          ],
                        ],
                      }}
                      scriptUrl="https://cdn.ckeditor.com/4.16.0/full/ckeditor.js"
                    />
                  </FormGroup>
                </Col>
                <Col lg={6} md={12} sm={12}>
                  <FormGroup>
                    <Label for="exampleEmail">{props.t("type")}</Label>
                    <Input type="select" name="type" onChange={onFieldsChange}>
                      <option value="month">Month</option>
                      <option value="year">Year</option>
                    </Input>
                  </FormGroup>
                </Col>
                <Col lg={6} md={12} sm={12}>
                  <FormGroup>
                    <Label for="exampleEmail">{props.t("duration")}</Label>
                    <Input
                      type="select"
                      name="duration"
                      onChange={onFieldsChange}
                    >
                      <option value={1}>1</option>
                      <option value={2}>2</option>
                      <option value={3}>3</option>
                      <option value={4}>4</option>
                      <option value={5}>5</option>
                      <option value={6}>6</option>
                      <option value={7}>7</option>
                      <option value={8}>8</option>
                      <option value={9}>9</option>
                      <option value={10}>10</option>
                      <option value={11}>11</option>
                    </Input>
                  </FormGroup>
                </Col>
                <Col lg={6} md={12} sm={12}>
                  <FormGroup>
                    <Label for="exampleEmail">{props.t("price")}</Label>
                    <Input
                      type="text"
                      name="price"
                      value={fields.price}
                      id="exampleEmail"
                      onChange={onFieldsChange}
                    />
                  </FormGroup>
                </Col>
                <Col lg={12}>
                  <Button type="submit" className="add_slot mt-5 w-100">
                    {loading ? (
                      <Spinner className="mx-1" size="sm" color="light" />
                    ) : null}
                    {data?._id ? props.t("update") : props.t("save")}
                  </Button>
                </Col>
              </Row>
            </div>
          </Form>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default withTranslation()(AddSubscription);
