import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { Card, CardBody, Col, Container, Row } from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb2";


const CustomerSupportView = ({
  accessLevel,
  history,
  loading,
  ...props
}) => {

  const [filter, setFilter] = useState({
    orderBy: "_id",
    order: -1,
    page: 0,
    limit: 20,
    search: "",
    fieldName: "",
    fieldValue: "",
  });
 
  return (
    <React.Fragment>

      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Customer Detail"
            breadcrumbItem={props.t("Customer Detail")}
            breadcrumbItems={[{ title: props.t("Customer Detail") }]}
          />

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                     <div className="customer-details">
                            <p><b>Customer Name</b> : </p>
                            <p><b>Message Type </b> : </p>
                            <p><b>Email </b> : </p>
                            <p><b>Mobile Number</b> : </p>
                            <p><b>Message</b> : </p>
                            <p><b>Status</b> : </p>
                    </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

CustomerSupportView.propTypes = {

};

const mapStateToProps = ({  }) => ({

});

const mapDispatchToProps = (dispatch) => ({

});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CustomerSupportView))
);
