import React from "react";
import { Link, useHistory } from "react-router-dom";

import { COUNTRY_OPTIONS } from "helpers/contants";

const SignupForm = (props) => {
  const history = useHistory();
  const { setactiveTab, fields, handleChange, registerUserEmail, apiError } =
    props;

  const submitNext = () => {
    if (!fields?.email) {
      window.scrollTo(0, 0);
      return apiError("Email is required!");
    }
    if (!fields?.firstName) {
      window.scrollTo(0, 0);
      return apiError("First Name is required!");
    }
    if (!fields?.mobileNumber) {
      window.scrollTo(0, 0);
      return apiError("Mobile Number is required!");
    }
    if (!fields?.password) {
      window.scrollTo(0, 0);
      return apiError("Password is required!");
    }

    registerUserEmail(fields, () => {
      setactiveTab(2);
    });
  };

  return (
    <div className="tab">
      <div className="row">
        {/* <div className="col-lg-12">
          <h1>First, let's get your account set up for the Go marketplace.</h1>
        </div> */}

        <a href="javascript:void(0)" onClick={() => history.goBack()}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
          >
            <path
              d="M15 25L5 15L15 5L16.7812 6.75L9.78125 13.75H25V16.25H9.78125L16.7812 23.25L15 25Z"
              fill="black"
            />
          </svg>
        </a>

        <div className="col-lg-12">
          <div className="input-group">
            <label>Your email</label>
            <input
              required
              type="text"
              placeholder="Your email"
              value={fields?.email}
              onChange={handleChange("email")}
            />
          </div>
        </div>

        <div className="col-lg-6 nopaddright">
          <div className="input-group">
            <label>First Name</label>
            <input
              required
              type="text"
              placeholder="First Name"
              value={fields?.firstName}
              onChange={handleChange("firstName")}
            />
          </div>
        </div>

        <div className="col-lg-6 nopaddleft">
          <div className="input-group">
            <label>Last Name</label>
            <input
              required
              type="text"
              placeholder="last Name"
              name="lastName"
              value={fields?.lastName}
              onChange={handleChange("lastName")}
            />
          </div>
        </div>

        <div className="col-lg-12">
          <div className="input-group">
            <label>Mobile Number</label>
            <input
              required
              type="text"
              placeholder="Mobile Number"
              value={fields?.mobileNumber}
              onChange={handleChange("mobileNumber")}
            />
          </div>
        </div>

        <div className="col-lg-12">
          <div className="input-group">
            <label>Password</label>
            <input
              required
              type="password"
              placeholder="Enter Password"
              value={fields?.password}
              onChange={handleChange("password")}
            />
          </div>
        </div>

        <div className="col-lg-12">
          <div className="custom-control custom-checkbox mb-3">
            <input
              required
              type="checkbox"
              className="custom-control-input"
              id="customCheck"
              name="example1"
              checked={fields?.policy}
              onChange={(e) => {
                handleChange("policy")({ target: { value: !fields?.policy } });
              }}
            />

            <label className="custom-control-label" for="customCheck">
              I agree all
              <a href="#" className="colortextlink">
                Term & Conditions
              </a>
            </label>
          </div>
        </div>

        <div className="col-lg-12">
          <button
            type="button"
            className="themebtn fullwidthbtn"
            onClick={submitNext}
          >
            Sign Up
          </button>
        </div>

        <div className="col-lg-12">
          <div className="reglink">
            <p>
              {" "}
              Already have an account?  {" "}
              <Link to="/login" className="colortextlink">
                Log in
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignupForm;
