import ToggleSwitch from "components/Common/ToggleSwitch";
import HolidaySelector from "components/HolidaySelector";
import { DAYS, acceptOnlyNumber } from "helpers/contants";
import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import TimePicker from "react-time-picker";
import Holidays from "date-holidays";
import moment from "moment";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  Spinner,
} from "reactstrap";
import { addZoneRequest, updateZoneRequest } from "store/actions";

const AddZone = ({ data, onFinish, ...props }) => {
  const { loading } = useSelector(({ Dashboard }) => Dashboard);
  const dispatch = useDispatch();
  const [fields, setFields] = useState({
    zone_number: 0,
    zone_name: "",
    stay_time_hours: 0,
    zone_price: 0,
    working_time_start: "09:00 AM",
    working_time_end: "21:00 PM",
    session_delay: 0,
    working_days: [0, 1, 2, 3, 4, 5, 6],
    status: false,
    national_holidays: [],
    working_timings: [0, 1, 2, 3, 4, 5, 6].map((day) => ({
      day: day,
      working_time_start: "09:00 AM",
      working_time_end: "21:00 PM",
    })),
  });

  const getHoldays = (value) => {
    let holiday = new Holidays("BG");
    // let holiday = new Holidays();
    holiday = holiday.getHolidays(moment().year());

    let filterData = holiday.filter(({ date }) => value.includes(date));
    filterData = filterData?.map((_d) => ({
      label: _d.name,
      value: _d.date,
    }));
    return filterData || [];
  };

  useEffect(() => {
    if (data) {
      setFields({
        ...data,
        zone_number: data?.zone_number,
        zone_name: data?.zone_name,
        stay_time_hours: data?.stay_time_hours,
        zone_price: data?.zone_price,
        working_days: data?.working_days || [],
        working_timings: data?.working_timings || [],
        national_holidays: getHoldays(data.national_holidays || []),
      });
    }
  }, [data]);

  const onFieldsChange = (e, type, keyName) => {
    if (type == "asitis") {
      setFields({ ...fields, [keyName]: e });
      return;
    }

    const { name, value } = e.target;
    setFields({ ...fields, [name]: value });
  };

  const handleTimeArray = (data, index, keyName) => {
    try {
      setFields((pre) => ({
        ...pre,
        working_timings: [
          ...pre.working_timings.slice(0, index),
          {
            ...pre.working_timings[index],
            [keyName]: data,
          },
          ...pre.working_timings.slice(index + 1),
        ],
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const handleWeekDays = (key) => {
    if (fields.working_days?.includes(key)) {
      setFields((pre) => ({
        ...pre,
        working_days: pre.working_days.filter((day) => day != key),
        working_timings: pre.working_timings.filter((data) => data.day != key),
      }));
    } else {
      let makeArr = [
        ...fields.working_timings,
        {
          day: key,
          working_time_start: "09:00",
          working_time_end: "21:00",
        },
      ];

      const sortByDayAscending = (a, b) => a.day - b.day;
      setFields((pre) => ({
        ...pre,
        working_days: [...pre.working_days, key],
        working_timings: makeArr.sort(sortByDayAscending),
      }));
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const payload = {
      ...fields,
      national_holidays: fields.national_holidays?.map((d) => d.value),
    };
    if (data?._id) {
      dispatch(
        updateZoneRequest({ _id: data?._id, ...payload }, () => {
          props.onhide();
          onFinish();
        })
      );
    } else {
      dispatch(
        addZoneRequest(payload, () => {
          props.onhide();
          onFinish();
        })
      );
    }
  };


  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      className="addslot_modalfrom"
      centered
    >
      <Modal.Body>
        <div className="Add_slot_form">
          <Form onSubmit={onSubmit}>
            <div className="form_header position-relative">
              <a
                href="javascript:void(0)"
                className="close_btn"
                onClick={props.onhide}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="21"
                  viewBox="0 0 24 21"
                  fill="none"
                >
                  <path
                    d="M0.94 21L10.12 8.25L10.09 12.48L1.27 -1.43051e-06H7.96L13.6 8.13L10.75 8.16L16.3 -1.43051e-06H22.72L13.9 12.24V8.04L23.2 21H16.36L10.63 12.45H13.36L7.72 21H0.94Z"
                    fill="#2E3A59"
                  />
                </svg>
              </a>
              <h3>
                {data?._id ? props.t("update") : props.t("add")}{" "}
                {props.t("zone")}
              </h3>
            </div>

            <div className="form_layout mt-5">
              <Row>
                <Col lg={6} md={12} sm={12}>
                  <FormGroup>
                    <Label for="exampleEmail">{props.t("zone_number")}</Label>
                    <Input
                      type="text"
                      name="zone_number"
                      value={fields.zone_number}
                      id="exampleEmail"
                      placeholder=""
                      onChange={(e) => onFieldsChange(e)}
                    />
                  </FormGroup>
                </Col>

                <Col lg={6} md={12} sm={12}>
                  <FormGroup>
                    <Label for="exampleEmail">{props.t("zone_name")}</Label>
                    <Input
                      type="text"
                      name="zone_name"
                      id="exampleEmail"
                      placeholder=""
                      onChange={(e) => onFieldsChange(e)}
                      value={fields.zone_name}
                    />
                  </FormGroup>
                </Col>
                <Col lg={6} md={12} sm={12}>
                  <FormGroup>
                    <Label for="exampleEmail">
                      {props.t("stay_time_hours")}
                    </Label>
                    <Input
                      type="text"
                      name="stay_time_hours"
                      id="exampleEmail"
                      placeholder=""
                      onChange={(e) => onFieldsChange(e)}
                      value={fields.stay_time_hours}
                    />
                  </FormGroup>
                </Col>
                <Col lg={6} md={12} sm={12}>
                  <FormGroup>
                    <Label for="exampleEmail">{props.t("zone_price")}</Label>
                    <Input
                      type="text"
                      name="zone_price"
                      id="exampleEmail"
                      placeholder=""
                      onChange={(e) => onFieldsChange(e)}
                      value={fields.zone_price}
                    />
                  </FormGroup>
                </Col>

                <Col lg={3}>
                  <FormGroup>
                    <Label for="exampleEmail">
                      {props.t("Enable Working Time")}
                    </Label>

                    <ToggleSwitch
                      id="switch-status"
                      size="l"
                      onLabel="Enable"
                      offLabel="Disable"
                      name="status"
                      onValue={true}
                      offValue={false}
                      checked={fields.status}
                      onChange={onFieldsChange}
                    />
                  </FormGroup>
                </Col>
                {!fields.status && <Col lg={6}></Col>}
                {fields.status && (
                  <>
                    <Col sm={9}>
                      <FormGroup>
                        <Label for="exampleEmail">
                          {props.t("Working Day")}
                        </Label>
                        <div className="row daysList">
                          {Object.entries(DAYS).map(([key, values], index) => {
                            key = parseInt(key);
                            return (
                              <>
                                <input
                                  type="checkbox"
                                  id={`switch-${values}`}
                                  checked={fields.working_days?.includes(key)}
                                  onClick={() => handleWeekDays(key)}
                                />
                                <br />
                                <label htmlFor={`switch-${values}`}>
                                  {values}
                                </label>
                              </>
                            );
                          })}
                        </div>
                      </FormGroup>
                    </Col>

                    {fields.working_timings.map((data, index) => (
                      <>
                        <Col lg={3} md={3} sm={12}>
                          <FormGroup>
                            <Label for="exampleEmail" className="pt-4">
                              {DAYS[data.day]}
                            </Label>
                          </FormGroup>
                        </Col>
                        <Col lg={4} md={4} sm={12}>
                          <FormGroup>
                            <Label for="exampleEmail">
                              {props.t("opening_time")}
                            </Label>
                            <div>
                              <TimePicker
                                onChange={(time) =>
                                  handleTimeArray(
                                    time,
                                    index,
                                    "working_time_start"
                                  )
                                }
                                name="working_time_start"
                                value={data.working_time_start}
                                format="h:mm a"
                                disableClock
                                clearIcon={true}
                              />
                            </div>
                          </FormGroup>
                        </Col>
                        <Col lg={4} md={4} sm={12}>
                          <FormGroup>
                            <Label for="exampleEmail">
                              {props.t("closing_time")}
                            </Label>
                            <div>
                              <TimePicker
                                onChange={(time) =>
                                  handleTimeArray(
                                    time,
                                    index,
                                    "working_time_end"
                                  )
                                }
                                value={data.working_time_end}
                                name="working_time_end"
                                format="h:mm a"
                                disableClock
                                clearIcon={true}
                              />
                            </div>
                          </FormGroup>
                        </Col>
                      </>
                    ))}
                  </>
                )}

                <Col lg={6} md={6} sm={12}>
                  <FormGroup>
                    <Label for="exampleEmail">
                      {props.t("New Session Delay(Hours)*")}
                    </Label>
                    <Input
                      type="number"
                      name="session_delay"
                      value={fields.session_delay}
                      id="exampleEmail"
                      // placeholder="BG"
                      onChange={onFieldsChange}
                      onKeyDown={acceptOnlyNumber}
                      required
                    />
                  </FormGroup>
                </Col>

                <Col lg={6} md={6} sm={12}>
                  <FormGroup>
                    <Label for="exampleEmail">
                      {props.t("National Holidays*")}
                    </Label>
                    <HolidaySelector
                      placeholder={props.t("select")}
                      isMulti={false}
                      value={fields.national_holidays}
                      handleChange={(data) =>
                        onFieldsChange(data, "asitis", "national_holidays")
                      }
                    />
                  </FormGroup>
                </Col>
                <Col lg={12}>
                  <Button type="submit" className="add_slot mt-5 w-100">
                    {loading ? (
                      <Spinner className="mx-1" size="sm" color="light" />
                    ) : null}
                    {data?._id ? props.t("update") : props.t("save")}
                  </Button>
                </Col>
              </Row>
            </div>
          </Form>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default withTranslation()(AddZone);
