import { call, put, takeLatest } from "redux-saga/effects";
import toastr from "toastr";

// Login Redux States
import {
  ADD_CONTACT_US_REQUEST, GET_CONTACT_US_LIST_REQUEST
} from "./actionTypes";
import {
  
  addContactUsFail,
  addContactUsSuccess,
  getContactUsListFail,
  getContactUsListSuccess
} from "./actions";

import {

    addContactUsApi, getContactUsApi

} from "helpers/backend_helper";

function* addContactUsReq({ payload: {data, callback } }) {
  try {
    const response = yield call(addContactUsApi,data)

    if (response.status === "failure") {
        toastr.error(response.message);
      return yield put(addContactUsFail(response.message));
    }
    yield put(addContactUsSuccess(response))
    toastr.success("Your request sended successfully!");
    callback && callback(response);
  } catch (error) {
    toastr.error("Something went wrong!");
    yield put(addContactUsFail(error.message))
  }
}


function* getContactUsListReq({ payload: {data, callback } }) {
  try {
    const response = yield call(getContactUsApi,data)

    if (response.status === "failure") {
        toastr.error(response.message);
      return yield put(getContactUsListFail(response.message));
    }
    yield put(getContactUsListSuccess(response))
    callback && callback(response);
  } catch (error) {
    toastr.error("Something went wrong!");
    yield put(getContactUsListFail(error.message))
  }
}


function* supportSaga() {
  yield takeLatest(ADD_CONTACT_US_REQUEST, addContactUsReq)
  yield takeLatest(GET_CONTACT_US_LIST_REQUEST, getContactUsListReq)
}

export default supportSaga;
