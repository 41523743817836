import {
    ADD_CONTACT_US_REQUEST,
    ADD_CONTACT_US_SUCCESS,
    ADD_CONTACT_US_FAIL,
    GET_CONTACT_US_LIST_REQUEST,
    GET_CONTACT_US_LIST_SUCCESS,
    GET_CONTACT_US_LIST_FAIL,
  } from "./actionTypes"
  

  const initialState = {
    loading : false,
    error : "",
    contactUsList : [],
    totalContactUs : 0
  }
  
  
  const Support = (state = initialState, action) => {
    switch (action.type) {
     
        case ADD_CONTACT_US_REQUEST:
          return {
          ...state,
          loading : true
          }
        case ADD_CONTACT_US_SUCCESS:
          return {
            ...state,
            loading : false,
          }    
      case ADD_CONTACT_US_FAIL:
        return {
          ...state,
          loading : false,
          error: action.payload,
        }
        case GET_CONTACT_US_LIST_REQUEST:
          return {
          ...state,
          loading : true
          }
        case GET_CONTACT_US_LIST_SUCCESS:
          return {
            ...state,
            loading : false,
            contactUsList: action.payload.data,
            totalContactUs : action.payload.totalcount
          }    
      case GET_CONTACT_US_LIST_FAIL:
        return {
          ...state,
          loading : false,
          error: action.payload,
        }          
    }
    return state
  }
  
  export default Support
  