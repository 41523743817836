import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { Card, CardBody, Col, Container, Row } from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb2";


const TransactionView = ({
  accessLevel,
  history,
  loading,
  ...props
}) => {

  const [filter, setFilter] = useState({
    orderBy: "_id",
    order: -1,
    page: 0,
    limit: 20,
    search: "",
    fieldName: "",
    fieldValue: "",
  });
 
  return (
    <React.Fragment>

      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Transaction Detail"
            breadcrumbItem={props.t("Transaction Detail")}
            breadcrumbItems={[{ title: props.t("Transaction Detail") }]}
          />

          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                     <div className="transaction-details">
                            <p><b>Date of transaction</b> : </p>
                            <p><b>Sender</b> : </p>
                            <p><b>Bank Name</b> : </p>
                            <p><b>Branch</b> : </p>
                            <p><b>Account</b> : </p>
                            <p><b>Receiver</b> : </p>
                            <p><b>Amount to Transafer</b> : </p>
                            <p><b>Transafer Status</b> : </p>
                            <p><b>Method of Transfer</b> : </p>
                            <p><b>Amount </b> : </p>
                    </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

TransactionView.propTypes = {

};

const mapStateToProps = ({  }) => ({

});

const mapDispatchToProps = (dispatch) => ({

});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(TransactionView))
);
