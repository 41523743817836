import React, { useEffect, useState, memo, useCallback, useMemo } from "react";
import moment from "moment";

import Select from "react-select";
import Holidays from "date-holidays";

// Store

function SelectComp(props) {
  const [options, setOptions] = useState([]);


  const getHoldays = (country) => {
    let holiday = new Holidays(country);
    holiday = holiday.getHolidays(moment().year());

    manuplateValue(holiday);
  };

  useEffect(() => {
    getHoldays("BG");
  }, []);



  ///////// Memorize option ////////

  const manuplateValue = (arr) => {
    let result = arr?.map((_d) => ({
      label: _d.name,
      value: _d.date,
    }));
    setOptions(result);
  };

  return (
    <Select
      options={options}
      value={props.value}
      onChange={props.handleChange}
      isMulti={true}
    />
  );
}

export default memo(SelectComp);
