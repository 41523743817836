import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

import { ADD_CITY_REQUEST, ADD_COMPANY_REQUEST, ADD_COUNTRY_REQUEST, ADD_ZONE_REQUEST, GET_CITIES_REQUEST, GET_CITY_REQUEST, GET_COMPANIES_REQUEST, GET_COMPANY_REQUEST, GET_COUNTRIES_REQUEST, GET_COUNTRY_REQUEST, GET_ZONES_REQUEST, GET_ZONE_REQUEST, REMOVE_CITY_REQUEST, REMOVE_COMPANY_REQUEST, REMOVE_COUNTRY_REQUEST, REMOVE_ZONE_REQUEST, UPDATE_CITY_REQUEST, UPDATE_COMPANY_REQUEST, UPDATE_COUNTRY_REQUEST, UPDATE_ZONE_REQUEST, GET_GRAPH_DATA_REQUEST, GET_STATS_DATA_REQUEST } from "./actionTypes"
import { addCityFail, addCitySuccess, addCompanyFail, addCompanySuccess, addCountryFail, addCountrySuccess, addZoneFail, getCitiesFail, getCitiesSuccess, getCityFail, getCitySuccess, getCompaniesFail, getCompaniesSuccess, getCompanyFail, getCompanySuccess, getCountriesFail, getCountriesSuccess, getCountryFail, getCountrySuccess, getZoneFail, getZoneSuccess, getZonesFail, getZonesSuccess, removeCityFail, removeCitySuccess, removeCompanyFail, removeCompanySuccess, removeCountryFail, removeCountrySuccess, removeZoneFail, removeZoneSuccess, updateCityFail, updateCitySuccess, updateCompanyFail, updateCompanySuccess, updateCountryFail, updateCountrySuccess, updateZoneFail, updateZoneSuccess, getGraphDataFail, getGraphDataSuccess, getStatsDataFail, getStatsDataSuccess, getTransactionFail, getTransactionSuccess, importSensorRequest, importSensorFail, importSensorSuccess } from "./actions"

import { addCityApi, addCompanyApi, addCountryApi, addZoneApi, getCityApi, getCityListApi, getCompaniesListApi, getCompanyApi, getCountryApi, getCountryListApi, getGraphData, getStatsData, getTransaction, getZoneApi, getZoneListApi, importSensor, removeCityApi, removeCompanyApi, removeCountryApi, removeZoneApi, updateCityApi, updateCompanyApi, updateCountryApi, updateZoneApi } from "helpers/backend_helper"
import toastr from "toastr"


import * as CONST from "./actionTypes";


//company

function* getCompaniesReq({ payload: { data, callback } }) {
  try {
    const response = yield call(getCompaniesListApi, data);

    if (response.status == "failure") {
      return yield put(getCompaniesFail(response.message));
    }

    yield put(getCompaniesSuccess(response));
    callback && callback(data);
  } catch (error) {
    yield put(getCompaniesFail(error));
  }
}


function* getCompanyReq({ payload: { data, callback } }) {
  try {
    const response = yield call(getCompanyApi, data);

    if (response.status == "failure") {
      return yield put(getCompanyFail(response.message));
    }

    yield put(getCompanySuccess(response));
    callback && callback(data);
  } catch (error) {
    yield put(getCompanyFail(error));
  }
}


function* addCompanyReq({ payload: { data, callback } }) {
  try {
    const response = yield call(addCompanyApi, data);

    if (response.status == "failure") {
      toastr.error(response.message);
      return yield put(addCompanyFail(response.message));
    }
    yield put(addCompanySuccess(response));
    toastr.success("Company added successfully!")
    callback && callback(data);
  } catch (error) {
    toastr.error("Something went wrong!");
    yield put(addCompanyFail(error));
  }
}


function* updateCompanyReq({ payload: { data, callback } }) {
  try {
    const response = yield call(updateCompanyApi, data);

    if (response.status == "failure") {
      toastr.error(response.message);
      return yield put(updateCompanyFail(response.message));
    }

    yield put(updateCompanySuccess(response));
    toastr.success("Update company successfully!")
    callback && callback(data);
  } catch (error) {
    toastr.error("Something went wrong!");
    yield put(updateCompanyFail(error));
  }
}


function* removeCompanyReq({ payload: { data, callback } }) {
  try {
    const response = yield call(removeCompanyApi, data);

    if (response.status == "failure") {
      toastr.error(response.message);
      return yield put(removeCompanyFail(response.message));
    }
    toastr.success("Company removed successfully!");
    yield put(removeCompanySuccess(response));
    callback && callback(data);
  } catch (error) {
    toastr.error("Something went wrong!");
    yield put(removeCompanyFail(error));
  }
}



//country

function* getCountriesReq({ payload: { data, callback } }) {
  try {
    const response = yield call(getCountryListApi, data);

    if (response.status == "failure") {
      return yield put(getCountriesFail(response.message));
    }

    yield put(getCountriesSuccess(response));
    callback && callback(data);
  } catch (error) {
    yield put(getCountriesFail(error));
  }
}


function* getCountryReq({ payload: { data, callback } }) {
  try {
    const response = yield call(getCountryApi, data);

    if (response.status == "failure") {
      return yield put(getCountryFail(response.message));
    }

    yield put(getCountrySuccess(response));
    callback && callback(data);
  } catch (error) {
    yield put(getCountryFail(error));
  }
}


function* addCountryReq({ payload: { data, callback } }) {
  try {
    const response = yield call(addCountryApi, data);

    if (response.status == "failure") {
      toastr.error(response.message);
      return yield put(addCountryFail(response.message));
    }

    yield put(addCountrySuccess(response));
    toastr.success("Country added successfully!")
    callback && callback(data);
  } catch (error) {
    toastr.error("Something went wrong!")
    yield put(addCountryFail(error));
  }
}


function* updateCountryReq({ payload: { data, callback } }) {
  try {
    const response = yield call(updateCountryApi, data);

    if (response.status == "failure") {
      toastr.error(response.message)
      return yield put(updateCountryFail(response.message));
    }
    toastr.success("Country updated successfully!")
    yield put(updateCountrySuccess(response));
    callback && callback(data);
  } catch (error) {
    toastr.error("Something went wrong!")
    yield put(updateCountryFail(error));
  }
}


function* removeCountryReq({ payload: { data, callback } }) {
  try {
    const response = yield call(removeCountryApi, data);

    if (response.status == "failure") {
      toastr.error(response.message);
      return yield put(removeCountryFail(response.message));
    }

    toastr.success("Country removed successfully!")
    yield put(removeCountrySuccess(response));
    callback && callback(data);
  } catch (error) {
    toastr.error("Something went wrong!");
    yield put(removeCountryFail(error));
  }
}


//city

function* getCitiesReq({ payload: { data, callback } }) {
  try {
    const response = yield call(getCityListApi, data);

    if (response.status == "failure") {
      return yield put(getCitiesFail(response.message));
    }

    yield put(getCitiesSuccess(response));
    callback && callback(data);
  } catch (error) {
    yield put(getCitiesFail(error));
  }
}


function* getCityReq({ payload: { data, callback } }) {
  try {
    const response = yield call(getCityApi, data);

    if (response.status == "failure") {
      return yield put(getCityFail(response.message));
    }

    yield put(getCitySuccess(response));
    callback && callback(data);
  } catch (error) {
    yield put(getCityFail(error));
  }
}


function* addCityReq({ payload: { data, callback } }) {
  try {
    const response = yield call(addCityApi, data);

    if (response.status == "failure") {
      toastr.error(response.message);
      return yield put(addCityFail(response.message));
    }
    toastr.success("City added successfully!")
    yield put(addCitySuccess(response));
    callback && callback(data);
  } catch (error) {
    toastr.error("Something went wrong!")
    yield put(addCityFail(error));
  }
}


function* updateCityReq({ payload: { data, callback } }) {
  try {
    const response = yield call(updateCityApi, data);

    if (response.status == "failure") {
      toastr.error(response.message);

      return yield put(updateCityFail(response.message));
    }
    toastr.success("City updated successfully!")

    yield put(updateCitySuccess(response));
    callback && callback(data);
  } catch (error) {
    toastr.error("Something went wrong!")

    yield put(updateCityFail(error));
  }
}


function* removeCityReq({ payload: { data, callback } }) {
  try {
    const response = yield call(removeCityApi, data);

    if (response.status == "failure") {
      toastr.error(response.message);
      return yield put(removeCityFail(response.message));
    }
    toastr.success("City removed successfully!")

    yield put(removeCitySuccess(response));
    callback && callback(data);
  } catch (error) {
    toastr.error("Something went wrong!")

    yield put(removeCityFail(error));
  }
}


//zones
function* getZonesReq({ payload: { data, callback } }) {
  try {
    const response = yield call(getZoneListApi, data);

    if (response.status == "failure") {
      return yield put(getZonesFail(response.message));
    }

    yield put(getZonesSuccess(response));
    callback && callback(data);
  } catch (error) {
    yield put(getZonesFail(error));
  }
}


function* getZoneReq({ payload: { data, callback } }) {
  try {
    const response = yield call(getZoneApi, data);

    if (response.status == "failure") {
      return yield put(getZoneFail(response.message));
    }

    yield put(getZoneSuccess(response));
    callback && callback(data);
  } catch (error) {
    yield put(getZoneFail(error));
  }
}


function* addZoneReq({ payload: { data, callback } }) {
  try {
    const response = yield call(addZoneApi, data);

    if (response.status == "failure") {
      toastr.error(response.message);

      return yield put(addZoneFail(response.message));
    }
    toastr.success("Zone added successfully!");
    yield put(addZoneFail(response));
    callback && callback(data);
  } catch (error) {
    toastr.error("Something went wrong!")

    yield put(addZoneFail(error));
  }
}


function* updateZoneReq({ payload: { data, callback } }) {
  try {
    const response = yield call(updateZoneApi, data);

    if (response.status == "failure") {
      toastr.error(response.message);

      return yield put(updateZoneFail(response.message));
    }
    toastr.success("Zone updated successfully!");

    yield put(updateZoneSuccess(response));
    callback && callback(data);
  } catch (error) {
    toastr.error("Something went wrong!")

    yield put(updateZoneFail(error));
  }
}


function* removeZoneReq({ payload: { data, callback } }) {
  try {
    const response = yield call(removeZoneApi, data);

    if (response.status == "failure") {
      toastr.error(response.message);
      return yield put(removeZoneFail(response.message));
    }
    toastr.success("Zone removed successfully!");
    yield put(removeZoneSuccess(response));
    callback && callback(data);
  } catch (error) {
    toastr.error("Something went wrong!");
    yield put(removeZoneFail(error));
  }
}


function* getGraphDataSaga({ payload: { data, callback } }) {
  try {
    const response = yield call(getGraphData, data);

    if (response.status == "failure") {
      toastr.error(response.message);
      return yield put(getGraphDataFail(response.message));
    }

    yield put(getGraphDataSuccess(response));
    callback && callback(data);
  } catch (error) {
    toastr.error("Something went wrong!");
    yield put(getGraphDataFail(error));
  }
}


function* getStatsDataSaga({ payload: { data, callback } }) {
  try {
    const response = yield call(getStatsData, data);

    if (response.status == "failure") {
      toastr.error(response.message);
      return yield put(getStatsDataFail(response.message));
    }

    yield put(getStatsDataSuccess(response));
    callback && callback(data);
  } catch (error) {
    toastr.error("Something went wrong!");
    yield put(getStatsDataFail(error));
  }
}

function* getTransactionSaga({ payload: { data, callback } }) {
  try {
    const response = yield call(getTransaction, data);

    if (response.status == "failure") {
      toastr.error(response.message);
      return yield put(getTransactionFail(response.message));
    }

    yield put(getTransactionSuccess(response));
    callback && callback(data);
  } catch (error) {
    toastr.error("Something went wrong!");
    yield put(getStatsDataFail(error));
  }
}

function* importSensorCSVData({ payload: { data, callback } }) {
  try {
    const response = yield call(importSensor, data);

    if (response.status == "failure") {
      toastr.error(response.message);
      return yield put(importSensorFail(response.message));
    }

    yield put(importSensorSuccess(response));
    callback && callback(data);
  } catch (error) {
    toastr.error("Something went wrong!");
    yield put(importSensorFail(error));
  }
}


function* DashboardSaga() {
  yield takeLatest(GET_COMPANIES_REQUEST, getCompaniesReq);
  yield takeLatest(GET_COMPANY_REQUEST, getCompanyReq);
  yield takeLatest(ADD_COMPANY_REQUEST, addCompanyReq);
  yield takeLatest(UPDATE_COMPANY_REQUEST, updateCompanyReq);
  yield takeLatest(REMOVE_COMPANY_REQUEST, removeCompanyReq);

  yield takeLatest(GET_COUNTRIES_REQUEST, getCountriesReq);
  yield takeLatest(GET_COUNTRY_REQUEST, getCountryReq);
  yield takeLatest(ADD_COUNTRY_REQUEST, addCountryReq);
  yield takeLatest(UPDATE_COUNTRY_REQUEST, updateCountryReq);
  yield takeLatest(REMOVE_COUNTRY_REQUEST, removeCountryReq);

  yield takeLatest(GET_CITIES_REQUEST, getCitiesReq);
  yield takeLatest(GET_CITY_REQUEST, getCityReq);
  yield takeLatest(ADD_CITY_REQUEST, addCityReq);
  yield takeLatest(UPDATE_CITY_REQUEST, updateCityReq);
  yield takeLatest(REMOVE_CITY_REQUEST, removeCityReq);

  yield takeLatest(GET_ZONES_REQUEST, getZonesReq);
  yield takeLatest(GET_ZONE_REQUEST, getZoneReq);
  yield takeLatest(ADD_ZONE_REQUEST, addZoneReq);
  yield takeLatest(UPDATE_ZONE_REQUEST, updateZoneReq);
  yield takeLatest(REMOVE_ZONE_REQUEST, removeZoneReq);

  yield takeLatest(GET_STATS_DATA_REQUEST, getStatsDataSaga);
  yield takeLatest(GET_GRAPH_DATA_REQUEST, getGraphDataSaga);
  yield takeLatest(CONST.GET_TRANSACTION_REQUEST, getTransactionSaga);
  yield takeLatest(CONST.IMPORT_SENSOR_REQUEST, importSensorCSVData);
}


export default DashboardSaga
