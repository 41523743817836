import React, { useState, useEffect, useCallback } from "react";
import SocketIo from "socket.io-client";
const obj = JSON.parse(localStorage.getItem("authUser"));
const baseApiUrl = process.env.REACT_APP_API_SOCKET_URL;
export default function useSocket(url = baseApiUrl, data = {}) {
  const [socket, setSocket] = useState({});
  const connect = useCallback(
    ({ userId }) => {
      try {
        console.log("Connecting Socket");
        const io = SocketIo(url);
        if (io) {
          console.log("Socket Found");
          io.emit(
            "customersocket",
            { customerId: userId, ...data },
            (response) => {
              console.log("Socket Connected", response);
              //const { success } = response;
              setSocket({
                ...response,
                io,
              });
            }
          );
        } else {
          throw new Error("Socket Not Found");
        }
      } catch (error) {
        console.log("Socket Error", error);
        setSocket({});
      }
    },
    [url, data]
  );

  useEffect(() => {
    connect({ userId: obj?.data?._id});
    return () => socket?.io?.close?.();
  }, []);

  return socket;
}
