import { CountryCodeSelector } from "components/Common/CountryCodeSelector";
import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Row,
  Col,
  Spinner,
} from "reactstrap";
import { addCountryRequest, updateCountryRequest } from "store/actions";

const AddCountry = ({data,onFinish,...props}) => {
  const {loading} = useSelector(({Dashboard}) => Dashboard);
const dispatch = useDispatch();
    const [fields,setFields] = useState({
        country_abbreviation : "",
        country_name : ""
      });

   useEffect(()=>{
     if(data){
        setFields({
            country_abbreviation : data?.country_abbreviation || "",
            country_name : data?.country_name || "",
        })
     }
   },[data]);
   
   
    const onFieldsChange = (e) =>{
        const {name,value} = e.target;
        setFields({...fields,[name]:value})
      }

      const onSubmit = (e) =>{
        e.preventDefault();
        console.log("fields",fields)
        const payload = {...fields};
       if(data?._id){
    
        dispatch(updateCountryRequest({_id : data?._id,...payload}, ()=>{
            props.onhide();
            onFinish();
        }))
       }else{
        dispatch(addCountryRequest(payload, ()=>{
            props.onhide();
            onFinish();
        }))
       }
      
      }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      className="addslot_modalfrom"
      centered
    >
      <Modal.Body>
        <div className="Add_slot_form">
          <Form onSubmit={onSubmit}>
            <div className="form_header position-relative">
              <a
                href="javascript:void(0)"
                className="close_btn"
                onClick={props.onhide}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="21"
                  viewBox="0 0 24 21"
                  fill="none"
                >
                  <path
                    d="M0.94 21L10.12 8.25L10.09 12.48L1.27 -1.43051e-06H7.96L13.6 8.13L10.75 8.16L16.3 -1.43051e-06H22.72L13.9 12.24V8.04L23.2 21H16.36L10.63 12.45H13.36L7.72 21H0.94Z"
                    fill="#2E3A59"
                  />
                </svg>
              </a>
              <h3>{data?._id ? props.t("update") : props.t("add")} {props.t("country")}</h3>
            </div>

            <div className="form_layout mt-5">

              <Row>
                <Col lg={6} md={12} sm={12}>
                  <FormGroup>
                    <Label for="exampleEmail">{props.t("short_name")}</Label>
                    <Input
                      type="text"
                      name="country_abbreviation"
                      value={fields.country_abbreviation}
                      id="exampleEmail"
                      placeholder=""
                      onChange={(e)=>onFieldsChange(e)}
                    />
                  </FormGroup>
                </Col>

                <Col lg={6} md={12} sm={12}>
                  <FormGroup>
                    <Label for="exampleEmail">{props.t("name")}</Label>
                    <Input
                      type="text"
                      name="country_name"
                      id="exampleEmail"
                      placeholder=""
                      onChange={(e)=>onFieldsChange(e)}
                       value={fields.country_name}
                    />
                  </FormGroup>
                </Col>
                <Col lg={12}> 
                   <Button type="submit" className="add_slot mt-5 w-100">
                        {loading ? 
                            <Spinner  className="mx-1" size="sm" color="light"/> 
                        : null}
                    {data?._id ? props.t("update") : props.t("save")}</Button>
                </Col>
              </Row>
            </div>
          </Form>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default  withTranslation()(AddCountry);
