import { call, put, takeEvery, takeLatest } from "redux-saga/effects"
import toastr from "toastr"
// ContentPage Redux States
import { ADD_SENSOR_REQUEST, GET_SENSORS_REQUEST, GET_SENSOR_REQUEST, REMOVE_SENSOR_REQUEST, UPDATE_SENSOR_REQUEST } from "./actionTypes"
import { addSensorFail, addSensorSuccess, getSensorFail, getSensorRequest, getSensorSuccess, getSensorsFail, getSensorsSuccess, removeSensorFail, removeSensorSuccess, updateSensorFail, updateSensorRequest, updateSensorSuccess } from "./actions"

import { addSensorApi, getSensorApi, getSensorsListApi, removeSensorApi, updateSensorApi } from "helpers/backend_helper"


function* getSensorsReq({ payload : {data,callback} }) {
  try {
    const response = yield call(getSensorsListApi, data)

    if (response.status == "failure") {
      console.log("jsfdkkjdsf", response)
      return yield put(getSensorsFail(response.message))
    }
    yield put(getSensorsSuccess(response))
    callback && callback();
  } catch (error) {
    console.log("error", error)
    yield put(getSensorsFail(error))
  }
}

function* addSensorReq({ payload: { data, callback } }) {
  try {
    const response = yield call(addSensorApi, data)

    if (response.status == "failure") {
        toastr.error(response.message)
      return yield put(addSensorFail(response.message))
    }
    yield put(addSensorSuccess(response))
    toastr.success("Censor add successfully!")
    callback && callback(response);
  } catch (error) {
    toastr.error("Internal Error!")
    yield put(addSensorFail("Internal Error!"))
  }
}



function* getSensorReq({ payload: { data, callback } }) {
  try {
    const response = yield call(getSensorApi, data)

    if (response.status == "failure") {
        toastr.error(response.message)
      return yield put(getSensorFail(response.message))
    }
    yield put(getSensorSuccess(response))
    callback && callback(response);
  } catch (error) {
    toastr.error("Internal Error!")
    yield put(getSensorFail("Internal Error!"))
  }
}


function* updateSensorReq({ payload: { data, callback } }) {
  try {
    const response = yield call(updateSensorApi, data)

    if (response.status == "failure") {
        toastr.error(response.message)
      return yield put(updateSensorFail(response.message))
    }
    yield put(updateSensorSuccess(response))
    toastr.success("Sensor updated successfully!")
    callback && callback(response);
  } catch (error) {
    toastr.error("Internal Error!")
    yield put(updateSensorFail("Internal Error!"))
  }
}



function* removeSensorReq({ payload: { data, callback } }) {
  try {
    const response = yield call(removeSensorApi, data)

    if (response.status == "failure") {
        toastr.error(response.message)
      return yield put(removeSensorFail(response.message))
    }
    yield put(removeSensorSuccess(response))
    toastr.success("Sensor removed successfully!")
    callback && callback(response);
  } catch (error) {
    toastr.error("Internal Error!")
    yield put(removeSensorFail("Internal Error!"))
  }
}

function* SensorSaga() {
  yield takeLatest(ADD_SENSOR_REQUEST, addSensorReq)
  yield takeLatest(GET_SENSORS_REQUEST, getSensorsReq)
  yield takeLatest(GET_SENSOR_REQUEST, getSensorReq)
  yield takeLatest(UPDATE_SENSOR_REQUEST, updateSensorReq)
  yield takeLatest(REMOVE_SENSOR_REQUEST, removeSensorReq)
}

export default SensorSaga
