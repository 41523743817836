import { all, fork } from "redux-saga/effects";

import Alert from "./Alert/saga";

//public
import AuthSaga from "./auth/login/saga";
import ForgetSaga from "./auth/forgetpwd/saga";
import ProfileSaga from "./auth/profile/saga";
import LayoutSaga from "./layout/saga";
import Register from "./auth/register/saga";
import Settings from "./Settings/saga";
import Customers from "./customers/saga";

// File
import File from "./File/saga";

import StakeSaga from "./stake/saga";

// Faqs
import documents from "./document/saga";

// Content Page
import content_page from "./contentpage/saga";
import Collection from "./collection/saga";
import ActivitiesSaga from "./activity/saga";
// Faqs
import faqs from "./faq/saga";

//nft 
import Items from "./items/saga";
import CategoryesSaga from "./categoryes/saga"


//Subscriptions
import Subscriptions from "./subscriptions/saga";

//sensors
import Sensors from "./sensor/saga";

import Dashboard from "./dashboard/saga";


//Parkings
import Parkings from "./parkings/saga";

import Support from "./support/saga";

import Bookings from "./bookings/saga";

export default function* rootSaga() {
  yield all([
    fork(Alert),
    fork(AuthSaga),
    ProfileSaga(),
    ForgetSaga(),
    Register(),
    LayoutSaga(),
    Settings(),
    content_page(),
    fork(StakeSaga),
    fork(Customers),
    fork(File),
    fork(documents),
    fork(Collection),
    fork(ActivitiesSaga),
    fork(faqs),
    fork(Items),
    fork(CategoryesSaga),
    fork(Subscriptions),
    fork(Sensors),
    fork(Dashboard),
    fork(Parkings),
    fork(Support),
    fork(Bookings)
  ]);
}
