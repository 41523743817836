//company
export const GET_COMPANIES_REQUEST = "GET_COMPANIES_REQUEST"
export const GET_COMPANIES_SUCCESS = "GET_COMPANIES_SUCCESS"
export const GET_COMPANIES_FAIL = "GET_COMPANIES_FAIL"

export const GET_COMPANY_REQUEST = "GET_COMPANY_REQUEST"
export const GET_COMPANY_SUCCESS = "GET_COMPANY_SUCCESS"
export const GET_COMPANY_FAIL = "GET_COMPANY_FAIL"

export const ADD_COMPANY_REQUEST = "ADD_COMPANY_REQUEST";
export const ADD_COMPANY_SUCCESS = "ADD_COMPANY_SUCCESS";
export const ADD_COMPANY_FAIL = "ADD_COMPANY_FAIL";

export const UPDATE_COMPANY_REQUEST = "UPDATE_COMPANY_REQUEST";
export const UPDATE_COMPANY_SUCCESS = "UPDATE_COMPANY_SUCCESS";
export const UPDATE_COMPANY_FAIL = "UPDATE_COMPANY_FAIl";

export const REMOVE_COMPANY_REQUEST = "REMOVE_COMPANY_REQUEST";
export const REMOVE_COMPANY_SUCCESS = "REMOVE_COMPANY_SUCCESS";
export const REMOVE_COMPANY_FAIL = "REMOVE_COMPANY_FAIl";


//country
export const GET_COUNTRIES_REQUEST = "GET_COUNTRIES_REQUEST"
export const GET_COUNTRIES_SUCCESS = "GET_COUNTRIES_SUCCESS"
export const GET_COUNTRIES_FAIL = "GET_COUNTRIES_FAIL"

export const GET_COUNTRY_REQUEST = "GET_COUNTRY_REQUEST"
export const GET_COUNTRY_SUCCESS = "GET_COUNTRY_SUCCESS"
export const GET_COUNTRY_FAIL = "GET_COUNTRY_FAIL"

export const ADD_COUNTRY_REQUEST = "ADD_COUNTRY_REQUEST";
export const ADD_COUNTRY_SUCCESS = "ADD_COUNTRY_SUCCESS";
export const ADD_COUNTRY_FAIL = "ADD_COUNTRY_FAIL";

export const UPDATE_COUNTRY_REQUEST = "UPDATE_COUNTRY_REQUEST";
export const UPDATE_COUNTRY_SUCCESS = "UPDATE_COUNTRY_SUCCESS";
export const UPDATE_COUNTRY_FAIL = "UPDATE_COUNTRY_FAIl";

export const REMOVE_COUNTRY_REQUEST = "REMOVE_COUNTRY_REQUEST";
export const REMOVE_COUNTRY_SUCCESS = "REMOVE_COUNTRY_SUCCESS";
export const REMOVE_COUNTRY_FAIL = "REMOVE_COUNTRY_FAIl";

//city
export const GET_CITIES_REQUEST = "GET_CITIES_REQUEST"
export const GET_CITIES_SUCCESS = "GET_CITIES_SUCCESS"
export const GET_CITIES_FAIL = "GET_CITIES_FAIL"

export const GET_CITY_REQUEST = "GET_CITY_REQUEST"
export const GET_CITY_SUCCESS = "GET_CITY_SUCCESS"
export const GET_CITY_FAIL = "GET_CITY_FAIL"

export const ADD_CITY_REQUEST = "ADD_CITY_REQUEST";
export const ADD_CITY_SUCCESS = "ADD_CITY_SUCCESS";
export const ADD_CITY_FAIL = "ADD_CITY_FAIL";

export const UPDATE_CITY_REQUEST = "UPDATE_CITY_REQUEST";
export const UPDATE_CITY_SUCCESS = "UPDATE_CITY_SUCCESS";
export const UPDATE_CITY_FAIL = "UPDATE_CITY_FAIl";

export const REMOVE_CITY_REQUEST = "REMOVE_CITY_REQUEST";
export const REMOVE_CITY_SUCCESS = "REMOVE_CITY_SUCCESS";
export const REMOVE_CITY_FAIL = "REMOVE_CITY_FAIl";

//zone
export const GET_ZONES_REQUEST = "GET_ZONES_REQUEST"
export const GET_ZONES_SUCCESS = "GET_ZONES_SUCCESS"
export const GET_ZONES_FAIL = "GET_ZONES_FAIL"

export const GET_ZONE_REQUEST = "GET_ZONE_REQUEST"
export const GET_ZONE_SUCCESS = "GET_ZONE_SUCCESS"
export const GET_ZONE_FAIL = "GET_ZONE_FAIL"

export const ADD_ZONE_REQUEST = "ADD_ZONE_REQUEST";
export const ADD_ZONE_SUCCESS = "ADD_ZONE_SUCCESS";
export const ADD_ZONE_FAIL = "ADD_ZONE_FAIL";

export const UPDATE_ZONE_REQUEST = "UPDATE_ZONE_REQUEST";
export const UPDATE_ZONE_SUCCESS = "UPDATE_ZONE_SUCCESS";
export const UPDATE_ZONE_FAIL = "UPDATE_ZONE_FAIL";

export const REMOVE_ZONE_REQUEST = "REMOVE_ZONE_REQUEST";
export const REMOVE_ZONE_SUCCESS = "REMOVE_ZONE_SUCCESS";
export const REMOVE_ZONE_FAIL = "REMOVE_ZONE_FAIL";


export const GET_GRAPH_DATA_REQUEST = "GET_GRAPH_DATA_REQUEST";
export const GET_GRAPH_DATA_SUCCESS = "GET_GRAPH_DATA_SUCCESS";
export const GET_GRAPH_DATA_FAIL = "GET_GRAPH_DATA_FAIL";

export const GET_STATS_DATA_REQUEST = "GET_STATS_DATA_REQUEST";
export const GET_STATS_DATA_SUCCESS = "GET_STATS_DATA_SUCCESS";
export const GET_STATS_DATA_FAIL = "GET_STATS_DATA_FAIL";


export const GET_TRANSACTION_REQUEST = "GET_TRANSACTION_REQUEST";
export const GET_TRANSACTION_SUCCESS = "GET_TRANSACTION_SUCCESS";
export const GET_TRANSACTION_FAIL = "GET_TRANSACTION_FAIL";

export const IMPORT_SENSOR_REQUEST = "IMPORT_SENSOR_REQUEST";
export const IMPORT_SENSOR_SUCCESS = "IMPORT_SENSOR_SUCCESS";
export const IMPORT_SENSOR_FAIL = "IMPORT_SENSOR_FAIL";
