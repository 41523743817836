import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";

import { Col, Row, Spinner } from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";

//Import Breadcrumb
import ListColumns from "./Columns";
import {getBookingsRequest} from "store/actions";


const CustomerSupport = ({
  accessLevel,
  history,
  userId,
  loading,
  bookingsList,
  getBookingsRequest,
  totalBookings,
  ...props
}) => {
  const [filter, setFilter] = useState({
    orderBy: "_id",
    order: -1,
    page: 0,
    limit: 20,
    search: "",
    fieldName: "",
    fieldValue: "",
  });

  const pageOptions = {
    sizePerPage: 20,
    totalSize: totalBookings,
    custom: true,
    onPageChange: (page) => setFilter((prevState) => ({ ...prevState, page })),
  };

  useEffect(() => {
    if (userId) {
         getBookingsRequest({...filter,userId : userId});
      }
  }, [userId]);

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { searchText, sortField, sortOrder }) => {
    if (type === "sort") {
      if (sortField == "createdAt") {
        sortField = "created_date";
      }

      if (sortOrder == "desc") {
        sortOrder = -1;
      } else if (sortOrder == "asc") {
        sortOrder = 1;
      }

      setFilter((prevState) => ({
        ...prevState,
        orderBy: sortField,
        order: sortOrder,
      }));
    }
  };

  const onFilterChange = (value) => {
    setFilter((prevState) => ({
      ...prevState,
      fieldName: "type",
      fieldValue: value,
    }));
  };

  const toggleDetailsModal = (details) => {
    setdetailsModal((prevState) => ({
      isOpen: !prevState.isOpen,
      details,
    }));
  };

  return (
          <Row>
            <Col xs="12">
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="_id"
                        data={bookingsList || []}
                        columns={ListColumns(
                          toggleDetailsModal,
                          history,
                          props.t
                        )}
                        bootstrap4
                        search
                      >
                        {(toolkitProps) => (
                          <React.Fragment>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive spinner-content">
                                  <BootstrapTable
                                    responsive
                                    remote
                                    bordered={false}
                                    striped={false}
                                    classes={
                                      "table table-centered table-nowrap"
                                    }
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    onTableChange={handleTableChange}
                                    {...paginationTableProps}
                                    defaultSorted={[
                                      {
                                        dataField: "createdAt",
                                        order: "desc",
                                      },
                                    ]}
                                    sort={{
                                      sortCaret: (order) =>
                                        order === "asc" ? (
                                          <span className="caret">
                                            <i className="bx bx-caret-up" />
                                          </span>
                                        ) : order === "desc" ? (
                                          <span className="caret">
                                            <i className="bx bx-caret-down" />
                                          </span>
                                        ) : null,
                                    }}
                                  />

                                  {loading && (
                                    <div className="spinner">
                                      <Spinner color="primary" />
                                    </div>
                                  )}
                                </div>
                              </Col>
                            </Row>
                            <Row className="align-stakes-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
            </Col>
          </Row>

  );
};

const mapStateToProps = ({ Bookings, Login }) => ({
  user : Login.user,
   loading: Bookings?.loading,
   bookingsList : Bookings?.bookingsList,
   totalBookings : Bookings?.totalBookings
});

const mapDispatchToProps = (dispatch) => ({
    getBookingsRequest: (data,callback) => dispatch(getBookingsRequest(data,callback)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CustomerSupport))
);
