import {
    GET_USER_BOOKINGS_REQUEST,
    GET_USER_BOOKINGS_SUCCESS,
    GET_USER_BOOKINGS_FAIL,
    GET_OWNER_BOOKINGS_REQUEST,
    GET_OWNER_BOOKINGS_SUCCESS,
    GET_OWNER_BOOKINGS_FAIL,
    GET_BOOKINGS_REQUEST,
    GET_BOOKINGS_SUCCESS,
    GET_BOOKINGS_FAIL,
    GET_BOOKING_DETAILS_REQUEST,
    GET_BOOKINGS_DETAILS_SUCCESS,
    GET_BOOKINGS_DETAILS_FAIL,
    } from "./actionTypes";
    
    //booking
    
    export const getUserBookingsRequest = (data,callback) =>{
      return {
        type: GET_USER_BOOKINGS_REQUEST,
        payload: {data, callback },
      }
    }
    export const getUserBookingsSuccess = (data) =>{
      return {
        type: GET_USER_BOOKINGS_SUCCESS,
        payload: data,
      }
    }
    export const getUserBookingsFail = (error) =>{
      return {
        type: GET_USER_BOOKINGS_FAIL,
        payload: error,
     }
    }
    

export const getOwnerBookingsRequest = (data,callback) =>{
        return {
            type: GET_OWNER_BOOKINGS_REQUEST,
            payload: {data, callback },
        }
    }
    export const getOwnerBookingsSuccess = (data) =>{
        return {
            type: GET_OWNER_BOOKINGS_SUCCESS,
            payload: data,
        }
    }
    export const getOwnerBookingsFail = (error) =>{
        return {
            type: GET_OWNER_BOOKINGS_FAIL,
            payload: error,
        }
  }
    

export const getBookingsRequest = (data,callback) =>{
    return {
        type: GET_BOOKINGS_REQUEST,
        payload: {data, callback },
    }
}
export const getBookingsSuccess = (data) =>{
    return {
        type: GET_BOOKINGS_SUCCESS,
        payload: data,
    }
}
export const getBookingsFail = (error) =>{
    return {
        type: GET_BOOKINGS_FAIL,
        payload: error,
    }
}



export const getBookingDetailsRequest = (data,callback) =>{
    return {
        type: GET_BOOKING_DETAILS_REQUEST,
        payload: {data, callback },
    }
}
export const getBookingDetailsSuccess = (data) =>{
    return {
        type: GET_BOOKINGS_DETAILS_SUCCESS,
        payload: data,
    }
}
export const getBookingDetailsFail = (error) =>{
    return {
        type: GET_BOOKINGS_DETAILS_FAIL,
        payload: error,
    }
}


