import {
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_FAIL,
  CHANGE_PASSWORD_SUCCESS,
} from "./actionTypes"


export const putChangePwd = (data, callback) => {
  return {
    type: CHANGE_PASSWORD,
    payload: { data, callback },
  }
}

export const putChangePwdSuccess = msg => {
  return {
    type: CHANGE_PASSWORD_SUCCESS,
    payload: msg,
  }
}

export const putChangePwdError = error => {
  return {
    type: CHANGE_PASSWORD_FAIL,
    payload: error,
  }
}
