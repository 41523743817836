export const GET_USER_BOOKINGS_REQUEST =   "GET_USER_BOOKINGS_REQUEST";
export const GET_USER_BOOKINGS_SUCCESS =   "GET_USER_BOOKINGS_SUCCESS";
export const GET_USER_BOOKINGS_FAIL =   "GET_USER_BOOKINGS_FAIL";

export const GET_OWNER_BOOKINGS_REQUEST =   "GET_OWNER_BOOKINGS_REQUEST";
export const GET_OWNER_BOOKINGS_SUCCESS =   "GET_OWNER_BOOKINGS_SUCCESS";
export const GET_OWNER_BOOKINGS_FAIL =   "GET_OWNER_BOOKINGS_FAIL";


export const GET_BOOKINGS_REQUEST =   "GET_BOOKINGS_REQUEST";
export const GET_BOOKINGS_SUCCESS =   "GET_BOOKINGS_SUCCESS";
export const GET_BOOKINGS_FAIL = "GET_BOOKINGS_FAIL";

export const GET_BOOKING_DETAILS_REQUEST =   "GET_BOOKINGS_DETAILS_REQUEST";
export const GET_BOOKINGS_DETAILS_SUCCESS =   "GET_BOOKINGS_DETAILS_SUCCESS";
export const GET_BOOKINGS_DETAILS_FAIL = "GET_BOOKINGS_DETAILS_FAIL";

