import {
  ADD_SUBSCRIPTION_PLAN_FAIL,
  ADD_SUBSCRIPTION_PLAN_REQUEST,
  ADD_SUBSCRIPTION_PLAN_SUCCESS,
  CANCEL_SUBSCRIPTION_FAIL,
  CANCEL_SUBSCRIPTION_REQUEST,
  CANCEL_SUBSCRIPTION_SUCCESS,
  DELETE_SUBSCRIPTION_PLAN_FAIL,
  DELETE_SUBSCRIPTION_PLAN_REQUEST,
  DELETE_SUBSCRIPTION_PLAN_SUCCESS,
  GET_SUBSCRIPTIONS_FAIL,
  GET_SUBSCRIPTIONS_PLAN_FAIL,
  GET_SUBSCRIPTIONS_PLAN_REQUEST,
  GET_SUBSCRIPTIONS_PLAN_SUCCESS,
  GET_SUBSCRIPTIONS_REQUEST,
  GET_SUBSCRIPTIONS_SUCCESS,
  GET_SUBSCRIPTION_PLAN_FAIL,
  GET_SUBSCRIPTION_PLAN_REQUEST,
  GET_SUBSCRIPTION_PLAN_SUCCESS,
  GET_SUBSCRIPTION_USERS_FAIL,
  GET_SUBSCRIPTION_USERS_REQUEST,
  GET_SUBSCRIPTION_USERS_SUCCESS,
  REMOVE_SUBSCRIPTIONS_FAIL,
  REMOVE_SUBSCRIPTIONS_REQUEST,
  REMOVE_SUBSCRIPTIONS_SUCCESS,
  UPDATE_SUBSCRIPTION_PLAN_FAIL,
  UPDATE_SUBSCRIPTION_PLAN_REQUEST,
  UPDATE_SUBSCRIPTION_PLAN_SUCCESS,
  ASSIGN_SUBSCRIPTION_TO_USER_FAIL,
  ASSIGN_SUBSCRIPTION_TO_USER_REQUEST,
  ASSIGN_SUBSCRIPTION_TO_USER_SUCCESS,
} from "./actionTypes";

//subscriptions

export const getSubscriptionsRequest = (data, callback) => {
  return {
    type: GET_SUBSCRIPTIONS_REQUEST,
    payload: { data, callback },
  };
};
export const getSubscriptionsSuccess = (data) => {
  return {
    type: GET_SUBSCRIPTIONS_SUCCESS,
    payload: data,
  };
};
export const getSubscriptionsFail = (error) => {
  return {
    type: GET_SUBSCRIPTIONS_FAIL,
    payload: error,
  };
};

export const removeSubscriptionsRequest = (data, callback) => {
  return {
    type: REMOVE_SUBSCRIPTIONS_REQUEST,
    payload: { data, callback },
  };
};
export const removeSubscriptionsSuccess = (data) => {
  return {
    type: REMOVE_SUBSCRIPTIONS_SUCCESS,
    payload: data,
  };
};
export const removeSubscriptionsFail = (error) => {
  return {
    type: REMOVE_SUBSCRIPTIONS_FAIL,
    payload: error,
  };
};

/* add */
export const addSubscriptionPlanRequest = (data, callback) => ({
  type: ADD_SUBSCRIPTION_PLAN_REQUEST,
  payload: { data, callback },
});

export const addSubscriptionPlanSuccess = (data) => ({
  type: ADD_SUBSCRIPTION_PLAN_SUCCESS,
  payload: data,
});

export const addSubscriptionPlanFail = (error) => ({
  type: ADD_SUBSCRIPTION_PLAN_FAIL,
  payload: error,
});

/*update */
export const updateSubscriptionPlanRequest = (data, callback) => ({
  type: UPDATE_SUBSCRIPTION_PLAN_REQUEST,
  payload: { data, callback },
});

export const updateSubscriptionPlanSuccess = (data) => ({
  type: UPDATE_SUBSCRIPTION_PLAN_SUCCESS,
  payload: data,
});

export const updateSubscriptionPlanFail = (error) => ({
  type: UPDATE_SUBSCRIPTION_PLAN_FAIL,
  payload: error,
});

/*delete */
export const deleteSubscriptionPlanRequest = (data, callback) => ({
  type: DELETE_SUBSCRIPTION_PLAN_REQUEST,
  payload: { data, callback },
});

export const deleteSubscriptionPlanSuccess = (data) => ({
  type: DELETE_SUBSCRIPTION_PLAN_SUCCESS,
  payload: data,
});

export const deleteSubscriptionPlanFail = (error) => ({
  type: DELETE_SUBSCRIPTION_PLAN_FAIL,
  payload: error,
});

/*get */
export const getSubscriptionsPlanRequest = (data, callback) => ({
  type: GET_SUBSCRIPTIONS_PLAN_REQUEST,
  payload: { data, callback },
});

export const getSubscriptionsPlanSuccess = (data) => ({
  type: GET_SUBSCRIPTIONS_PLAN_SUCCESS,
  payload: data,
});

export const getSubscriptionsPlanFail = (error) => ({
  type: GET_SUBSCRIPTIONS_PLAN_FAIL,
  payload: error,
});

/*get */
export const getSubscriptionPlanRequest = (data, callback) => ({
  type: GET_SUBSCRIPTION_PLAN_REQUEST,
  payload: { data, callback },
});

export const getSubscriptionPlanSuccess = (data) => ({
  type: GET_SUBSCRIPTION_PLAN_SUCCESS,
  payload: data,
});

export const getSubscriptionPlanFail = (error) => ({
  type: GET_SUBSCRIPTION_PLAN_FAIL,
  payload: error,
});

/*cancel subscription */
export const cancelSubscriptionRequest = (data, callback) => ({
  type: CANCEL_SUBSCRIPTION_REQUEST,
  payload: { data, callback },
});

export const cancelSubscriptionSuccess = (data) => ({
  type: CANCEL_SUBSCRIPTION_SUCCESS,
  payload: data,
});

export const cancelSubscriptionFail = (error) => ({
  type: CANCEL_SUBSCRIPTION_FAIL,
  payload: error,
});

/*get subscription users*/
export const getSubscriptionUsersRequest = (data, callback) => ({
  type: GET_SUBSCRIPTION_USERS_REQUEST,
  payload: { data, callback },
});

export const getSubscriptionUsersSuccess = (data) => ({
  type: GET_SUBSCRIPTION_USERS_SUCCESS,
  payload: data,
});

export const getSubscriptionUsersFail = (error) => ({
  type: GET_SUBSCRIPTION_USERS_FAIL,
  payload: error,
});

/*assign subscription to users*/
export const assignSubscriptionToUserRequest = (data, callback) => ({
  type: ASSIGN_SUBSCRIPTION_TO_USER_REQUEST,
  payload: { data, callback },
});

export const assignSubscriptionToUserSuccess = (data) => ({
  type: ASSIGN_SUBSCRIPTION_TO_USER_SUCCESS,
  payload: data,
});

export const assignSubscriptionToUserFail = (error) => ({
  type: ASSIGN_SUBSCRIPTION_TO_USER_FAIL,
  payload: error,
});
