import * as CONST from "./actionTypes";

const INIT_STATE = {
  items: [],
  totalItems: 0,
  error: "",
  loading: false,
  item: {},
  notifications: [],
  singleImage: {},
  totalNotifications: 0,
};

const Items = (state = INIT_STATE, action) => {
  switch (action.type) {
    /* Get Items */
    case CONST.GET_ITEMS:
      return {
        ...state,
        loading: true,
      };

    case CONST.GET_ITEMS_SUCCESS:
      return {
        ...state,
        items: action.payload.data || [],
        totalItems: action.payload.totalCount || 0,
        loading: false,
        error: "",
      };

    case CONST.GET_ITEMS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    /* Get Items END */

    /* Add Item */
    case CONST.ADD_ITEM:
    case CONST.UPDATE_ITEM:
      return {
        ...state,
        error: "",
        loading: true,
      };

    case CONST.ADD_ITEM_FAIL:
    case CONST.UPDATE_ITEM_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case CONST.ADD_ITEM_SUCCESS:
    case CONST.UPDATE_ITEM_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
      };
    /* Add Item END */

    /* Get Item */
    case CONST.GET_ITEM:
      return {
        ...state,
        error: "",
        loading: true,
        item: {},
      };

    case CONST.GET_ITEM_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        item: {},
      };

    case CONST.GET_ITEM_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
        item: action.payload,
      };
    /* Add Item END */

    /* Update Item */
    case CONST.DEPLOY_ITEM:
      return {
        ...state,
        error: "",
        loading: true,
      };

    case CONST.DEPLOY_ITEM_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case CONST.DEPLOY_ITEM_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
      };
    /* Update Item END */

    /* Delete Item */
    case CONST.DELETE_ITEM:
      return {
        ...state,
        error: "",
        loading: true,
      };

    case CONST.DELETE_ITEM_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case CONST.RESET_VIEW_ITEM:
      return {
        ...state,
        item: {},
      };
    case CONST.DELETE_ITEM_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
      };
    /* Delete Item END */

    /* Update Items Status */
    case CONST.DEPLOY_ITEMS_STATUS:
      return {
        ...state,
        error: "",
        loading: true,
      };

    case CONST.DEPLOY_ITEMS_STATUS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    case CONST.DEPLOY_ITEMS_STATUS_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
      };
    /* Update Items Status END */

    /* Get Notifications */
    case CONST.GET_NOTIFICATIONS:
      return {
        ...state,
        loading: true,
      };

    case CONST.GET_NOTIFICATIONS_FAIL:
      return {
        ...state,
        loading: false,
      };

    case CONST.GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        notifications: action.payload.data,
        totalNotifications: action.payload.totalCount,
      };
    /* Get Notification END */

    case CONST.ITEM_API_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    //Backgroun image crud
    case CONST.ADD_BACKGROUND_IMAGE:
      return {
        ...state,
        loading: true,
      };

    case CONST.ADD_BACKGROUND_IMAGE_FAIL:
      return {
        ...state,
        loading: false,
      };

    case CONST.ADD_BACKGROUND_IMAGE_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case CONST.GET_LIST_BACKGROUND_IMAGE:
      return {
        ...state,
        loading: true,
      };

    case CONST.GET_LIST_BACKGROUND_IMAGE_FAIL:
      return {
        ...state,
        loading: false,
      };

    case CONST.GET_LIST_BACKGROUND_IMAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        imageList: action.payload.data,
        totalImage: action.payload.totalCount,
      };

    case CONST.VIEW_BACKGROUND_IMAGE:
      return {
        ...state,
        loading: true,
      };

    case CONST.VIEW_BACKGROUND_IMAGE_FAIL:
      return {
        ...state,
        loading: false,
      };

    case CONST.VIEW_BACKGROUND_IMAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        singleImage: action.payload.data,
      };

    case CONST.DELETE_BACKGROUND_IMAGE:
      return {
        ...state,
        loading: true,
      };

    case CONST.DELETE_BACKGROUND_IMAGE_FAIL:
      return {
        ...state,
        loading: false,
      };

    case CONST.DELETE_BACKGROUND_IMAGE_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};

export default Items;
