import React from "react";
import { Link } from "react-router-dom";
import { Badge, Input, Label, UncontrolledTooltip } from "reactstrap";
import moment from "moment-timezone";

export const selectRow = (props) => ({
  mode: "checkbox",
  clickToSelect: true,
  selectionHeaderRenderer: ({ indeterminate, mode, ...rest }) => (
    <div className="custom-control custom-checkbox">
      <Input
        type="checkbox"
        className="custom-control-input"
        ref={(input) => {
          if (input) input.indeterminate = indeterminate;
        }}
        {...rest}
      />
      <Label className="custom-control-label">&nbsp;</Label>
    </div>
  ),
  selectionRenderer: ({ mode, rowKey, ...rest }) => (
    <div className="custom-control custom-checkbox" key={rowKey}>
      <input type="checkbox" className="custom-control-input" {...rest} />
      <Label className="custom-control-label">&nbsp;</Label>
    </div>
  ),
  ...props,
});

const FORMAT_TEXT = (text) => {
  const text_ = text?.length > 10 ? text?.substring(0, 10) + '...' : text
  return text_
}

const CustomersColumns = (onShowInfoModal,t) => [
  {
    dataField: "name",
    text: t("name"),
    sort: true,
    formatter: (_, row) => (row?.name ? FORMAT_TEXT(row.name) : '-'),
    // formatter: (_, row) => {
    //   return <p>{stringShortner(row?.name, 25)}</p>
    // }
  },
  {
    dataField: "mobileNumber",
    text: t("mobile"),
    sort: true,
    formatter: (_, row) => (row.phone ? row.phone : "-"),
  },
  {
    dataField: "email",
    text: t("email"),
    sort: true,
  },
  {
    dataField: "date_created_utc",
    text: t("created_at"),
    sort: true,
    formatter: (_, row) =>
      moment(row.created_at).utcOffset(330).format("DD MMM YYYY LT"),
  },
  {
    isDummyField: true,
    text: t("action"),
    dataField: "action",
    formatter: (_, row) => (
      <>
      <Link to={"#"} className="mx-2 text-secondary" onClick={()=>onShowInfoModal(row)}>
        <svg
           id={`view-${row._id}-tooltip`}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <g clip-path="url(#clip0_102_1928)">
              <path
                d="M23.6766 11.5625C21.321 6.32616 16.9811 2.81396 12.0168 2.81396V20.6007C16.9811 20.6007 21.321 17.0885 23.6766 11.8521C23.6875 11.8277 23.6955 11.8021 23.7006 11.7758V11.6388C23.6956 11.6126 23.6875 11.587 23.6766 11.5625Z"
                fill="#FFAD84"
              />
              <path
                d="M22.0727 11.7073C20.0654 6.39099 16.3139 2.81396 12.0169 2.81396C7.00696 2.81396 2.63307 6.39099 0.292725 11.7073C2.63307 17.0236 7.00696 20.6007 12.0169 20.6007C16.3139 20.6007 20.0654 17.0236 22.0727 11.7073Z"
                fill="#FFD4A1"
              />
              <path
                d="M23.7007 11.6388C21.3507 7.62956 16.9984 4.93701 12.0168 4.93701V18.4776C16.9984 18.4776 21.3507 15.785 23.7007 11.7758C23.7096 11.7306 23.7096 11.684 23.7007 11.6388Z"
                fill="#FE8566"
              />
              <path
                d="M22.0727 11.7073C20.0654 7.66013 16.3139 4.93701 12.0169 4.93701C7.56449 4.93701 3.6146 7.08789 1.14786 10.4074C0.644874 10.6245 0.292725 11.1247 0.292725 11.7073C0.292725 12.2899 0.644874 12.7902 1.14786 13.0072C3.6146 16.3267 7.56449 18.4776 12.0169 18.4776C16.3139 18.4776 20.0654 15.7545 22.0727 11.7073Z"
                fill="#FFAD84"
              />
              <path
                d="M18.161 8.02379C16.307 6.93044 14.1824 6.35254 12.0168 6.35254V17.0623C14.1824 17.0623 16.3071 16.4844 18.161 15.3911C19.7224 14.4703 21.0616 13.2068 22.0726 11.7074C21.0616 10.2081 19.7224 8.94459 18.161 8.02379Z"
                fill="#FFFBBE"
              />
              <path
                d="M20.6573 11.7074C19.7886 10.2081 18.6379 8.94455 17.2962 8.02379C15.7032 6.93044 13.8776 6.35254 12.0169 6.35254C9.8513 6.35254 7.72661 6.93044 5.87268 8.02379C4.31127 8.94459 2.97212 10.2081 1.96106 11.7074C2.97212 13.2068 4.31127 14.4703 5.87268 15.3911C7.72666 16.4844 9.8513 17.0623 12.0169 17.0623C13.8776 17.0623 15.7032 16.4844 17.2962 15.3911C18.6379 14.4703 19.7886 13.2068 20.6573 11.7074Z"
                fill="white"
              />
              <path
                d="M12.0168 6.35254V17.0623C14.9743 17.0623 17.3717 14.6649 17.3717 11.7074C17.3717 8.75002 14.9743 6.35254 12.0168 6.35254Z"
                fill="#01B1BC"
              />
              <path
                d="M15.9564 11.7074C15.9564 8.75002 14.1926 6.35254 12.0169 6.35254C9.05947 6.35254 6.66199 8.75002 6.66199 11.7074C6.66199 14.6649 9.05947 17.0623 12.0169 17.0623C14.1926 17.0623 15.9564 14.6649 15.9564 11.7074Z"
                fill="#3AC7CF"
              />
              <path
                d="M12.0168 9.18311V14.2313C13.4109 14.2313 14.541 13.1012 14.541 11.7072C14.541 10.3132 13.4109 9.18311 12.0168 9.18311Z"
                fill="#20335A"
              />
              <path
                d="M13.1256 11.7072C13.1256 10.3132 12.6293 9.18311 12.0169 9.18311C10.6229 9.18311 9.4928 10.3132 9.4928 11.7072C9.4928 13.1012 10.6229 14.2313 12.0169 14.2313C12.6293 14.2313 13.1256 13.1013 13.1256 11.7072Z"
                fill="#2B4478"
              />
            </g>
            <defs>
              <clipPath id="clip0_102_1928">
                <rect
                  width="23.4146"
                  height="23.4146"
                  fill="white"
                  transform="translate(0.292725)"
                />
              </clipPath>
            </defs>
          </svg>
          <UncontrolledTooltip
            placement="top"
            target={`view-${row._id}-tooltip`}
          >
            {t("View")} {t("Details")}
          </UncontrolledTooltip>
        </Link>
      </>
    ),
  },
];

export default CustomersColumns;
