import { call, put, takeEvery, takeLatest, delay } from "redux-saga/effects";
import toastr from "toastr";
// Customer Redux States
import { GET_CUSTOMERS, GET_CUSTOMER, CHANGE_STATUS, GET_PARKING_OWNERS_REQUEST, GET_PARKING_OWNER_BY_ID_REQUEST, ADD_PARKING_OWNER_REQUEST, UPDATE_PARKING_OWNER_REQUEST, REMOVE_PARKING_OWNER_REQUEST, UPDATE_USER_STATUS_REQUEST, REMOVE_CUSTOMER, GET_OWNER_BY_PARKING_ID_REQUEST } from "./actionTypes";
import {
  getCustomersFail,
  getCustomersSuccess,
  getCustomerFail,
  getCustomerSuccess,
  removeCustomerFail,
  removeCustomerSuccess,

} from "./actions";

import {
  getCustomers,
  getCustomer,
  changeStatus,
  getAllParkingOwnersApi,
  getParkingOwnerByIdApi,
  addParkingOwnerApi,
  removeParkingOwnerApi,
  updateParkingOwnerApi,
  updateUserStatusApi,
  removeCustomer,
  getOwnerParkingByIdApi
} from "helpers/backend_helper";

import * as actType from "./actions";

toastr.options = {
  newestOnTop: true,
  progressBar: true,
};

function* fetchCustomers({ payload: { data, callback } }) {
  try {
    const response = yield call(getCustomers, data);

    if (response.status == "failure") {
      return yield put(getCustomersFail(response.message));
    }

    yield put(getCustomersSuccess(response));
    callback && callback(response);
  } catch (error) {
    yield put(getCustomersFail(error));
  }
}

function* fetchCustomer({ payload }) {
  try {
    const response = yield call(getCustomer, payload);

    if (response.status == "failure") {
      return yield put(getCustomerFail(response.message));
    }

    yield put(getCustomerSuccess(response));
  } catch (error) {
    yield put(getCustomerFail(error));
  }
}

function* onChangeStatus({ payload }) {
  try {
    const response = yield call(changeStatus, payload);

    if (response.status == "failure") {
      return yield put(actType.changeStatusFail(response.message));
    }

    yield put(actType.changeStatusSuccess(response));
  } catch (error) {
    yield put(actType.changeStatusFail(error));
  }
}

function* getParkingOwnersReq({ payload: { data, callback } }) {
  try {
    const response = yield call(getAllParkingOwnersApi, data);

    if (response.status == "failure") {
      return yield put(actType.getParkingOwnersFail(response.message));
    }

    yield put(actType.getParkingOwnersSuccess(response));
    callback && callback(response);
  } catch (error) {
    yield put(actType.getParkingOwnersFail(error));
  }
}


function* getParkingOwnerReq({ payload: { data, callback } }) {
  try {
    const response = yield call(getParkingOwnerByIdApi, data);

    if (response.status == "failure") {
      return yield put(actType.getParkingOwnerFail(response.message));
    }

    yield put(actType.getParkingOwnerSuccess(response));
    callback && callback(data);
  } catch (error) {
    yield put(actType.getParkingOwnerFail(error));
  }
}



function* addParkingOwnerReq({ payload: { data, callback } }) {
  try {
    const response = yield call(addParkingOwnerApi, data);

    if (response.status == "failure") {
      toastr.error(response.message)
      return yield put(actType.addParkingOwnerFail(response.message));
    }

    yield put(actType.addParkingOwnerSuccess(response));
    toastr.success("Owner added successfully!")
    callback && callback(data);
  } catch (error) {
    toastr.error("Something went wrong!")

    yield put(actType.addParkingOwnerFail(error));
  }
}



function* removeParkingOwnerReq({ payload: { data, callback } }) {
  try {
    const response = yield call(removeParkingOwnerApi, data);

    if (response.status == "failure") {
      toastr.error(response.message)
      return yield put(actType.removeParkingOwnerFail(response.message));
    }

    yield put(actType.removeParkingOwnerSuccess({ _id: data }));
    toastr.success("Owner deleted successfully!")
    callback && callback(data);
  } catch (error) {
    toastr.error("Something went wrong!")
    yield put(actType.removeParkingOwnerFail(error));
  }
}


function* updateParkingOwnerReq({ payload: { data, callback } }) {
  try {
    const response = yield call(updateParkingOwnerApi, data);

    if (response.status == "failure") {
      toastr.error(response.message)
      return yield put(actType.updateParkingOwnerFail(response.message));
    }

    yield put(actType.updateParkingOwnerSuccess(response));
    toastr.success("Owner updated successfully!")
    callback && callback(data);
  } catch (error) {
    toastr.error("Something went wrong!")

    yield put(actType.updateParkingOwnerFail(error));
  }
}


function* updateUserStatusReq({ payload: { data, callback } }) {
  try {
    const response = yield call(updateUserStatusApi, data);

    if (response.status == "failure") {
      toastr.error(response.message)
      return yield put(actType.updateUserStatusFail(response.message));
    }

    yield put(actType.updateUserStatusSuccess(response));
    toastr.success("Data updated successfully!")
    callback && callback(data);
  } catch (error) {
    toastr.error("Something went wrong!")

    yield put(actType.updateUserStatusFail(error));
  }
}


function* removeCustomerSaga({ payload: { data, callback } }) {
  try {
    const response = yield call(removeCustomer, data);

    if (response.status == "failure") {
      toastr.error(response.message)
      return yield put(actType.removeCustomerFail(response.message));
    }

    yield put(actType.removeCustomerSuccess(response));
    toastr.success("Data updated successfully!")
    callback && callback(response);
  } catch (error) {
    toastr.error("Something went wrong!")

    yield put(actType.removeCustomerFail(error));
  }
}

function* getOwnerByParkingIdReq({ payload: { data, callback } }) {
  try {
    const response = yield call(getOwnerParkingByIdApi, data);

    if (response.status == "failure") {
      return yield put(actType.getOwnerByParkingIdFail(response.message));
    }

    yield put(actType.getOwnerByParkingIdSuccess(response));
    callback && callback(data);
  } catch (error) {
    yield put(actType.getOwnerByParkingIdFail(error));
  }
}

function* customersSaga() {
  yield takeLatest(GET_CUSTOMERS, fetchCustomers);
  yield takeLatest(CHANGE_STATUS, onChangeStatus);
  yield takeLatest(GET_CUSTOMER, fetchCustomer);
  yield takeLatest(GET_PARKING_OWNERS_REQUEST, getParkingOwnersReq);
  yield takeLatest(GET_PARKING_OWNER_BY_ID_REQUEST, getParkingOwnerReq);
  yield takeLatest(ADD_PARKING_OWNER_REQUEST, addParkingOwnerReq);
  yield takeLatest(UPDATE_PARKING_OWNER_REQUEST, updateParkingOwnerReq);
  yield takeLatest(REMOVE_PARKING_OWNER_REQUEST, removeParkingOwnerReq);
  yield takeLatest(UPDATE_USER_STATUS_REQUEST, updateUserStatusReq);
  yield takeLatest(REMOVE_CUSTOMER, removeCustomerSaga);
  yield takeLatest(GET_OWNER_BY_PARKING_ID_REQUEST, getOwnerByParkingIdReq);

}

export default customersSaga;
