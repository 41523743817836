import React from 'react'
import { Container } from 'reactstrap'

import Welcomegreet from 'components/Welcomegreet'
// import Welcomecards from 'components/Welcomecards'
import Subscriptionplan from './subscriptionplan'

function Dashsubscription() {
  return (
    <React.Fragment>
    <div className="page-content">
         <Container fluid>
              <Welcomegreet/>
              {/* <Welcomecards/> */}
              <Subscriptionplan/>
         </Container>
    </div>
    </React.Fragment>
  )
}

export default Dashsubscription