import { call, put, takeEvery } from "redux-saga/effects";

// Collection Redux States
import {
  GET_COLLECTIONS,
  ADD_COLLECTION,
  GET_COLLECTION,
  PUT_COLLECTION,
  DELETE_COLLECTION,
  PUT_COLLECTIONS_STATUS,
  COMPILE_DEPLOY_COLLECTIONS,
} from "./actionTypes";
import {
  getCollectionsFail,
  getCollectionsSuccess,
  addCollectionFail,
  addCollectionSuccess,
  getCollectionFail,
  getCollectionSuccess,
  putCollectionFail,
  putCollectionSuccess,
  deleteCollectionFail,
  deleteCollectionSuccess,
  putCollectionsStatusFail,
  putCollectionsStatusSuccess,
  compileDeployCollectionSuccess,
  compileDeployCollectionFail,
} from "./actions";

import {
  getCollections,
  postCollection,
  getCollection,
  putCollection,
  deleteCollection,
  putCollectionsStatus,
  compileDeployCollection,
} from "helpers/backend_helper";

function* fetchCollections({ payload }) {
  try {
    const response = yield call(getCollections, payload);
    if (response.status == "failure") {
      return yield put(getCollectionsFail(response.message));
    }
    yield put(getCollectionsSuccess(response));
  } catch (error) {
    yield put(getCollectionsFail(error));
  }
}

function* onAddNewCollection({ payload: { collection, callback } }) {
  try {
    const response = yield call(postCollection, collection);
    console.log(response,123)
    if (response.status == "failure") {
      window.scrollTo(0, 0);
      
      return yield put(addCollectionFail(response.message));
    }
    
    yield put(addCollectionSuccess());
    callback && callback(response);
  } catch (error) {
    yield put(addCollectionFail(error));
  }
}

function* fetchCollection({ payload }) {
  try {
    const response = yield call(getCollection, payload);
    if (response.status == "failure") {
      return yield put(getCollectionFail(response.message));
    }
    yield put(getCollectionSuccess(response.data));
  } catch (error) {
    yield put(getCollectionFail(error));
  }
}

function* onPutCollection({ payload: { data, history } }) {
  try {
    const response = yield call(putCollection, data);

    if (response.status == "failure") {
      return yield put(putCollectionFail(response.message));
    }

    history && history.goBack();

    yield put(putCollectionSuccess());
  } catch (error) {
    yield put(putCollectionFail("Internal Error!"));
  }
}

function* onDeleteCollection({ payload: { data, callback } }) {
  try {
    const response = yield call(deleteCollection, data);

    if (response.status == "failure") {
      return yield put(deleteCollectionFail(response.message));
    }

    yield put(deleteCollectionSuccess());
    callback && callback();
  } catch (error) {
    yield put(deleteCollectionFail("Internal Error!"));
  }
}

function* onPutCollectionsStatus({ payload: { data, callback } }) {
  try {
    const response = yield call(putCollectionsStatus, data);

    if (response.status == "failure") {
      return yield put(putCollectionsStatusFail(response.message));
    }

    yield put(putCollectionsStatusSuccess());
    callback && callback();
  } catch (error) {
    yield put(putCollectionsStatusFail("Internal Error!"));
  }
}

function* onCompileDeployCollection({ payload: { data, callback } }) {
  try {
    const response = yield call(compileDeployCollection, data);
    callback && callback(response);
    if (response.status == "failure") {
      return yield put(compileDeployCollectionFail(response.message));
    }
    yield put(compileDeployCollectionSuccess(response));
  } catch (error) {
    yield put(compileDeployCollectionFail(error));
  }
}

function* CollectionsSaga() {
  yield takeEvery(GET_COLLECTIONS, fetchCollections);
  yield takeEvery(ADD_COLLECTION, onAddNewCollection);
  yield takeEvery(GET_COLLECTION, fetchCollection);
  yield takeEvery(PUT_COLLECTION, onPutCollection);
  yield takeEvery(DELETE_COLLECTION, onDeleteCollection);
  yield takeEvery(PUT_COLLECTIONS_STATUS, onPutCollectionsStatus);
  yield takeEvery(COMPILE_DEPLOY_COLLECTIONS, onCompileDeployCollection);
}

export default CollectionsSaga;
