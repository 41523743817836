import { ADD_SENSOR_FAIL, ADD_SENSOR_REQUEST, ADD_SENSOR_SUCCESS, GET_SENSORS_FAIL, GET_SENSORS_REQUEST, GET_SENSORS_SUCCESS, GET_SENSOR_FAIL, GET_SENSOR_REQUEST, GET_SENSOR_SUCCESS, REMOVE_SENSOR_FAIL, REMOVE_SENSOR_REQUEST, REMOVE_SENSOR_SUCCESS, UPDATE_SENSOR_FAIL, UPDATE_SENSOR_REQUEST, UPDATE_SENSOR_SUCCESS } from "./actionTypes";


export const getSensorsRequest = (data, callback) => ({
  type: GET_SENSORS_REQUEST,
  payload: { data, callback },
});

export const getSensorsSuccess = (data) => ({
  type: GET_SENSORS_SUCCESS,
  payload: data
});

export const getSensorsFail = (error) => ({
  type: GET_SENSORS_FAIL,
  payload: error,
});

export const addSensorRequest = (data, callback) => ({
  type: ADD_SENSOR_REQUEST,
  payload: { data, callback },
});

export const addSensorSuccess = (data) => ({
  type: ADD_SENSOR_SUCCESS,
  payload: data
});

export const addSensorFail = (error) => ({
  type: ADD_SENSOR_FAIL,
  payload: error,
});

export const getSensorRequest = (data, callback) => ({
  type: GET_SENSOR_REQUEST,
  payload: { data, callback },
});

export const getSensorSuccess = (data) => ({
  type: GET_SENSOR_SUCCESS,
  payload: data
});

export const getSensorFail = (error) => ({
  type: GET_SENSOR_FAIL,
  payload: error,
});

export const removeSensorRequest = (data, callback) => ({
  type: REMOVE_SENSOR_REQUEST,
  payload: { data, callback },
});

export const removeSensorSuccess = (data) => ({
  type: REMOVE_SENSOR_SUCCESS,
  payload: data
});

export const removeSensorFail = (error) => ({
  type: REMOVE_SENSOR_FAIL,
  payload: error,
});


export const updateSensorRequest = (data, callback) => ({
  type: UPDATE_SENSOR_REQUEST,
  payload: { data, callback },
});

export const updateSensorSuccess = (data) => ({
  type: UPDATE_SENSOR_SUCCESS,
  payload: data
});

export const updateSensorFail = (error) => ({
  type: UPDATE_SENSOR_FAIL,
  payload: error,
});


