import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getSettings, putSettings } from "store/actions";

import {
  Row,
  Col,
  Form,
  FormGroup,
  Button,
  Label,
  Input,
  Container,
  Card,
  CardBody,
  Spinner,
} from "reactstrap";

import Breadcrumbs from "components/Common/Breadcrumb";
import Welcomegreet from "components/Welcomegreet";
import { withTranslation } from "react-i18next";

function get_url_extension(url) {
  return url.split(/[#?]/)[0].split(".").pop().trim();
}

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const GeneralSettings = (props) => {
  const [banersData, setBanersData] = useState([
    {
      productName: "",
      images: "",
      imagesLink: "",
      productId: "",
      id: 1,
      sort: 0,
    },
  ]);

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const { onGetSettings, onPutSettings, loading, onUploadFile } = props;

  const [fields, setFields] = useState({
    App_mode: props.settings.App_mode,
    name: props.settings.name,
    adminCommission: props.settings.adminCommission,
    searchRadiusInKm: props.settings.searchRadiusInKm,
    supportSetting: props.settings?.supportSetting,
    socialMediaSetting: props.settings?.socialMediaSetting || {},
    stripeKey: {
      sandbox: {
        public_key: props.settings?.stripeKey?.sandbox?.public_key,
        secret_key: props.settings?.stripeKey?.sandbox?.secret_key,
      },
      live: {
        public_key: props.settings?.stripeKey?.live?.public_key,
        secret_key: props.settings?.stripeKey?.live?.secret_key,
      },
    },
  });

  useEffect(() => {
    onGetSettings();
  }, []);

  useEffect(() => {
    if (props.settings) {
      setFields((prevState) => ({
        ...prevState,
        App_mode: props.settings.App_mode,
        name: props.settings.name,
        adminCommission: props.settings.adminCommission,
        searchRadiusInKm: props.settings.searchRadiusInKm,
        supportSetting: props.settings?.supportSetting,
        socialMediaSetting: props.settings?.socialMediaSetting || {},
        stripeKey: {
          sandbox: {
            public_key: props.settings?.stripeKey?.sandbox?.public_key,
            secret_key: props.settings?.stripeKey?.sandbox?.secret_key,
          },
          live: {
            public_key: props.settings?.stripeKey?.live?.public_key,
            secret_key: props.settings?.stripeKey?.live?.secret_key,
          },
        },
      }));
    }
  }, [JSON.stringify(props?.settings)]);

  const handleStripeKeysChange = (platform) => (event) => {
    const { name, value } = event.target;
    let stripe = fields.stripeKey;
    stripe[platform][name] = value;
    setFields({ ...fields, stripeKey: { ...fields.stripeKey, ...stripe } });
  };

  useEffect(() => {
    if (fields?.slider && fields.slider.length > 0) {
      let newArrayIds = [];

      fields.slider.map((e) => {
        newArrayIds.push(e._id);
      });
      setSliderData(newArrayIds);
    }
  }, [JSON.stringify(fields?.slider)]);

  const handleChange = (name) => (event) => {
    const { value } = event.target;
    setFields((prevFields) => ({ ...prevFields, [name]: value }));
  };

  const handlebannerChange = (bannerIndex) => (name, data) => {
    setBanersData((prevState) => {
      const updateData = { ...prevState[bannerIndex], [name]: data };

      return [
        ...prevState.slice(0, bannerIndex),
        updateData,
        ...prevState.slice(bannerIndex + 1),
      ];
    });
  };

  const removeData = (bannerIndex) => {
    setBanersData((prevState) => [
      ...prevState.slice(0, bannerIndex),
      ...prevState.slice(bannerIndex + 1),
    ]);
  };

  const uploadFileSuccess = (bannerIndex) => (response) => {
    const { _id, link } = response.data;

    setBanersData((prevState) => {
      const updateData = {
        ...prevState[bannerIndex],
        images: _id,
        imagesLink: link,
      };

      return [
        ...prevState.slice(0, bannerIndex),
        updateData,
        ...prevState.slice(bannerIndex + 1),
      ];
    });
  };

  const changeHandler = (bannerIndex) => (event) => {
    const _files = event.target.files;

    /* if (_files && _files[0] && _files[0].size > 5242880) {
      return toastr.error("Maximum upload file size: 5MB");
    } */

    onUploadFile({ image: _files[0] }, uploadFileSuccess(bannerIndex));
  };

  function submit() {
    onPutSettings({ ...fields, baners: banersData, _id: props.settings._id });
  }

  const onBannerDragEnd = (list) => (result) => {
    const { destination, source } = result;
    if (!destination) return;

    const items = reorder(list, source.index, destination.index);
    setBanersData(items);
  };

  const onChangeSupportSetting = (e) => {
    const { name, value } = e.target;
    setFields((prev) => ({
      ...prev,
      supportSetting: { ...prev.supportSetting, [name]: value },
    }));
  };

  const handleChangeSocialMediaSetting = (event) => {
    const { name, value } = event.target;
    setFields({
      ...fields,
      socialMediaSetting: { ...fields.socialMediaSetting, [name]: value },
    });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg={12}>
              <Welcomegreet />
            </Col>
          </Row>
          <Row className="mt-4">
            <Col lg={12}>
              <h2 class="fillter_head pl-2">
                {props.t("general") + " " + props.t("settings")}
              </h2>
            </Col>
            <Col lg={8}>
              <Form>
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Label>App Mode</Label>

                      <div className="switch-l square-switch">
                        <input
                          type="checkbox"
                          id="App_mode"
                          switch="none"
                          checked={fields.App_mode === "live"}
                          onChange={() => {
                            const value =
                              fields.App_mode === "sandbox"
                                ? "live"
                                : "sandbox";
                            handleChange("App_mode")({ target: { value } });
                          }}
                        />
                        <label
                          htmlFor="App_mode"
                          data-on-label="Live"
                          data-off-label="Sandbox"
                        />
                      </div>
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label>Admin Commission (%)</Label>

                      <Input
                        type="number"
                        value={fields.adminCommission}
                        onChange={(e) => {
                          handleChange("adminCommission")(e);
                        }}
                        min={0}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label>Search Radius (Km)</Label>

                      <Input
                        type="number"
                        value={fields.searchRadiusInKm}
                        onChange={(e) => {
                          handleChange("searchRadiusInKm")(e);
                        }}
                        min={0}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <hr className="my-3" />
                <Row className="mt-4">
                  <Col lg={12}>
                    <div className="label-content">
                      <h4>Support Settings</h4>
                    </div>
                  </Col>

                  <Col lg={8}>
                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Email</Label>

                          <Input
                            name="email"
                            type="text"
                            value={fields.supportSetting?.email}
                            onChange={onChangeSupportSetting}
                          />
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label>Mobile Number</Label>

                          <Input
                            name="mobileNumber"
                            type="text"
                            value={fields.supportSetting?.mobileNumber}
                            onChange={onChangeSupportSetting}
                          />
                        </FormGroup>
                      </Col>

                      <Col md={12}>
                        <FormGroup>
                          <Label>Address</Label>

                          <Input
                            name="address"
                            type="textarea"
                            value={fields.supportSetting?.address}
                            onChange={onChangeSupportSetting}
                            rows={4}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form>
            </Col>

            <Col lg={8} xs={12} md={12} sm={12}>
              <hr className="my-3" />
              <Row>
                <Col lg={4}>
                  <h4>Payment Method</h4>
                  <p>
                    <small>
                      <b>Stripe Keys:</b>
                    </small>
                  </p>
                </Col>
                <Col lg={8}>
                  <Row>
                    <Col lg={6}>
                      <FormGroup>
                        <Label>Sandbox Secert Key</Label>
                        <Input
                          type="text"
                          value={fields.stripeKey.sandbox.secret_key}
                          name="secret_key"
                          onChange={(e) => {
                            handleStripeKeysChange("sandbox")(e);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={6}>
                      <FormGroup>
                        <Label>Sandbox Publishble Key</Label>
                        <Input
                          type="text"
                          value={fields.stripeKey.sandbox.public_key}
                          name="public_key"
                          onChange={(e) => {
                            handleStripeKeysChange("sandbox")(e);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={6}>
                      <FormGroup>
                        <Label>Live Secert Key</Label>
                        <Input
                          type="text"
                          value={fields.stripeKey.live.secret_key}
                          name="secret_key"
                          onChange={(e) => {
                            handleStripeKeysChange("live")(e);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={6}>
                      <FormGroup>
                        <Label>Live Publishble Key</Label>
                        <Input
                          type="text"
                          value={fields.stripeKey.live.public_key}
                          name="public_key"
                          onChange={(e) => {
                            handleStripeKeysChange("live")(e);
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>

            <Col lg={8} xs={12} md={12} sm={12}>
              <hr className="my-3" />
              <h4>Social Media Setting</h4>
              <Row>
                <Col lg={6}>
                  <FormGroup>
                    <Label>Teligram Link</Label>
                    <Input
                      type="text"
                      value={fields.socialMediaSetting?.teligram}
                      name="teligram"
                      placeholder="https://telegram.org/"
                      onChange={(e) => {
                        handleChangeSocialMediaSetting(e);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col lg={6}>
                  <FormGroup>
                    <Label>Instagram Link</Label>
                    <Input
                      type="text"
                      value={fields.socialMediaSetting?.instagram}
                      name="instagram"
                      placeholder="https://instagram.com/"
                      onChange={(e) => {
                        handleChangeSocialMediaSetting(e);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col lg={6}>
                  <FormGroup>
                    <Label>Twitter Link</Label>
                    <Input
                      type="text"
                      value={fields.socialMediaSetting?.twitter}
                      name="twitter"
                      placeholder="https://twitter.com/"
                      onChange={(e) => {
                        handleChangeSocialMediaSetting(e);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col lg={6}>
                  <FormGroup>
                    <Label>Youtube Link</Label>
                    <Input
                      type="text"
                      value={fields.socialMediaSetting?.youtube}
                      placeholder="https://youtube.com/"
                      name="youtube"
                      onChange={(e) => {
                        handleChangeSocialMediaSetting(e);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col lg={6}>
                  <FormGroup>
                    <Label>Facebook Link</Label>
                    <Input
                      type="text"
                      value={fields.socialMediaSetting?.facebook}
                      name="facebook"
                      placeholder="https://facebook.com/"
                      onChange={(e) => {
                        handleChangeSocialMediaSetting(e);
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Col>
            <Col lg={12}>
              <Row>
                <Col lg={12} className="d-flex justify-content-end">
                  {loading && <Spinner color="primary" className="mr-2" />}
                  <Button
                    className="btn-rounded waves-effect waves-light mb-2 mr-2 btn btn-success"
                    disabled={loading}
                    onClick={submit}
                  >
                    Save Changes
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

GeneralSettings.propTypes = {
  fields: PropTypes.object,
  handleChange: PropTypes.func,
};

const mapStateToProps = ({ Settings }) => ({
  settings: Settings.settings,
  loading: Settings.loading,
  error: Settings.error,
});

const mapDispatchToProps = (dispatch) => ({
  onGetSettings: () => dispatch(getSettings()),
  onPutSettings: (data) => dispatch(putSettings(data)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation()(GeneralSettings))
);
