import React from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import Signup from "../pages/Authentication/Signup";

import ProfileChangePassword from "../pages/Authentication/ChangePassword";

import Dashboard from "../pages/Dashboard";

import Customers_List from "../pages/Customers/CustomersList";
import UserSubscription from "../pages/Customers/SubscriptionAssign/SubscriptionAssign";

// import Customers_Profile from "../pages/Customers/CustomerProfile"
import ParkingOwnersList from "../pages/ParkingOwners/List";
import ParkingOwnerView from "../pages/ParkingOwners/View";

import TransactionsList from "../pages/Transactions/List";
import TransactionView from "../pages/Transactions/View";
import CustomerSupportList from "../pages/CustomerSupport/List";
import CustomerSupportView from "../pages/CustomerSupport/View";
import ViewCustomer from "../pages/Customers/ViewCustomer";

import Bookingpage from "../pages/Bookings"
import CensorList from "pages/Censor/List";
import CensorAddEdit from "pages/Censor/AddEdit";
import Dashsubscription from "pages/Dashsubscription";
import Manageprofile from "pages/Manageprofile";
import Termsconditions from "pages/Termsconditions";
import Privacy from "pages/Privacy";
import Contactus from "pages/ContactUs/List";

import GeneralSettings from "../pages/Settings/General";

import ParkingStructureList from "pages/Parking/List";
import ParkingAddEdit from "pages/Parking/AddEdit";

// Content Page
import ContentPage from "../pages/ContentPage/List";
import ContentPageAddEdit from "../pages/ContentPage/AddEdit";
import Faqs from "../pages/Faq/List";
import FaqAddEdit from "../pages/Faq/AddEdit";

//company
import CompaniesList from "pages/Company/List";

//zone
import ZonesList from "pages/Zone/List";

//city
import CityList from "pages/City/List";

//country
import CountryList from "pages/Country/List";

import SubscribedCompanies from "pages/Dashsubscription/SubscribedCompanies";
import BookingDetails from "pages/Bookings/View";
import PermitCardsList from "pages/PermitCards/List";
import AppResetPassword from "pages/Authentication/AppResetPassword";

const userRoutes = () => [
  // Logout
  { path: "/logout", exact: true, component: Logout },
  {
    path: "/change-password",
    exact: true,
    component: ProfileChangePassword,
  },
  { path: "/dashboard", component: Dashboard },


  { path: "/users", component: Customers_List },
  { path: "/users/profile/subscription-assign", component: UserSubscription },
  { path: "/users/profile/:id", component: ViewCustomer },

  { path: "/bookings", component: Bookingpage },
  { path: "/sensors", component: CensorList },
  { path: "/sensors/add", component: CensorAddEdit },
  { path: "/sensors/:id/edit", component: CensorAddEdit },
  { path: "/subscription", component: Dashsubscription },
  { path: "/manage-profile", component: Manageprofile },
  { path: "/terms-conditions", component: Termsconditions },
  { path: "/privacy", component: Privacy },
  { path: "/contact-us", component: Contactus },


  { path: "/content-pages", component: ContentPage },
  { path: "/content-pages/add", component: ContentPageAddEdit },
  { path: "/content-pages/:id/edit", component: ContentPageAddEdit },


  { path: "/transactions", component: TransactionsList },
  { path: "/transaction/:id/view", component: TransactionView },
  { path: "/customer-support", component: CustomerSupportList },
  { path: "/customer-support/:id/view", component: CustomerSupportView },

  { path: "/general", component: GeneralSettings },

  { path: "/faqs", component: Faqs },
  { path: "/faqs/add", component: FaqAddEdit },
  { path: "/faqs/:id/edit", component: FaqAddEdit },

  { path: "/parking-owners", component: ParkingOwnersList },
  { path: "/parking-owner/:id", component: ParkingOwnerView },


  { path: "/parking-structure", component: ParkingStructureList },
  { path: "/parking-structure/add", component: ParkingAddEdit },
  { path: "/parking-structure/:id/edit", component: ParkingAddEdit },

  { path: "/companies", component: CompaniesList },
  { path: "/zones", component: ZonesList },
  { path: "/cities", component: CityList },
  { path: "/countries", component: CountryList },
  { path: "/subscription/:planId/companies", component: SubscribedCompanies },
  { path: "/booking/:id/view", component: BookingDetails },
  { path: "/permitCards", component: PermitCardsList },
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
];

const authRoutes = [
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/signup", component: Signup },

  { path: "/app-forgot/:id", component: AppResetPassword },

  { path: "/", exact: true, component: () => <Redirect to="/login" /> },
];

export { userRoutes, authRoutes };
