import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { Button, Form, FormGroup, Label, Input, FormText } from "reactstrap";


import Welcomegreet from "components/Welcomegreet";



//i18n
import { withTranslation } from "react-i18next";

import {  Col, Container, Row, Spinner } from "reactstrap";

//Import Breadcrumb




import SearchInput from "components/Common/SearchInput";

import { getCompaniesRequest, removeCompanyRequest } from "store/actions";
import UsersColumns from "./Columns";
import UsersConfirmModal from "./ConfirmModal";
import AddCompanyModal from "components/Modals/AddCompany";


const CompanyList = (props) => {
  const {
    history,
    companies,
    totalCompanies,
    getCompaniesRequest,
    removeCompanyRequest,
    loading,
  } = props;
  const [confirmModal, setConfirmModal] = useState({
    isOpen: false,
    userId: null,
  });
  const [showAddModal,setShowAddModal] = useState(false); 

  const [filter, setFilter] = useState({
    orderBy: "createdAt",
    order: -1,
    page: 1,
    limit: 10,
  });
  const [searchText, setSearchText] = useState("");
  const [rowData,setRowData] = useState(null);
  const pageOptions = {
    sizePerPage: 10,
    totalSize: totalCompanies,
    custom: true,
    onPageChange: (page) => setFilter((prevState) => ({ ...prevState, page })),
  };

  useEffect(() => {
    getCompaniesRequest({ ...filter });
  }, [JSON.stringify(filter)]);


  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { searchText, sortField, sortOrder }) => {
    if (type === "search") {
      setSearchText(searchText);
    }

    if (type === "sort") {
      if (sortField == "createdAt") {
        sortField = "date_created_utc";
      }

      if (sortOrder == "desc") {
        sortOrder = -1;
      } else if (sortOrder == "asc") {
        sortOrder = 1;
      }

      setFilter((prevState) => ({
        ...prevState,
        orderBy: sortField,
        order: sortOrder,
      }));
    }
  };

  const toggleConfirmModal = (userId) => {
    setConfirmModal((prevState) => ({ isOpen: !prevState.isOpen, userId }));
  };

  const removeConfirm = () => {
    const { userId, isOpen } = confirmModal;

    const onComplete = () => {
      getCompaniesRequest(filter);
    };

    setConfirmModal({ isOpen: !isOpen, userId: null });
    removeCompanyRequest({ _id: userId }, onComplete);
  };

  const onSearch = (event) => {
    event.preventDefault();

    setFilter((prevState) => ({ ...prevState, search: searchText }));
  };

  const onEditHandler = (data) =>{
      setRowData(data)
       setShowAddModal(true);
  }

  const onFinishAddEdit = () =>{
      setRowData(null);
      getCompaniesRequest(filter);
  }

  return (
    <React.Fragment>
      <UsersConfirmModal
        isOpen={confirmModal.isOpen}
        toggle={toggleConfirmModal}
        onConfirm={removeConfirm}
      />
     {showAddModal &&
      <AddCompanyModal
          data={rowData}
          show={showAddModal}
          onFinish={onFinishAddEdit}
          onhide={()=>setShowAddModal(false)}
      />
     }
      <div className="page-content">
        <Container fluid>
          <Welcomegreet />
          <div className="fillter_ssection mt-5">
            <Form>
              <div className="latest_cust_table mt-4">
                {/* <Newlist/> */}
                 
                <Row>
                  <Col xs="12"> 
                        <PaginationProvider
                          pagination={paginationFactory(pageOptions)}
                        >
                          {({ paginationProps, paginationTableProps }) => (
                            <ToolkitProvider
                              keyField="_id"
                              data={companies}
                              columns={UsersColumns(toggleConfirmModal,onEditHandler,props.t)}
                              bootstrap4
                              search
                            >
                              {(toolkitProps) => (
                                <React.Fragment>
                                  <Row>
                      <Col lg={6} md={12} sm={12}>
                             <div className="custom-flex-sm d-flex align-items-center">          
                              <div className="search d-flex align-items-center">
                                <div className="pl-3">
                                  <SearchInput
                                    {...toolkitProps.searchProps}
                                    triggerSearch={onSearch}
                                    placeholder={props.t("search")}
                                    searchText={searchText}
                                  />
                                </div>

                              </div>
                            </div>
                      </Col>
                      <Col
                            className="mb-3"
                            xs={12}
                            md={6}
                            lg={6}
                          >
                            <div className="text-sm-left text-md-right">
                            
                                <Button
                                  type="button"
                                  color="success"
                                  className="btn-rounded waves-effect waves-light mb-2 mr-2"
                                  onClick={()=>setShowAddModal(true)}
                                >
                                  <i className="mdi mdi-plus mr-1" />
                                  {props.t("add")} {props.t("company")}
                                </Button>
                            </div>
                          </Col>
                    </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive spinner-content mt-3">
                                  <BootstrapTable
                                    responsive
                                    remote
                                    bordered={false}
                                    striped={false}
                                    classes={
                                      "table table-centered table-nowrap"
                                    }
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    onTableChange={handleTableChange}
                                    {...paginationTableProps}
                                    defaultSorted={[
                                      {
                                        dataField: "createdAt",
                                        order: "desc",
                                      },
                                    ]}
                                    sort={{
                                      sortCaret: (order) =>
                                        order === "asc" ? (
                                          <span className="caret">
                                            <i className="bx bx-caret-up" />
                                          </span>
                                        ) : order === "desc" ? (
                                          <span className="caret">
                                            <i className="bx bx-caret-down" />
                                          </span>
                                        ) : null,
                                    }}
                                  />

                                  {loading && (
                                    <div className="spinner">
                                      <Spinner color="primary" />
                                    </div>
                                  )}
                                </div>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
            </Col>
          </Row>
              </div>
            </Form>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};



const mapStateToProps = ({ Dashboard }) => ({
  loading: Dashboard.loading,
  companies: Dashboard.companies,
  totalCompanies: Dashboard.totalCompanies,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getCompaniesRequest: (data,callback) => {
      dispatch(getCompaniesRequest(data,callback));
    },
    removeCompanyRequest: (data,callback) => {
      dispatch(removeCompanyRequest(data,callback))
    }
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CompanyList))
);
