import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import moment from "moment-timezone";
import { Alert, Spinner } from "reactstrap";

// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

import loginbaner from "assets/images/loginbaner.png";

// actions
import {
  registerUserEmail,
  registerUser,
  apiError,
  getStoreTypes,
  registerUserFailed,
} from "store/actions";

import SignupForm from "./SignupForm";
import MarketType from "./MarketType";
import CreateMarket from "./CreateMarket";

import { LANGUAGES } from "helpers/contants";

// import images
import logo from "assets/images/logo.png";

const Signup = (props) => {
  const [activeTab, setactiveTab] = useState(1);
  const [fields, setfields] = useState({
    firstName: "",
    lastName: "",
    name: "",
    email: "",
    mobileNumber: "",
    password: "",
    storeType: {},
    storeName: "",
    language: { code: LANGUAGES[0].value, name: LANGUAGES[0].label },
    currency: { sign: "", code: "" },
    timezone: moment.tz.guess(),
    country: "",
    policy: false,
  });

  useEffect(() => {
    props.registerUserFailed();
    props.getStoreTypes();
  }, []);

  const handleChange = (name) => (e) => {
    const { value } = e.target;

    setfields((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      ...fields,
      storeTypes: Object.entries(fields?.storeType)?.map(([key, value]) => ({
        storeType: key,
        storeVendorType: value,
      })),
      name: (fields?.firstName + " " + fields?.lastName)?.trim(),
    };

    const { storeType, ..._data } = data;

    props.registerUser(_data, props.history);
  };

  return (
    <>
      {/* <section id="sign-up">
      <div className="container">
        <div className="registerwrap">
          <div className="row">
            <div className="col-lg-12">
              <div className="sign-up-top">
                <Link to="/">
                  <img
                    src={logo}
                    className="img-fluid registerlogo logo-data"
                  />
                </Link>
                <Link to="/login" className="colortextlink">
                  Already have an account?
                </Link>
              </div>
            </div>
          </div>

          <form
            id="regForm"
            onSubmit={handleSubmit}
            className="spinner-content position-relative needs-validation"
          >
            {props.error && <Alert color="danger">{props.error}</Alert>}

            {props.loading && (
              <div className="spinner">
                <Spinner color="primary" />
              </div>
            )}

            {activeTab === 1 && (
              <SignupForm
                registerUserEmail={props?.registerUserEmail}
                apiError={props.registerUserFailed}
                setactiveTab={setactiveTab}
                fields={fields}
                handleChange={handleChange}
              />
            )}

            {activeTab === 2 && (
              <MarketType
                apiError={props.registerUserFailed}
                setactiveTab={setactiveTab}
                fields={fields}
                handleChange={handleChange}
              />
            )}

            {activeTab === 3 && (
              <CreateMarket
                setactiveTab={setactiveTab}
                fields={fields}
                handleChange={handleChange}
              />
            )}
          </form>

        
        </div>
      </div>
    </section> */}

      <section id="login">
        <div className="container">
          <div className="wraaping_login">
            <div className="form_Side">
              <div className="login_right_banner">
                <img src={loginbaner} alt="" className="img_fluid" />
              </div>

              <form
                id="regForm"
                onSubmit={handleSubmit}
                className="spinner-content position-relative needs-validation"
              >
                {props.error && <Alert color="danger">{props.error}</Alert>}

                {props.loading && (
                  <div className="spinner">
                    <Spinner color="primary" />
                  </div>
                )}

                {activeTab === 1 && (
                  <SignupForm
                    registerUserEmail={props?.registerUserEmail}
                    apiError={props.registerUserFailed}
                    setactiveTab={setactiveTab}
                    fields={fields}
                    handleChange={handleChange}
                  />
                )}

                {activeTab === 2 && (
                  <MarketType
                    apiError={props.registerUserFailed}
                    setactiveTab={setactiveTab}
                    fields={fields}
                    handleChange={handleChange}
                  />
                )}

                {activeTab === 3 && (
                  <CreateMarket
                    setactiveTab={setactiveTab}
                    fields={fields}
                    handleChange={handleChange}
                  />
                )}
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = (state) => {
  const { registrationError, loading } = state.Register;
  return { error: registrationError, loading };
};

export default withRouter(
  connect(mapStateToProps, {
    registerUser,
    apiError,
    getStoreTypes,
    registerUserEmail,
    registerUserFailed,
  })(Signup)
);

Signup.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  registerUser: PropTypes.func,
  loading: PropTypes.bool,
};
