import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Alert, Spinner, Row, Col, Button } from "reactstrap";

// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

import logodark from "../../assets/images/logo.png";
import loginbaner from "../../assets/images/loginbaner.png";

// actions
import { appResetPasswordRequest, apiError } from "store/actions";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { checkPassword } from "helpers/validations";
import OpenEye from "components/icons/OpenEye";

// import images

// import logo from "assets/images/logo.png";

const AppResetPassword = (props) => {
  const { loading } = props;
  let { id } = useParams();

  const [fields, setfields] = useState({
    token: id,
    password: "",
    confirmPassword: "",
  });

  const [formErrors, SetFormErrors] = useState({
    password: "",
    confirmPassword: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  // useEffect(() => {
  //     props.apiError();
  // }, []);

  const handleChange = (name) => (e) => {
    const { value } = e.target;

    setfields((prevState) => ({ ...prevState, [name]: value }));
  };

  const formValidation = () => {
    if (!fields.password) {
      SetFormErrors((p) => ({
        ...p,
        password: "password is required",
      }));
      return false;
    } else {
      SetFormErrors((p) => ({
        ...p,
        password: "",
      }));
    }

    if (!checkPassword(fields.password)) {
      SetFormErrors((p) => ({
        ...p,
        password:
          "Password must contain atleast a capital letter, a lowercase letter, a number and a special character.",
      }));
      return false;
    } else {
      SetFormErrors((p) => ({
        ...p,
        password: "",
      }));
    }

    if (!checkPassword(fields.confirmPassword)) {
      SetFormErrors((p) => ({
        ...p,
        confirmPassword:
          "Password must contain atleast a capital letter, a lowercase letter, a number and a special character.",
      }));
      return false;
    } else {
      SetFormErrors((p) => ({
        ...p,
        confirmPassword: "",
      }));
    }

    if (!fields.confirmPassword) {
      SetFormErrors((p) => ({
        ...p,
        confirmPassword: "Confirm Password is required",
      }));
      return false;
    } else {
      SetFormErrors((p) => ({
        ...p,
        confirmPassword: "",
      }));
    }
    if (fields.password !== fields.confirmPassword) {
      SetFormErrors((p) => ({
        ...p,
        confirmPassword: "Confirm password not matched with password",
      }));
      return false;
    } else {
      SetFormErrors((p) => ({
        ...p,
        confirmPassword: "",
      }));
    }

    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!formValidation()) return;

    console.log(fields, "submit values");

    props.appResetPasswordRequest(fields);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
 
  return (
    <section id="login">
      <div className="container">
        <div className="wraaping_login">
          {/* <div className="form_Side"> */}

          <form
            id="loginform"
            onSubmit={(e) => handleSubmit(e)}
            className="spinner-content position-relative"
          >
            {loading && (
              <div className="spinner">
                <Spinner color="primary" />
              </div>
            )}

            {props.error && typeof props.error === "string" ? (
              <Alert color="danger">{props.error}</Alert>
            ) : null}

            <div className="sign-in-top">
              <img src={logodark} alt="" className="registerlogo" />
            </div>
            <div className="row">
  <div className="col-lg-12">
    <div className="input-group position-relative" style={{ position: "relative" }}>
      <label>Password</label>
      <input
        type={showPassword ? "text" : "password"}
        placeholder="Enter your Password"
        value={fields?.password}
        onChange={handleChange("password")}
        style={{ paddingRight: "55px" }} // Add padding to the right to avoid overlapping with the icon
      />
      <span
        className="password-toggle-icon"
        onClick={togglePasswordVisibility}
        style={{
          cursor: "pointer",
          position: "absolute",
          right: 20,
          top: "55%",
          transform: "translateY(-50%)",
          zIndex: 99999, // Ensure the icon appears above other elements
        }}
      >
        {showPassword ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 36 36"
          >
            <path
              fill="#D0D2D1"
              d="M33.62 17.53c-3.37-6.23-9.28-10-15.82-10S5.34 11.3 2 17.53l-.28.47l.26.48c3.37 6.23 9.28 10 15.82 10s12.46-3.72 15.82-10l.26-.48Zm-15.82 8.9C12.17 26.43 7 23.29 4 18c3-5.29 8.17-8.43 13.8-8.43S28.54 12.72 31.59 18c-3.05 5.29-8.17 8.43-13.79 8.43"
            ></path>
            <path
              fill="#D0D2D1"
              d="M18.09 11.17A6.86 6.86 0 1 0 25 18a6.86 6.86 0 0 0-6.91-6.83m0 11.72A4.86 4.86 0 1 1 23 18a4.87 4.87 0 0 1-4.91 4.89"
            ></path>
            <path fill="none" d="M0 0h36v36H0z"></path>
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 36 36"
          >
            <path
              fill="#D0D2D1"
              d="M18.37 11.17a6.79 6.79 0 0 0-2.37.43l8.8 8.8a6.78 6.78 0 0 0 .43-2.4a6.86 6.86 0 0 0-6.86-6.83"
            ></path>
            <path
              fill="#D0D2D1"
              d="M34.29 17.53c-3.37-6.23-9.28-10-15.82-10a16.82 16.82 0 0 0-5.24.85L14.84 10a14.78 14.78 0 0 1 3.63-.47c5.63 0 10.75 3.14 13.8 8.43a17.75 17.75 0 0 1-4.37 5.1l1.42 1.42a19.93 19.93 0 0 0 5-6l.26-.48Z"
            ></path>
            <path
              fill="#D0D2D1"
              d="m4.87 5.78l4.46 4.46a19.52 19.52 0 0 0-6.69 7.29l-.26.47l.26.48c3.37 6.23 9.28 10 15.82 10a16.93 16.93 0 0 0 7.37-1.69l5 5l1.75-1.5l-26-26Zm8.3 8.3a6.85 6.85 0 0 0 9.55 9.55l1.6 1.6a14.91 14.91 0 0 1-5.86 1.2c-5.63 0-10.75-3.14-13.8-8.43a17.29 17.29 0 0 1 6.12-6.3Z"
            ></path>
            <path fill="none" d="M0 0h36v36H0z"></path>
          </svg>
        )}
      </span>
    </div>
    {formErrors.password && (
      <p className="text-danger mb-0">{formErrors.password}</p>
    )}
  </div>

  <div className="col-lg-12">
    <div className="input-group position-relative" style={{ position: "relative" }}>
      <label>Confirm Password</label>
      <input
        type={showConfirmPassword ? "text" : "password"}
        placeholder="Enter your confirm password"
        value={fields?.confirmPassword}
        onChange={handleChange("confirmPassword")}
        style={{ paddingRight: "55px" }} // Add padding to the right for the icon
      />
      <span
        className="password-toggle-icon"
        onClick={toggleConfirmPasswordVisibility}
        style={{
          cursor: "pointer",
          position: "absolute",
          right: 20,
          top: "55%",
          transform: "translateY(-50%)",
          zIndex: 2, // Ensure icon is above other elements
        }}
      >
        {showConfirmPassword ? (
          <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 36 36"
        >
          <path
            fill="#D0D2D1"
            d="M33.62 17.53c-3.37-6.23-9.28-10-15.82-10S5.34 11.3 2 17.53l-.28.47l.26.48c3.37 6.23 9.28 10 15.82 10s12.46-3.72 15.82-10l.26-.48Zm-15.82 8.9C12.17 26.43 7 23.29 4 18c3-5.29 8.17-8.43 13.8-8.43S28.54 12.72 31.59 18c-3.05 5.29-8.17 8.43-13.79 8.43"
          ></path>
          <path
            fill="#D0D2D1"
            d="M18.09 11.17A6.86 6.86 0 1 0 25 18a6.86 6.86 0 0 0-6.91-6.83m0 11.72A4.86 4.86 0 1 1 23 18a4.87 4.87 0 0 1-4.91 4.89"
          ></path>
          <path fill="none" d="M0 0h36v36H0z"></path>
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 36 36"
        >
          <path
            fill="#D0D2D1"
            d="M18.37 11.17a6.79 6.79 0 0 0-2.37.43l8.8 8.8a6.78 6.78 0 0 0 .43-2.4a6.86 6.86 0 0 0-6.86-6.83"
          ></path>
          <path
            fill="#D0D2D1"
            d="M34.29 17.53c-3.37-6.23-9.28-10-15.82-10a16.82 16.82 0 0 0-5.24.85L14.84 10a14.78 14.78 0 0 1 3.63-.47c5.63 0 10.75 3.14 13.8 8.43a17.75 17.75 0 0 1-4.37 5.1l1.42 1.42a19.93 19.93 0 0 0 5-6l.26-.48Z"
          ></path>
          <path
            fill="#D0D2D1"
            d="m4.87 5.78l4.46 4.46a19.52 19.52 0 0 0-6.69 7.29l-.26.47l.26.48c3.37 6.23 9.28 10 15.82 10a16.93 16.93 0 0 0 7.37-1.69l5 5l1.75-1.5l-26-26Zm8.3 8.3a6.85 6.85 0 0 0 9.55 9.55l1.6 1.6a14.91 14.91 0 0 1-5.86 1.2c-5.63 0-10.75-3.14-13.8-8.43a17.29 17.29 0 0 1 6.12-6.3Z"
          ></path>
          <path fill="none" d="M0 0h36v36H0z"></path>
        </svg>
      )}
      </span>
    </div>
    {formErrors.confirmPassword && (
      <p className="text-danger mb-0">
        {formErrors.confirmPassword}
      </p>
    )}
  </div>

  <div className="col-lg-12">
    <button
      type="submit"
      className="themebtn new_ho fullwidthbtn"
      id="nextBtn"
    >
      Reset Password
    </button>
  </div>
</div>
          </form>
          {/* </div> */}
        </div>
        {/* 
        <div className="col-lg-12">
          <div className="reglink">
            <Link to="/signup" className="colortextlink">
              Don't have an account?
            </Link>
          </div>
        </div> */}
      </div>
    </section>
  );
};

const mapStateToProps = (state) => {
  const { error, loading } = state.Login;
  return { error, loading };
};

export default withRouter(
  connect(mapStateToProps, { appResetPasswordRequest, apiError })(
    AppResetPassword
  )
);

AppResetPassword.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  appResetPasswordRequest: PropTypes.func,
};
