import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Button,
  Col,
  Container,
  FormGroup,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import OwnerSelector from "components/OwnerSelector";
import { useSelector, useDispatch } from "react-redux";
import {
  getSubscriptionsPlanRequest,
  assignSubscriptionToUserRequest,
  getCustomer,
} from "store/actions";
import ConfirmSubscriptionModel from "./ConfirmSubscriptionModel";

import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import toastr from "toastr";


const SubscriptionAssign = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  console.log(props, "------");

  const { assignSubscriptionToUserRequest } = props;

  const { customer } = props.location.state;
  const [checkOwner, setCheckOwner] = useState(null);
  const { loading, subscriptionPlans } = useSelector(
    ({ Subscriptions }) => Subscriptions
  );
  const [confirmModal, setConfirmModal] = useState({
    isOpen: false,
    userId: null,
  });
  const [selectedCollection, setSelectedCollection] = useState(null);

  const onChangeCollectionFilter = (data) => {
    setSelectedCollection(data);
    setCheckOwner(data.value);
    dispatch(
      getSubscriptionsPlanRequest({ page: 1, limit: 100, ownerId: data.value })
    );
  };

  const toggleConfirmModal = (userId, planId) => {
    setConfirmModal((prevState) => ({
      isOpen: !prevState.isOpen,
      userId,
      planId,
    }));
  };

  const onConfirm = () => {
    const { isOpen, userId, planId } = confirmModal;
    assignSubscriptionToUserRequest({
      plan_id: planId,
      userId: userId,
    });

    dispatch(getCustomer(userId));
    setConfirmModal({ isOpen: !isOpen, userId: null });
    toastr.success("Subscribed!")
    history.goBack();
  };

  const checkPlanInArray = (plans, planId) => {
    // Loop through the plans array
    if (plans?.length > 0) {
      for (let i = 0; i < plans?.length; i++) {
        const plan = plans[i];
        // Check if the plan ID and expiry date match
        if (plan?.product_id === planId) {
          return true; // Match found, return true
        }
      }
    }
    return false;
  };

  return (
    <React.Fragment>
      <ConfirmSubscriptionModel
        isOpen={confirmModal.isOpen}
        toggle={toggleConfirmModal}
        onConfirm={onConfirm}
      />
      <div className="page-content">
        <section className="viewprofile_main">
          <Container>
            <a
              href="javascript:void(0)"
              className="backto_censor"
              onClick={() => history.goBack()}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M17.77 3.77L16 2L6 12L16 22L17.77 20.23L9.54 12L17.77 3.77Z"
                  fill="#2E3A59"
                ></path>
              </svg>
              {props.t("profile")}
            </a>

            <div className="profile_content">
              <Row>
                <Col lg={4} md={5} sm={12}>
                  <FormGroup>
                    <Label for="exampleEmail">
                      {props.t("Parking Owners")}
                    </Label>
                    <OwnerSelector
                      placeholder="select owner"
                      isMulti={false}
                      defaultSelected={selectedCollection}
                      onChangeHandler={onChangeCollectionFilter}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                {checkOwner &&
                  subscriptionPlans?.map((plan) => {
                    return (
                      <Col key={plan?._id} lg={4} md={6} sm={12}>
                        <div className="card_coommnsub monthly_Card my-3">
                          <div className="d-flex justify-content-end cursor-pointer"></div>
                          <h4>
                            <b>{plan?.name}</b>
                          </h4>
                          <h5>{plan?.duration + " " + plan?.type}</h5>
                          <h1>${plan?.price}</h1>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: plan?.description,
                            }}
                          ></p>
                          {checkPlanInArray(
                            customer.subscription,
                            plan?.product_id
                          ) ? (
                            <Button style={{ cursor: "not-allowed" }}>
                              Subscribed
                            </Button>
                          ) : (
                            <Button
                              className="btn-danger"
                              onClick={() =>
                                toggleConfirmModal(customer?._id, plan?._id)
                              }
                            >
                              Give Subscription
                            </Button>
                          )}
                        </div>
                      </Col>
                    );
                  })}

                <Col>
                  {!loading && checkOwner && subscriptionPlans.length <= 0 && (
                    <div style={{ "textAlign": "center" }}>No Record found.</div>
                  )}
                </Col>
              </Row>
              {loading && (
                <div className="spinner" style={{ textAlign: "center" }}>
                  <Spinner color="primary" />
                </div>
              )}
            </div>
          </Container>
        </section>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = ({ customers }) => ({
  error: customers.error,
  loading: customers.loading,
});

const mapDispatchToProps = (dispatch) => {
  return {
    assignSubscriptionToUserRequest: (data) => {
      dispatch(assignSubscriptionToUserRequest(data));
    },
  };
};


export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation()(SubscriptionAssign))
);
