import React from "react";
import { Link } from "react-router-dom";
import { Badge, Input, Label, UncontrolledTooltip } from "reactstrap";
import moment from "moment-timezone";

const Columns = (t) => [
  {
    dataField: "userId",
    text: t("name"),
    sort: true,
    formatter: (_, row) => row?.name,
  },
  {
    dataField: "email",
    text: t("email"),
    sort: true,
    formatter: (_, row) => row?.email,
  },
  {
    dataField: "plan_name",
    text: t("plan"),
    sort: true,
    formatter: (_, row) => (row?.subscription?.name),
  },
  {
    dataField: "plan_duration",
    text: t("plan") + " " + t("duration"),
    sort: true,
    formatter: (_, row) => {
        return row?.subscription?.duration +" " + row?.subscription?.type;
    }
  },
  {
    text: t("start_date"),
    dataField: "current_period_start",
    sort: true,
    formatter: (_, row) => moment.unix(row?.subscription?.current_period_start).format("DD MMM YYYY LT"),
  },
  {
    dataField: "current_period_end",
    text: t("end_date"),
    sort: true,
    formatter: (_, row) => moment.unix(row?.subscription?.current_period_end).format("DD MMM YYYY LT"),
  },
  {
    dataField: "amount",
    text: t("amount"),
    sort: true,
    formatter: (_, row) => "€" + row?.subscription?.price,
  }
];

export default Columns;
