
export const GET_SENSORS_REQUEST = "GET_SENSORS_REQUEST";
export const GET_SENSORS_SUCCESS = "GET_SENSORS_SUCCESS";
export const GET_SENSORS_FAIL = "GET_SENSORS_FAIL";

export const ADD_SENSOR_REQUEST = "ADD_SENSOR_REQUEST";
export const ADD_SENSOR_SUCCESS = "ADD_SENSOR_SUCCESS";
export const ADD_SENSOR_FAIL = "ADD_SENSOR_FAIL";

export const GET_SENSOR_REQUEST = "GET_SENSOR_REQUEST";
export const GET_SENSOR_SUCCESS = "GET_SENSOR_SUCCESS";
export const GET_SENSOR_FAIL = "GET_SENSOR_FAIL";

export const REMOVE_SENSOR_REQUEST = "REMOVE_SENSOR_REQUEST";
export const REMOVE_SENSOR_SUCCESS = "REMOVE_SENSOR_SUCCESS";
export const REMOVE_SENSOR_FAIL = "REMOVE_SENSOR_FAIL";

export const UPDATE_SENSOR_REQUEST = "UPDATE_SENSOR_REQUEST";
export const UPDATE_SENSOR_SUCCESS = "UPDATE_SENSOR_SUCCESS";
export const UPDATE_SENSOR_FAIL = "UPDATE_SENSOR_FAIL";

