export const ITEM_API_FAILED = "ITEM_API_FAILED";

// ITEMs
export const GET_ITEMS = "GET_ITEMS";
export const GET_ITEMS_FAIL = "GET_ITEMS_FAIL";
export const GET_ITEMS_SUCCESS = "GET_ITEMS_SUCCESS";

// Add ITEM
export const ADD_ITEM = "ADD_ITEM";
export const ADD_ITEM_FAIL = "ADD_ITEM_FAIL";
export const ADD_ITEM_SUCCESS = "ADD_ITEM_SUCCESS";

// Update ITEM
export const UPDATE_ITEM = "UPDATE_ITEM";
export const UPDATE_ITEM_FAIL = "UPDATE_ITEM_FAIL";
export const UPDATE_ITEM_SUCCESS = "UPDATE_ITEM_SUCCESS";

// Get ITEM
export const GET_ITEM = "GET_ITEM";
export const GET_ITEM_FAIL = "GET_ITEM_FAIL";
export const GET_ITEM_SUCCESS = "GET_ITEM_SUCCESS";

// update ITEM
export const DEPLOY_ITEM = "DEPLOY_ITEM";
export const DEPLOY_ITEM_FAIL = "DEPLOY_ITEM_FAIL";
export const DEPLOY_ITEM_SUCCESS = "DEPLOY_ITEM_SUCCESS";

// Delete ITEM
export const DELETE_ITEM = "DELETE_ITEM";
export const DELETE_ITEM_FAIL = "DELETE_ITEM_FAIL";
export const DELETE_ITEM_SUCCESS = "DELETE_ITEM_SUCCESS";

// Update Multi ITEM Status
export const DEPLOY_ITEMS_STATUS = "DEPLOY_ITEMS_STATUS";
export const DEPLOY_ITEMS_STATUS_FAIL = "DEPLOY_ITEMS_STATUS_FAIL";
export const DEPLOY_ITEMS_STATUS_SUCCESS = "DEPLOY_ITEMS_STATUS_SUCCESS";

// Get Notifications
export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";
export const GET_NOTIFICATIONS_FAIL = "GET_NOTIFICATIONS_FAIL";
export const GET_NOTIFICATIONS_SUCCESS = "GET_NOTIFICATIONS_SUCCESS";
export const BLUE_TICK_NOTIFICATIONS = "BLUE_TICK_NOTIFICATIONS";

// Backround image upload
export const ADD_BACKGROUND_IMAGE = "ADD_BACKGROUND_IMAGE";
export const ADD_BACKGROUND_IMAGE_FAIL = "ADD_BACKGROUND_IMAGE_FAIL";
export const ADD_BACKGROUND_IMAGE_SUCCESS = "ADD_BACKGROUND_IMAGE_SUCCESS";

export const GET_LIST_BACKGROUND_IMAGE = "GET_LIST_BACKGROUND_IMAGE";
export const GET_LIST_BACKGROUND_IMAGE_FAIL = "GET_LIST_BACKGROUND_IMAGE_FAIL";
export const GET_LIST_BACKGROUND_IMAGE_SUCCESS =
  "GET_LIST_BACKGROUND_IMAGE_SUCCESS";

export const PUT_BACKGROUND_IMAGE = "PUT_BACKGROUND_IMAGE";
export const PUT_BACKGROUND_IMAGE_FAIL = "PUT_BACKGROUND_IMAGE_FAIL";
export const PUT_BACKGROUND_IMAGE_SUCCESS = "PUT_BACKGROUND_IMAGE_SUCCESS";

export const VIEW_BACKGROUND_IMAGE = "VIEW_BACKGROUND_IMAGE";
export const VIEW_BACKGROUND_IMAGE_FAIL = "VIEW_BACKGROUND_IMAGE_FAIL";
export const VIEW_BACKGROUND_IMAGE_SUCCESS = "VIEW_BACKGROUND_IMAGE_SUCCESS";

export const DELETE_BACKGROUND_IMAGE = "DELETE_BACKGROUND_IMAGE";
export const DELETE_BACKGROUND_IMAGE_FAIL = "DELETE_BACKGROUND_IMAGE_FAIL";
export const DELETE_BACKGROUND_IMAGE_SUCCESS =
  "DELETE_BACKGROUND_IMAGE_SUCCESS";

export const RESET_VIEW_ITEM = "RESET_VIEW_ITEM";
