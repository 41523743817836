import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { uniqueId } from "lodash"
import { useTranslation } from "react-i18next"

const SIZES = {
  xsm: "switch-xsm",
  sm: "switch-sm",
  md: "switch-md",
  l: "switch-l",
  default: "status-switch",
}

const ToggleSwitch = props => {
  const { i18n } = useTranslation()

  const handleChange = event => {
    let checked = props.checked
    let value = checked ? props.offValue : props.onValue
    props.onChange({
      target: {
        value: value,
        name: props.name,
      },
    })
  }

  return (
    <div className={"status-switch square-switch"}>
      <input
        className={classNames("switch", props.inputType)}
        type="checkbox"
        switch="none"

        id={props.id}
        checked={props.checked}
        onClick={handleChange}
      />

      <label
        htmlFor={props.id}
        data-on-label={i18n.t(props.onLabel)}
        data-off-label={i18n.t(props.offLabel)}
      />
    </div>
  )
}

ToggleSwitch.propTypes = {
  id: PropTypes.string,
  size: PropTypes.oneOf(["xsm", "sm", "md", "l", "default"]),
  inputType: PropTypes.oneOf(["", "switcher"]),
  checked: PropTypes.bool,
  onLabel: PropTypes.string,
  offLabel: PropTypes.string,
  onValue: PropTypes.string,
  offValue: PropTypes.string,
}

ToggleSwitch.defaultProps = {
  id: uniqueId("switch-"),
  size: "l",
  inputType: "",
  onChange: () => {},
}

export default React.memo(ToggleSwitch)
