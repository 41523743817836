import * as CONST from "./actionTypes";


//companies
export const getCompaniesRequest = (data, callback) => ({
  type: CONST.GET_COMPANIES_REQUEST,
  payload: { data, callback },
});

export const getCompaniesSuccess = (data) => ({
  type: CONST.GET_COMPANIES_SUCCESS,
  payload : data
});

export const getCompaniesFail = (error) => ({
  type: CONST.GET_COMPANIES_FAIL,
  payload: error,
});


export const getCompanyRequest = (data, callback) => ({
  type: CONST.GET_COMPANY_REQUEST,
  payload: { data, callback },
});

export const getCompanySuccess = (data) => ({
  type: CONST.GET_COMPANY_SUCCESS,
  payload : data
});

export const getCompanyFail = (error) => ({
  type: CONST.GET_COMPANY_FAIL,
  payload: error,
});

export const addCompanyRequest = (data, callback) => ({
  type: CONST.ADD_COMPANY_REQUEST,
  payload: { data, callback },
});

export const addCompanySuccess = (data) => ({
  type: CONST.ADD_COMPANY_SUCCESS,
  payload : data
});

export const addCompanyFail = (error) => ({
  type: CONST.ADD_COMPANY_FAIL,
  payload: error,
});

export const  updateCompanyRequest = (data, callback) => ({
  type: CONST.UPDATE_COMPANY_REQUEST,
  payload: { data, callback },
});

export const updateCompanySuccess = (data) => ({
  type: CONST.UPDATE_COMPANY_SUCCESS,
  payload : data
});

export const updateCompanyFail = (error) => ({
  type: CONST.UPDATE_COMPANY_FAIL,
  payload: error,
});


export const  removeCompanyRequest = (data, callback) => ({
  type: CONST.REMOVE_COMPANY_REQUEST,
  payload: { data, callback },
});

export const removeCompanySuccess = (data) => ({
  type: CONST.REMOVE_COMPANY_SUCCESS,
  payload : data
});

export const removeCompanyFail = (error) => ({
  type: CONST.REMOVE_COMPANY_FAIL,
  payload: error,
});

//country
export const getCountriesRequest = (data, callback) => ({
  type: CONST.GET_COUNTRIES_REQUEST,
  payload: { data, callback },
});

export const getCountriesSuccess = (data) => ({
  type: CONST.GET_COUNTRIES_SUCCESS,
  payload : data
});

export const getCountriesFail = (error) => ({
  type: CONST.GET_COUNTRIES_FAIL,
  payload: error,
});


export const getCountryRequest = (data, callback) => ({
  type: CONST.GET_COUNTRY_REQUEST,
  payload: { data, callback },
});

export const getCountrySuccess = (data) => ({
  type: CONST.GET_COUNTRY_SUCCESS,
  payload : data
});

export const getCountryFail = (error) => ({
  type: CONST.GET_COUNTRY_FAIL,
  payload: error,
});

export const addCountryRequest = (data, callback) => ({
  type: CONST.ADD_COUNTRY_REQUEST,
  payload: { data, callback },
});

export const addCountrySuccess = (data) => ({
  type: CONST.ADD_COUNTRY_SUCCESS,
  payload : data
});

export const addCountryFail = (error) => ({
  type: CONST.ADD_COUNTRY_FAIL,
  payload: error,
});

export const  updateCountryRequest = (data, callback) => ({
  type: CONST.UPDATE_COUNTRY_REQUEST,
  payload: { data, callback },
});

export const updateCountrySuccess = (data) => ({
  type: CONST.UPDATE_COUNTRY_SUCCESS,
  payload : data
});

export const updateCountryFail = (error) => ({
  type: CONST.UPDATE_COUNTRY_FAIL,
  payload: error,
});


export const  removeCountryRequest = (data, callback) => ({
  type: CONST.REMOVE_COUNTRY_REQUEST,
  payload: { data, callback },
});

export const removeCountrySuccess = (data) => ({
  type: CONST.REMOVE_COUNTRY_SUCCESS,
  payload : data
});

export const removeCountryFail = (error) => ({
  type: CONST.REMOVE_COUNTRY_FAIL,
  payload: error,
});

//city
export const getCitiesRequest = (data, callback) => ({
  type: CONST.GET_CITIES_REQUEST,
  payload: { data, callback },
});

export const getCitiesSuccess = (data) => ({
  type: CONST.GET_CITIES_SUCCESS,
  payload : data
});

export const getCitiesFail = (error) => ({
  type: CONST.GET_CITIES_FAIL,
  payload: error,
});


export const getCityRequest = (data, callback) => ({
  type: CONST.GET_CITY_REQUEST,
  payload: { data, callback },
});

export const getCitySuccess = (data) => ({
  type: CONST.GET_CITY_SUCCESS,
  payload : data
});

export const getCityFail = (error) => ({
  type: CONST.GET_CITY_FAIL,
  payload: error,
});

export const addCityRequest = (data, callback) => ({
  type: CONST.ADD_CITY_REQUEST,
  payload: { data, callback },
});

export const addCitySuccess = (data) => ({
  type: CONST.ADD_CITY_SUCCESS,
  payload : data
});

export const addCityFail = (error) => ({
  type: CONST.ADD_CITY_FAIL,
  payload: error,
});

export const  updateCityRequest = (data, callback) => ({
  type: CONST.UPDATE_CITY_REQUEST,
  payload: { data, callback },
});

export const updateCitySuccess = (data) => ({
  type: CONST.UPDATE_CITY_SUCCESS,
  payload : data
});

export const updateCityFail = (error) => ({
  type: CONST.UPDATE_CITY_FAIL,
  payload: error,
});


export const  removeCityRequest = (data, callback) => ({
  type: CONST.REMOVE_CITY_REQUEST,
  payload: { data, callback },
});

export const removeCitySuccess = (data) => ({
  type: CONST.REMOVE_CITY_SUCCESS,
  payload : data
});

export const removeCityFail = (error) => ({
  type: CONST.REMOVE_CITY_FAIL,
  payload: error,
});

//zone
export const getZonesRequest = (data, callback) => ({
  type: CONST.GET_ZONES_REQUEST,
  payload: { data, callback },
});

export const getZonesSuccess = (data) => ({
  type: CONST.GET_ZONES_SUCCESS,
  payload : data
});

export const getZonesFail = (error) => ({
  type: CONST.GET_ZONES_FAIL,
  payload: error,
});


export const getZoneRequest = (data, callback) => ({
  type: CONST.GET_ZONE_REQUEST,
  payload: { data, callback },
});

export const getZoneSuccess = (data) => ({
  type: CONST.GET_ZONE_SUCCESS,
  payload : data
});

export const getZoneFail = (error) => ({
  type: CONST.GET_ZONE_FAIL,
  payload: error,
});

export const addZoneRequest = (data, callback) => ({
  type: CONST.ADD_ZONE_REQUEST,
  payload: { data, callback },
});

export const addZoneSuccess = (data) => ({
  type: CONST.ADD_ZONE_SUCCESS,
  payload : data
});

export const addZoneFail = (error) => ({
  type: CONST.ADD_ZONE_FAIL,
  payload: error,
});

export const  updateZoneRequest = (data, callback) => ({
  type: CONST.UPDATE_ZONE_REQUEST,
  payload: { data, callback },
});

export const updateZoneSuccess = (data) => ({
  type: CONST.UPDATE_ZONE_SUCCESS,
  payload : data
});

export const updateZoneFail = (error) => ({
  type: CONST.UPDATE_ZONE_FAIL,
  payload: error,
});


export const  removeZoneRequest = (data, callback) => ({
  type: CONST.REMOVE_ZONE_REQUEST,
  payload: { data, callback },
});

export const removeZoneSuccess = (data) => ({
  type: CONST.REMOVE_ZONE_SUCCESS,
  payload : data
});

export const removeZoneFail = (error) => ({
  type: CONST.REMOVE_ZONE_FAIL,
  payload: error,
});



export const  getGraphDataRequest = (data, callback) => ({
  type: CONST.GET_GRAPH_DATA_REQUEST,
  payload: { data, callback },
});

export const getGraphDataSuccess = (data) => ({
  type: CONST.GET_GRAPH_DATA_SUCCESS,
  payload : data
});

export const getGraphDataFail = (error) => ({
  type: CONST.GET_GRAPH_DATA_FAIL,
  payload: error,
});


export const  getStatsDataRequest = (data, callback) => ({
  type: CONST.GET_STATS_DATA_REQUEST,
  payload: { data, callback },
});

export const getStatsDataSuccess = (data) => ({
  type: CONST.GET_STATS_DATA_SUCCESS,
  payload : data
});

export const getStatsDataFail = (error) => ({
  type: CONST.GET_STATS_DATA_FAIL,
  payload: error,
});

export const  getTransactionRequest = (data, callback) => ({
  type: CONST.GET_TRANSACTION_REQUEST,
  payload: { data, callback },
});

export const getTransactionSuccess = (data) => ({
  type: CONST.GET_TRANSACTION_SUCCESS,
  payload : data
});

export const getTransactionFail = (error) => ({
  type: CONST.GET_TRANSACTION_FAIL,
  payload: error,
});


export const  importSensorRequest = (data, callback) => ({
  type: CONST.IMPORT_SENSOR_REQUEST,
  payload: { data, callback },
});

export const importSensorSuccess = (data) => ({
  type: CONST.IMPORT_SENSOR_SUCCESS,
  payload : data
});

export const importSensorFail = (error) => ({
  type: CONST.IMPORT_SENSOR_FAIL,
  payload: error,
});