import React from "react";
import { Link } from "react-router-dom";
import { Input, Label, UncontrolledTooltip } from "reactstrap";

export const selectRow = (props) => ({
  mode: "checkbox",
  clickToSelect: true,
  selectionHeaderRenderer: ({ indeterminate, mode, ...rest }) => (
    <div className="custom-control custom-checkbox">
      <Input
        type="checkbox"
        className="custom-control-input"
        ref={(input) => {
          if (input) input.indeterminate = indeterminate;
        }}
        {...rest}
      />
      <Label className="custom-control-label">&nbsp;</Label>
    </div>
  ),
  selectionRenderer: ({ mode, rowKey, ...rest }) => (
    <div className="custom-control custom-checkbox" key={rowKey}>
      <input type="checkbox" className="custom-control-input" {...rest} />
      <Label className="custom-control-label">&nbsp;</Label>
    </div>
  ),
  ...props,
});

const ListColumns = (toggleDetailsModal, history, t) => [
  {
    text: t("order_id"),
    dataField: "_id",
    sort: true,
  },
  {
    text: t("email"),
    dataField: "userId",
    formatter: (_, row) => row?.userId?.email,
  },
  {
    text: t("parking"),
    dataField: "parkingId",
    formatter: (_, row) => row?.parkingId?.building_name,
  },
  {
    dataField: "cost",
    text: t("cost"),
    formatter: (_, row) => row?.amount,
  },
  {
    dataField: "paymentStatus",
    text: t("payment_status"),
    formatter: (_, row) => row?.paymentStatus,
  },
  {
    dataField: "status",
    text: t("status"),
    formatter: (_, row) => row?.bookingStatus,
  },
  {
    isDummyField: true,
    text: t("action"),
    dataField: "action",
    formatter: (_, row) => (
      <>
        <Link
          to={`/booking/${row?._id}/view`}
          className="mr-3 text-secondary"
    
        >
          <i className="far fa-eye mr-3" id={`view-${row._id}-tooltip`} />
          <UncontrolledTooltip
            placement="top"
            target={`view-${row._id}-tooltip`}
          >
            {t("view_details")}
          </UncontrolledTooltip>
        </Link>
      </>
    ),
  },
];

export default ListColumns;
