import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import AsyncSelect from "react-select/async";
import { getCarParkCodesRequest } from "store/actions";

const CarParkCodeSelector = ({
  getCarParkCodesRequest,
  carParkCode,
  onChangeHandler,
  loading,
  defaultSelected = [],
  role,
  user,
  isMulti = false,
  placeholder = "Select...",
  ...props
}) => {
  const [filter, setFilter] = useState({
    order: -1,
    orderBy: "createdAt",
    page: 1,
    limit: 30,

    // owner_id : role==="VENDOR" ? user?._id : undefined
  });
  const [selected, setSelected] = useState(defaultSelected);

  console.log(selected, "================");

  useEffect(() => {
    setSelected(defaultSelected);
  }, [defaultSelected]);

  const collectionOptions = (carParkCode) =>
    (carParkCode || []).map((item) => ({
      value: `${item?.carpark_code}`,
      label: `${item?.carpark_code}`,
    }));

  const defaultOptions = (carParkCode || []).map((item) => ({
    value: `${item?.carpark_code}`,
    label: `${item?.carpark_code}`,
  }));

  useEffect(() => {
    if (user?._id) {
      getCarParkCodesRequest({ ...filter });
    }
  }, [JSON.stringify(filter, user)]);

  const loadOptions = (inputValue, callback) => {
    getCarParkCodesRequest(
      { ...filter, search: inputValue },
      ({ data }) => {
        const options = collectionOptions(data);
        setTimeout(() => {
          callback(options);
        }, 1000);
        setFilter({ ...filter, search: inputValue });
      }
    );
  };

  const onChangeSelector = (data) => {
    if (isMulti) {
      if (data?.length) {
        // const ids= [];
        // data.forEach((a)=>{
        //     ids.push(a.value);
        // },[])
        // console.log("allIds",ids);
        onChangeHandler && onChangeHandler(data);
        setSelected(data);
      } else {
        onChangeHandler && onChangeHandler([]);
        setSelected([]);
      }
    } else {
      onChangeHandler(data);
    }
  };

  return (
    // <Select options={userOptions} />
    <AsyncSelect
      isMulti={isMulti}
      placeholder={placeholder}
      value={selected}
      cacheOptions
      defaultOptions={defaultOptions}
      loadOptions={loadOptions}
      onChange={(data) => onChangeSelector(data)}
    // onInputChange={(value)=>onInputChange(value)}
    />
  );
};

const mapStateToProps = ({ Parkings, Login }) => ({
  loading: Parkings.loading,
  carParkCode: Parkings.carParkCode,
  role: Login?.user?.role || "",
  user: Login?.user,
});

const mapDispatchToProps = (dispatch) => ({
  getCarParkCodesRequest: (data, callback) => {
    dispatch(getCarParkCodesRequest(data, callback));
  },
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation()(CarParkCodeSelector))
);
