
export const stringShortner = (string , limit) =>{
    try{
        if(string.length <= limit ){
            return string;
        }
       return string.slice(0,limit) + "...";
    }catch(e){
     return string;
    }
  }
  