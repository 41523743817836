// Get Settings
export const GET_SETTINGS = "GET_SETTINGS";
export const GET_SETTINGS_SUCCESS = "GET_SETTINGS_SUCCESS";
export const GET_SETTINGS_FAIL = "GET_SETTINGS_FAIL";

// Put Settings
export const PUT_SETTINGS = "PUT_SETTINGS";
export const PUT_SETTINGS_SUCCESS = "PUT_SETTINGS_SUCCESS";
export const PUT_SETTINGS_FAIL = "PUT_SETTINGS_FAIL";

// Access List
export const GET_ACCESS_LIST = "GET_ACCESS_LIST";
export const GET_ACCESS_LIST_SUCCESS = "GET_ACCESS_LIST_SUCCESS";
export const GET_ACCESS_LIST_FAIL = "GET_ACCESS_LIST_FAIL";

// Store Types
export const GET_STORE_TYPES = "GET_STORE_TYPES";
export const GET_STORE_TYPES_SUCCESS = "GET_STORE_TYPES_SUCCESS";
export const GET_STORE_TYPES_FAIL = "GET_STORE_TYPES_FAIL";

// Get Started
export const GET_STARTED = "GET_STARTED";
export const GET_STARTED_SUCCESS = "GET_STARTED_SUCCESS";
export const GET_STARTED_FAIL = "GET_STARTED_FAIL";

// Deploy Contract
export const POST_CONTRACT_DEPLOY = "POST_CONTRACT_DEPLOY";
export const POST_CONTRACT_DEPLOY_SUCCESS = "POST_CONTRACT_DEPLOY_SUCCESS";
export const POST_CONTRACT_DEPLOY_FAIL = "POST_CONTRACT_DEPLOY_FAIL";

export const POST_CONTRACT_DEPLOY_MULTISEND = "POST_CONTRACT_DEPLOY_MULTISEND";
export const POST_CONTRACT_DEPLOY_MULTISEND_SUCCESS =
  "POST_CONTRACT_DEPLOY_MULTISEND_SUCCESS";
export const POST_CONTRACT_DEPLOY_MULTISEND_FAIL =
  "POST_CONTRACT_DEPLOY_MULTISEND_FAIL";

export const POST_CONTRACT_DEPLOY_PPG = "POST_CONTRACT_DEPLOY_PPG";
export const POST_CONTRACT_DEPLOY_SUCCESS_PPG =
  "POST_CONTRACT_DEPLOY_SUCCESS_PPG";
export const POST_CONTRACT_DEPLOY_FAIL_PPG = "POST_CONTRACT_DEPLOY_FAIL_PPG";

export const POST_CONTRACT_DEPLOY_GOV = "POST_CONTRACT_DEPLOY_GOV";
export const POST_CONTRACT_DEPLOY_SUCCESS_GOV =
  "POST_CONTRACT_DEPLOY_SUCCESS_GOV";
export const POST_CONTRACT_DEPLOY_FAIL_GOV = "POST_CONTRACT_DEPLOY_FAIL_GOV";

// Compile Token Contract
export const POST_COMPILE_CONTRACT = "POST_COMPILE_CONTRACT";
export const POST_COMPILE_CONTRACT_SUCCESS = "POST_COMPILE_CONTRACT_SUCCESS";
export const POST_COMPILE_CONTRACT_FAIL = "POST_COMPILE_CONTRACT_FAIL";

export const POST_COMPILE_CONTRACT_GOV = "POST_COMPILE_CONTRACT_GOV";
export const POST_COMPILE_CONTRACT_SUCCESS_GOV =
  "POST_COMPILE_CONTRACT_SUCCESS_GOV";
export const POST_COMPILE_CONTRACT_FAIL_GOV = "POST_COMPILE_CONTRACT_FAIL_GOV";

export const POST_COMPILE_CONTRACT_PPG = "POST_COMPILE_CONTRACT_PPG";
export const POST_COMPILE_CONTRACT_SUCCESS_PPG =
  "POST_COMPILE_CONTRACT_SUCCESS_PPG";
export const POST_COMPILE_CONTRACT_FAIL_PPG = "POST_COMPILE_CONTRACT_FAIL_PPG";

// Air drop
export const AIR_DROP = "AIR_DROP";
export const AIR_DROP_SUCCESS = "AIR_DROP_SUCCESS";
export const AIR_DROP_FAIL = "AIR_DROP_FAIL";

export const AIR_DROP_LIST = "AIR_DROP_LIST";
export const AIR_DROP_LIST_SUCCESS = "AIR_DROP_LIST_SUCCESS";
export const AIR_DROP_LIST_FAIL = "AIR_DROP_LIST_FAIL";
