import * as TYPES from "./actionTypes";

const SETTINGS = {};

const INIT_STATE = {
  loading: false,
  settings: SETTINGS,
  airDropList: [],
  airDropCount: 0,
  starterLoading: false,
  error: "",
};

const Settings = (state = INIT_STATE, action) => {
  switch (action.type) {
    /* Get Global Settings */
    case TYPES.GET_SETTINGS:
    case TYPES.AIR_DROP:
      return {
        ...state,
        loading: true,
        storeLoading: true,
        isStoreFailed: false,
        error: "",
      };

    case TYPES.GET_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        settings: action.payload,
        isStoreFailed: false,
        storeLoading: false,
      };

    case TYPES.GET_SETTINGS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        storeLoading: false,
        isStoreFailed: true,
      };
    /* Get Global Settings END */

    /* Update Global Settings */
    case TYPES.PUT_SETTINGS:
      return {
        ...state,
        loading: true,
        error: "",
      };

    case TYPES.PUT_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
      };

    case TYPES.PUT_SETTINGS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    /* Update Global Settings END */

    /* Get Access List */
    case TYPES.GET_ACCESS_LIST:
      return {
        ...state,
        loading: true,
        error: "",
      };

    case TYPES.GET_ACCESS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        accessList: action.payload,
      };

    case TYPES.GET_ACCESS_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    /* Get Access List END */

    /* Get Store Types */
    case TYPES.GET_STORE_TYPES:
      return {
        ...state,
        loading: true,
        error: "",
      };

    case TYPES.GET_STORE_TYPES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        storeTypes: action.payload,
      };

    case TYPES.GET_STORE_TYPES_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    /* Get Store Types END */

    /* Get Store Started */
    case TYPES.GET_STARTED:
      return {
        ...state,
        starterLoading: true,
      };

    case TYPES.GET_STARTED_SUCCESS:
      return {
        ...state,
        starterLoading: false,
        getStarted: action.payload,
      };

    case TYPES.GET_STARTED_FAIL:
      return {
        ...state,
        starterLoading: false,
        error: action.payload,
        getStarted: {},
      };
    /* Get Store Started END */

    /* Post Deploy Contract */
    case TYPES.POST_CONTRACT_DEPLOY:
      return {
        ...state,
        loading: true,
      };

    case TYPES.POST_CONTRACT_DEPLOY_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case TYPES.POST_CONTRACT_DEPLOY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    /* Post Deploy Contract END */

    /* Post Deploy Contract  For multisend*/
    case TYPES.POST_CONTRACT_DEPLOY_MULTISEND:
      return {
        ...state,
        loading: true,
      };

    case TYPES.POST_CONTRACT_DEPLOY_MULTISEND_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case TYPES.POST_CONTRACT_DEPLOY_MULTISEND_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    /* Post Deploy Contract END */

    /* Post Deploy Contract */
    case TYPES.POST_CONTRACT_DEPLOY_PPG:
      return {
        ...state,
        loading: true,
      };

    case TYPES.POST_CONTRACT_DEPLOY_SUCCESS_PPG:
      return {
        ...state,
        loading: false,
      };

    case TYPES.POST_CONTRACT_DEPLOY_FAIL_PPG:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    /* Post Deploy Contract END */

    /* Post Deploy Contract */
    case TYPES.POST_CONTRACT_DEPLOY_GOV:
      return {
        ...state,
        loading: true,
      };

    case TYPES.POST_CONTRACT_DEPLOY_SUCCESS_GOV:
      return {
        ...state,
        loading: false,
      };

    case TYPES.POST_CONTRACT_DEPLOY_FAIL_GOV:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    /* Post Deploy Contract END */

    /* Post Compile Contract */
    case TYPES.POST_COMPILE_CONTRACT:
      return {
        ...state,
        loading: true,
      };

    case TYPES.POST_COMPILE_CONTRACT_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case TYPES.POST_COMPILE_CONTRACT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    /* Post Compile Contract END */
    /* Post Compile Contract */
    case TYPES.POST_COMPILE_CONTRACT_PPG:
      return {
        ...state,
        loading: true,
      };

    case TYPES.POST_COMPILE_CONTRACT_SUCCESS_PPG:
      return {
        ...state,
        loading: false,
      };

    case TYPES.POST_COMPILE_CONTRACT_FAIL_PPG:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    /* Post Compile Contract END */

    /* Post Compile Contract */
    case TYPES.POST_COMPILE_CONTRACT_GOV:
      return {
        ...state,
        loading: true,
      };

    case TYPES.POST_COMPILE_CONTRACT_SUCCESS_GOV:
      return {
        ...state,
        loading: false,
      };

    case TYPES.POST_COMPILE_CONTRACT_FAIL_GOV:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    /* Post Compile Contract END */

    //Air Drop
    case TYPES.AIR_DROP_FAIL:
    case TYPES.AIR_DROP_SUCCESS:
    case TYPES.AIR_DROP_LIST_FAIL:
      return {
        ...state,
        loading: false,
      };

    case TYPES.AIR_DROP_LIST:
      return {
        ...state,
        loading: true,
      };

    case TYPES.AIR_DROP_LIST_SUCCESS:
      return {
        ...state,
        airDropList: action.payload.data,
        totalCount: action.payload.totalCount,
        loading: false,
      };
    case TYPES.AIR_DROP_LIST_FAIL:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default Settings;
