import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Button, Col, Container, Row, Spinner } from "reactstrap";

//Import Breadcrumb

//Import actions
import {
  getGraphDataRequest,
  getParkingOwnerRequest,
  getStatsDataRequest,
  updateUserStatusRequest,
  getSubscriptionsPlanRequest
} from "store/actions";
import moment from "moment";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { withTranslation } from "react-i18next";
import OwnerBookings from "./OwnerBookings";
import Booking from "assets/images/bookingimg.png";
import Revnue from "assets/images/revnue.png";
import Customer from "assets/images/customer.png";
import { GraphWidget } from "components/GraphWidget";

export const getMonthName = (monthNumber) => {
  const date = new Date();
  date.setMonth(monthNumber - 1);

  return date.toLocaleString("en-US", { month: "long" });
};

export const makeGraphDataFormat = (arr, name) => {
  const newObj = {};
  arr?.forEach((item) => {
    const month = getMonthName(item?._id?.month);
    const total = item?.total;
    if (!newObj.hasOwnProperty(month)) {
      newObj[month] = total;
    }
  });
  return newObj;
};

export const userDataGraph = [
  {
    name: "January",
    Revenue: 0,
  },
  {
    name: "February",
    Revenue: 0,
  },
  {
    name: "March",
    Revenue: 0,
  },
  {
    name: "April",
    Revenue: 0,
  },
  {
    name: "May",
    Revenue: 0,
  },
  {
    name: "June",
    Revenue: 0,
  },
  {
    name: "July",
    Revenue: 0,
  },
  {
    name: "August",
    Revenue: 0,
  },
  {
    name: "September",
    Revenue: 0,
  },
  {
    name: "October",
    Revenue: 0,
  },
  {
    name: "November",
    Revenue: 0,
  },
  {
    name: "December",
    Revenue: 0,
  },
];



const ViewCustomer = (props) => {
  const [graphsData, setGraphsData] = useState(userDataGraph);
  const dispatch = useDispatch()

  const history = useHistory();

  const {
    match: { params },
    getParkingOwnerRequest,
    parkingOwnerDetails,
    error,
    loading,
    updateUserStatusRequest,
    updateStatusLoader,
    graph,
    stats
  } = props;
  const { subscriptionPlans } = useSelector(
    ({ Subscriptions }) => Subscriptions
  );

  useEffect(() => {
    if (params && params.id) {
      getParkingOwnerRequest(params.id);
      props.onGetGraphData(`?isAdmin=false&parkingOwnerId=${params.id}`);
    }
  }, [params]);

  const onChangeStatus = () => {
    updateUserStatusRequest(
      {
        userId: parkingOwnerDetails._id,
        status: parkingOwnerDetails.status !== "blocked" ? "blocked" : "active",
      },
      (response) => {
        console.log("update user status", response);
      }
    );
  };

  useEffect(() => {
    if (graph) {
      try {
        //orders
        const revenue = makeGraphDataFormat(graph);
        const newUserGraphData = graphsData.map((item) => {
          item.Revenue = revenue[item.name] ? revenue[item.name] : 0;
          return item;
        });
        setGraphsData(newUserGraphData);
      } catch (error) {
        console.log("error", error);
      }
    }
  }, [graph]);

  useEffect(() => {
    if (parkingOwnerDetails?._id) {
      dispatch(getSubscriptionsPlanRequest({ page: 1, limit: 100, order: -1, ownerId: parkingOwnerDetails?._id }));

    }
  }, [parkingOwnerDetails?._id]);


  return (
    <React.Fragment>
      <div className="page-content">
        <section className="viewprofile_main">
          <Container>
            <a
              href="javascript:void(0)"
              className="backto_censor"
              onClick={() => history.push("/parking-owners")}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M17.77 3.77L16 2L6 12L16 22L17.77 20.23L9.54 12L17.77 3.77Z"
                  fill="#2E3A59"
                ></path>
              </svg>
              {props.t("profile")}
            </a>

            <div className="profile_content">
              <Row>
                <Col lg={4} md={5} sm={12}>
                  <div className="common_profile_card profile_Card mb-3">
                    <div className="profile_picname">
                      {/* <div className="profile_pic">
                    <img src={""} alt="" className="img-fluid" />
                  </div> */}
                      <h3>{parkingOwnerDetails?.name || "NA"}</h3>
                    </div>
                    <div className="divider_line"></div>

                    <div className="profile_status">
                      <div className="status_profile">
                        <h4>
                          {moment(parkingOwnerDetails?.createdAt).format(
                            "DD MMMM YYYY"
                          )}
                        </h4>
                      </div>
                      <div className="status_profile">
                        <h4 className="text-capitalize">{props.t("status")}</h4>
                        <p>{parkingOwnerDetails?.status}</p>
                      </div>
                    </div>
                    {/* <Button className="send_msg mt-3">Send Message</Button> */}
                  </div>

                  <div className="common_profile_card personal_Card mb-3">
                    <h5>{props.t("personal_information")}</h5>
                    <div className="divider_line"></div>
                    <ul className="personal_info_list">
                      <li>
                        <p>
                          <strong>{props.t("name")}</strong>
                        </p>
                        <p>{parkingOwnerDetails?.name || "NA"}</p>
                      </li>
                      <li>
                        <p>
                          <strong>{props.t("email")}</strong>
                        </p>
                        <p>{parkingOwnerDetails?.email || "NA"}</p>
                      </li>
                      <li>
                        <p>
                          <strong>{props.t("mobile")}</strong>
                        </p>
                        <p>
                          {parkingOwnerDetails?.countryCode +
                            " " +
                            parkingOwnerDetails?.mobileNumber}
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>{props.t("wallet")}</strong>
                        </p>
                        <p>${parkingOwnerDetails?.walletAmount || 0}</p>
                      </li>
                    </ul>
                  </div>

                  <div className="common_profile_card address_Card mb-3">
                    <div className="head_withbutton">
                      <h5>{props.t("address")}</h5>
                      {/* <Button className="edit_btn send_msg">Edit</Button> */}
                    </div>
                    <p>NA</p>
                  </div>
                  <div>
                    <Button
                      className="common-btn"
                      onClick={() => onChangeStatus()}
                    >
                      {updateStatusLoader && (
                        <Spinner size="sm" color="white" />
                      )}
                      {parkingOwnerDetails?.status !== "blocked"
                        ? "Block"
                        : "Active"}
                    </Button>
                  </div>
                  <div className="common_profile_card address_Card mb-3">
                    <div className="head_withbutton">
                      Subscription Plan
                    </div>
                    {subscriptionPlans?.slice()?.reverse()?.map((plan) => {
                      return (
                        // <Col key={plan?._id} lg={4} md={2} sm={4}>
                        <div className="card_coommnsub monthly_Card my-3">
                          <div className="d-flex justify-content-end cursor-pointer">
                          </div>
                          <h5><b>Parking Name:</b> {plan?.parkingId?.building_name}</h5>
                          <div>
                            <b>Plan Name:</b> {plan?.name}
                          </div>
                          <div><b>Type:</b> {plan?.type == 'month' ? "Monthly" : "Yearly"}</div>
                          <div><b>Duration(in {plan?.type}):</b> {plan?.duration}</div>
                          <div><b>Price:</b> ${plan?.price}</div>
                          <div><b>Create At:</b> {moment(plan?.createdAt).format('MMMM Do YYYY')}</div>
                          <div><b>Expire At:</b> {moment(plan?.createdAt).add(plan?.duration, plan?.type == 'month' ? 'months' : 'years').format('MMMM Do YYYY')}</div>
                          <p
                            dangerouslySetInnerHTML={{ __html: plan?.description }}
                          ></p>
                          {/* {
                            plan?.status === 'active' ?
                              <div
                                className="btn btn-success"
                              >
                                {props.t("active")}
                              </div>
                              :
                              <div
                                className="btn btn-secondary"
                              >
                                {props.t("not_subscribe")}
                              </div>
                          } */}
                        </div>
                        // </Col>
                      );
                    })}

                  </div>
                </Col>


                <Col lg={8} md={7} sm={12}>
                  <div className="common_profile_card">
                    <section className="analytic-graphs">
                      <Row>
                        <Col lg={4} md={6} sm={12}>
                          <div className="common_card booking_card">
                            <div className="inner_content">
                              <p>{props.t("bookings")}</p>
                              <h4>{stats?.bookings || 0}</h4>

                            </div>
                            <div className="inner_img">
                              <img src={Booking} alt="" className="img-fluid" />
                            </div>
                          </div>
                        </Col>
                        <Col lg={4} md={6} sm={12}>
                          <div className="common_card revenue_card">
                            <div className="inner_content">
                              <p>{props.t("revenue")}</p>
                              <h4>$ {parseInt(stats?.revenue || 0)}</h4>

                            </div>
                            <div className="inner_img">
                              <img src={Revnue} alt="" className="img-fluid" />
                            </div>
                          </div>
                        </Col>
                        <Col lg={4} md={6} sm={12}>
                          <div className="common_card customer_card">
                            <div className="inner_content">
                              <p>{props.t("customers")}</p>
                              <h4>{stats?.customers || 0}</h4>

                            </div>
                            <div className="inner_img">
                              <img
                                src={Customer}
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </section>
                    <section className="revenue_graph_main mt-4">
                      <Row>
                        <Col lg={12} md={12} sm={12}>
                          <div className="revenue_graph">
                            {/* <img src={Revenurgraph} alt="" className="img-fluid" /> */}
                            <GraphWidget
                              title="Revenue"
                              data={graphsData}
                              dataKey="Revenue"
                              color={"#0774e8"}
                              loading={false}
                            />
                          </div>
                        </Col>
                      </Row>
                    </section>
                    <section className="latesttable_table">
                      <div className="latest_table_inner">
                        <h2 class="fillter_head pl-2 mb-2">
                          {props.t("parking")} {props.t("details")}
                        </h2>
                        <OwnerBookings ownerId={params?.id} />
                      </div>
                    </section>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </section>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = ({ customers, Dashboard }) => ({
  error: customers.error,
  loading: customers.loading,
  parkingOwnerDetails: customers.parkingOwnerDetails,
  updateStatusLoader: customers.updateStatusLoader,
  graph: Dashboard.graphData,
  stats: Dashboard.statsData,
});

const mapDispatchToProps = (dispatch) => ({
  getParkingOwnerRequest: (id) => dispatch(getParkingOwnerRequest(id)),
  updateUserStatusRequest: (data, callback) =>
    dispatch(updateUserStatusRequest(data, callback)),
  onGetGraphData: (data) => dispatch(getGraphDataRequest(data)),
  onGetStatsData: (data) => dispatch(getStatsDataRequest(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ViewCustomer));
