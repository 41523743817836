export const GET_PARKING_STRUCTURES_REQUEST = "GET_PARKING_STRUCTURES_REQUEST";
export const GET_PARKING_STRUCTURES_SUCCESS = "GET_PARKING_STRUCTURES_SUCCESS";
export const GET_PARKING_STRUCTURES_FAIL = "GET_PARKING_STRUCTURES_FAIL";

export const GET_PARKING_STRUCTURE_REQUEST = "GET_PARKING_STRUCTURE_REQUEST";
export const GET_PARKING_STRUCTURE_SUCCESS = "GET_PARKING_STRUCTURE_SUCCESS";
export const GET_PARKING_STRUCTURE_FAIL = "GET_PARKING_STRUCTURE_FAIL";

export const ADD_PARKING_STRUCTURE_REQUEST = "ADD_PARKING_STRUCTURE_REQUEST";
export const ADD_PARKING_STRUCTURE_SUCCESS = "ADD_PARKING_STRUCTURE_SUCCESS";
export const ADD_PARKING_STRUCTURE_FAIL = "ADD_PARKING_STRUCTURE_FAIL";

export const UPDATE_PARKING_STRUCTURE_REQUEST = "UPDATE_PARKING_STRUCTURE_REQUEST";
export const UPDATE_PARKING_STRUCTURE_SUCCESS = "UPDATE_PARKING_STRUCTURE_SUCCESS";
export const UPDATE_PARKING_STRUCTURE_FAIL = "UPDATE_PARKING_STRUCTURE_FAIL";

export const DELETE_PARKING_STRUCTURE_REQUEST = "DELETE_PARKING_STRUCTURE_REQUEST";
export const DELETE_PARKING_STRUCTURE_SUCCESS = "DELETE_PARKING_STRUCTURE_SUCCESS";
export const DELETE_PARKING_STRUCTURE_FAIL = "DELETE_PARKING_STRUCTURE_FAIL";

export const LIST_PERMIT_CARDS_REQUEST = "LIST_PERMIT_CARDS_REQUEST";
export const LIST_PERMIT_CARDS_SUCCESS = "LIST_PERMIT_CARDS_SUCCESS";
export const LIST_PERMIT_CARDS_FAIL = "LIST_PERMIT_CARDS_FAIL";

export const ASSIGN_PERMIT_CARD_REQUEST = "ASSIGN_PERMIT_CARD_REQUEST";
export const ASSIGN_PERMIT_CARD_SUCCESS = "ASSIGN_PERMIT_CARD_SUCCESS";
export const ASSIGN_PERMIT_CARD_FAIL = "ASSIGN_PERMIT_CARD_FAIL";

export const REMOVE_PERMIT_CARD_REQUEST = "REMOVE_PERMIT_CARD_REQUEST";
export const REMOVE_PERMIT_CARD_SUCCESS = "REMOVE_PERMIT_CARD_SUCCESS";
export const REMOVE_PERMIT_CARD_FAIL = "REMOVE_PERMIT_CARD_FAIL";

export const GET_USER_VEHICALS_REQUEST = "GET_USER_VEHICALS_REQUEST";
export const GET_USER_VEHICALS_SUCCESS = "GET_USER_VEHICALS_SUCCESS";
export const GET_USER_VEHICALS_FAIL = "GET_USER_VEHICALS_FAIL";


export const GET_CARPARK_CODE_REQUEST = "GET_CARPARK_CODE_REQUEST";
export const GET_CARPARK_CODE_SUCCESS = "GET_CARPARK_CODE_SUCCESS";
export const GET_CARPARK_CODE_FAIL = "GET_CARPARK_CODE_FAIL";
