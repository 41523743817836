import React,{useState,useEffect} from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Form, FormGroup, Label, Input, FormText, Spinner } from "reactstrap";
import toastr from "toastr";
// images
import Editimg from "../../../src/assets/images/editimgeright.png";
import User1 from "../../../src/assets/images/user1.jpg";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { uploadFile , updateProfileRequest} from "store/actions";
import { CountryCodeSelector } from "components/Common/CountryCodeSelector";


const Editprofile = (props) => { 
  const {user,loading} = useSelector(({Login})=>Login);
  const dispatch = useDispatch();
  const [fields,setFields]  = useState({
     name : user?.name || "",
     profileImage : user?.profileImage?._id,
     mobileNumber : user?.mobileNumber || "",
     countryCode : user?.countryCode || "",
     password : "",
     confirmPassword : ""
  })
  const [logoselectedFiles, setlogoselectedFiles] = useState([]);

  const onFieldsChange = (e) =>{
    const {name,value} = e.target;
    setFields({...fields,[name]:value})
  }

  useEffect(() => {
    if (user?.profileImage?.link && !!user?.profileImage?.link) {
      setlogoselectedFiles([{ preview: user?.profileImage?.link }]);
    }
  }, [user?.profileImage]);

  const uploadFileSuccess = (name) => (response) => {
    console.log("data response",response);
    const { _id,link} = response.data;
    onFieldsChange({ target: {name : name, value: _id } });
    if(name==="profileImage"){
      setlogoselectedFiles([{ preview: link }]);
    }
  };


  const onUploadDocFile = (name,file) =>{
    console.log("file",file);

    const ext = file.name.split(".").pop();
      console.log("extension",ext);

      //checking extension 
      if (!["png","jpg","jpeg"].includes(ext)) {
        toastr.error(
          props.t("png_jpg_jpeg_allow")
        );
        return;
      }

    if (file?.size >= 5242880) {
      toastr.error(props.t("max_upload_5mb"));
      return;
    }
      if(file){
         dispatch(uploadFile({ file }, uploadFileSuccess(name)));
      } 
   } 

   const onSubmitForm = (e) =>{
         e.preventDefault();
         const payload = {...fields};
         console.log("payload",payload)

         if(fields.password && fields.confirmPassword !== fields.password){
            toastr.error("Confirm Password not matched!")  
            return;
         }
         
         delete payload.confirmPassword
         
         if(!fields.password){
           delete payload.password;
           delete payload.confirmPassword
         }
         dispatch(updateProfileRequest({...payload},()=>{}))         
   }

  const onRemoveImage = () =>{
      setFields((prev)=>({...prev,profileImage : null }));
      setlogoselectedFiles([])
  }

  return (
    <section className="Editprofile_Main">
      <Container>
        <Row>
          <Col lg={6} md={6} sm={12}>
            <div className="Edit_Section">
              <h2 className="fillter_head">{props.t("edit_profile")}</h2>
              <Form className="add_form" onSubmit={onSubmitForm}> 
                <div className="fileupload">


                <div className="wraaping_both">
                  <div className="upload_img">
                    <img src={logoselectedFiles?.[0]?.preview} alt="" className="img-fluid"/>
                  </div>


                  <div className="button_uploads">
                  <div class="upload-btn-wrapper position-relative">
                    <button class="btn">{props.t("change_profile")}</button>
                    <input type="file" name="myfile" onChange={(e)=>onUploadDocFile("profileImage",e.target.files[0])}/>
                  </div>
                  <Button className="remove" onClick={()=>onRemoveImage()}>{props.t("remove")}</Button>
                  </div>
                  </div>    


                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.
                  </p>
                </div>

                <FormGroup>
                  <Label for="exampleEmail">{props.t("name")}</Label>
                  <Input
                    type="text"
                    name="name"
                    id="exampleEmail"
                    onChange={onFieldsChange}
                    value={fields.name}
                  />
                </FormGroup>

                  <FormGroup>
                    <Label for="exampleEmail">{props.t("country_code")}</Label>
                    <CountryCodeSelector
                       extraStyleClasses=""
                        selectorName="countryCode"
                        selectedCountryCode={fields.countryCode}
                        onChangeHandler={onFieldsChange}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="exampleEmail">{props.t("mobile")}</Label>
                    <Input
                      type="number"
                      name="mobileNumber"
                      id="exampleEmail"
                      onChange={onFieldsChange}
                      value={fields.mobileNumber}
                    />
                  </FormGroup>
                <FormGroup>
                  <Label for="exampleEmail">{props.t("password")}</Label>
                  <Input
                    type="password"
                    name="password"
                    id="exampleEmail"
                    onChange={onFieldsChange}
                    value={fields.password}

                  />
                </FormGroup>

                <FormGroup>
                  <Label for="exampleEmail">{props.t("confirm_password")}</Label>
                  <Input
                    type="password"
                    name="confirmPassword"
                    id="exampleEmail"
                    onChange={onFieldsChange}
                    value={fields.confirmPassword}

                  />
                </FormGroup>
                <Button type="submit"  className="btn_addform mt-5">
                {loading ? 
                      <Spinner  className="mx-1" size="sm" color="light"/> 
                  : null}
                  {props.t("update")}</Button>
              </Form>
            </div>
          </Col>
          <Col lg={6} md={6} sm={12}>
            <div className="edit_imgright">
              <img src={Editimg} alt="" className="img-fluid" />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default withTranslation()(Editprofile);
