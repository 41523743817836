export const GET_SUBSCRIPTIONS_REQUEST =   "GET_SUBSCRIPTIONS_REQUEST";
export const GET_SUBSCRIPTIONS_SUCCESS =   "GET_SUBSCRIPTIONS_SUCCESS";
export const GET_SUBSCRIPTIONS_FAIL =   "GET_SUBSCRIPTIONS_FAIL";

export const REMOVE_SUBSCRIPTIONS_REQUEST =   "REMOVE_SUBSCRIPTIONS_REQUEST";
export const REMOVE_SUBSCRIPTIONS_SUCCESS =   "REMOVE_SUBSCRIPTIONS_SUCCESS";
export const REMOVE_SUBSCRIPTIONS_FAIL =   "REMOVE_SUBSCRIPTIONS_FAIL";

// add subscriptions
export const ADD_SUBSCRIPTION_PLAN_REQUEST = "ADD_SUBSCRIPTION_PLAN_REQUEST"
export const ADD_SUBSCRIPTION_PLAN_SUCCESS = "ADD_SUBSCRIPTION_PLAN_SUCCESS"
export const ADD_SUBSCRIPTION_PLAN_FAIL = "ADD_SUBSCRIPTION_PLAN_FAIL"

// update subscriptions
export const UPDATE_SUBSCRIPTION_PLAN_REQUEST = "UPDATE_SUBSCRIPTION_PLAN_REQUEST"
export const UPDATE_SUBSCRIPTION_PLAN_SUCCESS = "UPDATE_SUBSCRIPTION_PLAN_SUCCESS"
export const UPDATE_SUBSCRIPTION_PLAN_FAIL = "UPDATE_SUBSCRIPTION_PLAN_FAIL"

// delete subscriptions
export const DELETE_SUBSCRIPTION_PLAN_REQUEST = "DELETE_SUBSCRIPTION_PLAN_REQUEST"
export const DELETE_SUBSCRIPTION_PLAN_SUCCESS = "DELETE_SUBSCRIPTION_PLAN_SUCCESS"
export const DELETE_SUBSCRIPTION_PLAN_FAIL = "DELETE_SUBSCRIPTION_PLAN_FAIL"

// get subscriptions
export const GET_SUBSCRIPTIONS_PLAN_REQUEST = "GET_SUBSCRIPTIONS_PLAN_REQUEST"
export const GET_SUBSCRIPTIONS_PLAN_SUCCESS = "GET_SUBSCRIPTIONS_PLAN_SUCCESS"
export const GET_SUBSCRIPTIONS_PLAN_FAIL = "GET_SUBSCRIPTIONS_PLAN_FAIL"


// get subscription
export const GET_SUBSCRIPTION_PLAN_REQUEST = "GET_SUBSCRIPTION_PLAN_REQUEST"
export const GET_SUBSCRIPTION_PLAN_SUCCESS = "GET_SUBSCRIPTION_PLAN_SUCCESS"
export const GET_SUBSCRIPTION_PLAN_FAIL = "GET_SUBSCRIPTION_PLAN_FAIL"

//cancel vendor subscription
export const CANCEL_SUBSCRIPTION_REQUEST = "CANCEL_SUBSCRIPTION_REQUEST"
export const CANCEL_SUBSCRIPTION_SUCCESS = "CANCEL_SUBSCRIPTION_SUCCESS"
export const CANCEL_SUBSCRIPTION_FAIL = "CANCEL_SUBSCRIPTION_FAIL"

//subscription users
export const GET_SUBSCRIPTION_USERS_REQUEST = "GET_SUBSCRIPTION_USERS_REQUEST"
export const GET_SUBSCRIPTION_USERS_SUCCESS = "GET_SUBSCRIPTION_USERS_SUCCESS"
export const GET_SUBSCRIPTION_USERS_FAIL = "GET_SUBSCRIPTION_USERS_FAIL"

// assign subscription
export const ASSIGN_SUBSCRIPTION_TO_USER_REQUEST = "ASSIGN_SUBSCRIPTION_TO_USER_REQUEST"
export const ASSIGN_SUBSCRIPTION_TO_USER_SUCCESS = "ASSIGN_SUBSCRIPTION_TO_USER_SUCCESS"
export const ASSIGN_SUBSCRIPTION_TO_USER_FAIL = "ASSIGN_SUBSCRIPTION_TO_USER_FAIL"
