import React from "react";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { withTranslation } from "react-i18next";

const CustomInfoModal = ({show,title,onClose,children,...props}) => {

  return (
     <Modal 
       show={show} 
       onHide={onClose}  
       size="sm"
       aria-labelledby="contained-modal-title-vcenter"
       className="addslot_modalfrom"
       centered
    >
     <Modal.Header>
       <Modal.Title>{title}</Modal.Title>
     </Modal.Header>
     <Modal.Body>
       {children}
    </Modal.Body>
     <Modal.Footer>
       <Button variant="secondary" onClick={onClose}>
         {props.t("close")}
       </Button>
     </Modal.Footer>
   </Modal>
  );
};

export default  withTranslation()(CustomInfoModal);
