import React,{useState,useEffect} from "react";
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"
import { withTranslation } from "react-i18next"
import AsyncSelect from 'react-select/async';
import {
    getUserVehicalsRequest,
} from "store/actions"

const UserVehicalSelector = ({
    userId,
    userVehicleList,
    getUserVehicalsRequest,
    loading,
    onChangeHandler,
    defaultSelected = [],
    role,
    user,
    isMulti=false,
    placeholder="Select...",
    ...props }) => {
    
    console.log("userId vehical",userId)
     
    const [filter, setFilter] = useState({
        userId: userId
    });
    const [selected,setSelected] = useState(defaultSelected);

    useEffect(() => {
        setSelected(defaultSelected)
    }, [defaultSelected]);

    const collectionOptions = (userVehicleList) => ((userVehicleList || []).map((item)=> ({value : item?._id,label : item?.vehicalNumber})));  
    
    const defaultOptions = (userVehicleList || []).map((item)=> ({value : item?._id,label : item?.vehicalNumber}));  

    useEffect(() => {
        if (userId) {
            getUserVehicalsRequest({ ...filter,userId});
        }
      }, [JSON.stringify(filter),userId]);

  const loadOptions = (inputValue,callback) =>{
    getUserVehicalsRequest({...filter,userId,search:inputValue},({data})=>{
        const options = collectionOptions(data);
        setTimeout(()=>{
            callback(options);  
        },1000);
        setFilter({...filter,search:inputValue})
    });
}

  const onChangeSelector = (data) =>{
    if(isMulti){
    if(data?.length){
        // const ids= [];
        // data.forEach((a)=>{
        //     ids.push(a.value);
        // },[])
        // console.log("allIds",ids);
        onChangeHandler && onChangeHandler(data);
        setSelected(data);
    }else{
        onChangeHandler && onChangeHandler([]);
        setSelected([]);
    }
  }else{
    onChangeHandler(data);
  }
}

    return(
           // <Select options={userOptions} />
           <AsyncSelect 
                isMulti={isMulti} 
                placeholder={placeholder}
                value={selected}
                cacheOptions
                defaultOptions={defaultOptions}  
                loadOptions={loadOptions} 
                onChange={(data)=>onChangeSelector(data)}
                // onInputChange={(value)=>onInputChange(value)}

          />
    );
}

  
const mapStateToProps = ({ Parkings }) => ({
    loading: Parkings.loading,
    userVehicleList: Parkings.userVehicleList,
  });
  
  const mapDispatchToProps = (dispatch) => {
    return {
        getUserVehicalsRequest: (data,callback) => {
        dispatch(getUserVehicalsRequest(data,callback));
      }
    };
  };
  
  export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(withTranslation()(UserVehicalSelector)),
  )



