import {
    ADD_CONTACT_US_FAIL,
    ADD_CONTACT_US_REQUEST,
    ADD_CONTACT_US_SUCCESS,
    GET_CONTACT_US_LIST_FAIL,
    GET_CONTACT_US_LIST_REQUEST,
    GET_CONTACT_US_LIST_SUCCESS
    } from "./actionTypes";
    
    //support
    
    export const addContactUsRequest = (data,callback) =>{
      return {
        type: ADD_CONTACT_US_REQUEST,
        payload: {data, callback },
      }
    }
    export const addContactUsSuccess = (data) =>{
      return {
        type: ADD_CONTACT_US_SUCCESS,
        payload: data,
      }
    }
    export const addContactUsFail = (error) =>{
      return {
        type: ADD_CONTACT_US_FAIL,
        payload: error,
     }
    }
    


export const getContactUsListRequest = (data,callback) =>{
  console.log(data)
  return {
    type: GET_CONTACT_US_LIST_REQUEST,
    payload: {data, callback },
  }
}
export const getContactUsListSuccess = (data) =>{
  return {
    type: GET_CONTACT_US_LIST_SUCCESS,
    payload: data,
  }
}
export const getContactUsListFail = (error) =>{
  return {
    type: GET_CONTACT_US_LIST_FAIL,
    payload: error,
  }
}
    