import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, Col, Container, Row, Spinner } from "reactstrap";

//Import Breadcrumb

//Import actions
import { getCustomer, updateUserStatusRequest } from "store/actions";
import moment from "moment";
import { withTranslation } from "react-i18next";
import UserBookings from "./UserBookings";

const ViewCustomer = (props) => {
  const history = useHistory();
  const {
    match: { params },
    getCustomer,
    customer,
    error,
    loading,
    updateUserStatusRequest,
    updateStatusLoader,
  } = props;

  useEffect(() => {
    if (params && params.id) {
      getCustomer(params.id);
    }
  }, [params]);

  const onChangeStatus = () => {
    updateUserStatusRequest(
      {
        userId: customer._id,
        status: customer.status !== "blocked" ? "blocked" : "active",
      },
      (response) => {
        console.log("update user status", response);
      }
    );
  };

  const goToSubscription = () => {
    history.push({
      pathname: '/users/profile/subscription-assign',
      state: { customer: customer },
    });
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <section className="viewprofile_main">
          <Container>
            <a
              href="javascript:void(0)"
              className="backto_censor"
              onClick={() => history.goBack()}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M17.77 3.77L16 2L6 12L16 22L17.77 20.23L9.54 12L17.77 3.77Z"
                  fill="#2E3A59"
                ></path>
              </svg>
              {props.t("profile")}
            </a>

            <div className="profile_content">
              <Row>
                <Col lg={4} md={5} sm={12}>
                  <div className="common_profile_card profile_Card mb-3">
                    <div className="profile_picname">
                      <div className="profile_pic">
                        {/* <img src={User2} alt="" className="img-fluid" /> */}
                      </div>
                      <h3>{customer?.name || "NA"}</h3>
                    </div>
                    <div className="divider_line"></div>

                    <div className="profile_status">
                      <div className="status_profile">
                        <h4>
                          {moment(customer?.createdAt).format("DD MMMM YYYY")}
                        </h4>
                      </div>
                      <div className="status_profile">
                        <h4 className="text-capitalize">{props.t("status")}</h4>
                        <div
                          className={`${customer.status === "active"
                              ? "status_active"
                              : "status_inactive"
                            }`}
                        >
                          <span className="text-capitalize">
                            {customer.status}
                          </span>
                        </div>
                        {/* <p>{customer?.status}</p> */}
                      </div>
                    </div>
                    {/* <Button className="send_msg mt-3">Send Message</Button> */}
                  </div>

                  <div className="common_profile_card personal_Card mb-3">
                    <h5>{props.t("personal_information")}</h5>
                    <div className="divider_line"></div>
                    <ul className="personal_info_list">
                      <li>
                        <p>
                          <strong>{props.t("name")}</strong>
                        </p>
                        <p>{customer?.name || "NA"}</p>
                      </li>
                      <li>
                        <p>
                          <strong>{props.t("email")}</strong>
                        </p>
                        <p>{customer?.email || "NA"}</p>
                      </li>
                      <li>
                        <p>
                          <strong>{props.t("mobile")}</strong>
                        </p>
                        <p>
                          {customer?.countryCode + " " + customer?.mobileNumber}
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>{props.t("wallet")}</strong>
                        </p>
                        <p>${customer?.walletAmount || 0}</p>
                      </li>
                    </ul>
                  </div>

                  <div className="common_profile_card address_Card mb-3">
                    <div className="head_withbutton">
                      <h5>{props.t("address")}</h5>
                      {/* <Button className="edit_btn send_msg">Edit</Button> */}
                    </div>
                    <p>NA</p>
                  </div>
                  <div>
                    <Button
                      className="common-btn"
                      onClick={() => onChangeStatus()}
                    >
                      {updateStatusLoader && (
                        <Spinner size="sm" color="white" />
                      )}
                      {customer?.status !== "blocked" ? "Block" : "Active"}
                    </Button>
                    {/* <Button
                      className="btn-danger"
                      onClick={() => goToSubscription()}
                      style={{"marginLeft":"4px", "padding":"9px"}}
                    >
                      Subscription
                    </Button> */}
                  </div>
                </Col>

                <Col lg={8} md={7} sm={12}>
                  <div className="common_profile_card">
                    <section className="latesttable_table">
                      <div className="latest_table_inner">
                        <h2 class="fillter_head pl-2 mb-2">
                          {props.t("parking")} {props.t("details")}
                        </h2>
                        <UserBookings userId={params?.id} />
                      </div>
                    </section>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </section>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = ({ customers }) => ({
  error: customers.error,
  loading: customers.loading,
  customer: customers.customer,
  updateStatusLoader: customers.updateStatusLoader,
});

const mapDispatchToProps = (dispatch) => ({
  getCustomer: (id) => dispatch(getCustomer(id)),
  updateUserStatusRequest: (data, callback) =>
    dispatch(updateUserStatusRequest(data, callback)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ViewCustomer));
