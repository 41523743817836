import { call, put, takeEvery, takeLatest, delay } from "redux-saga/effects";
import toastr from "toastr";
// Customer Redux States
import {
  ADD_PARKING_STRUCTURE_REQUEST,
  ASSIGN_PERMIT_CARD_REQUEST,
  DELETE_PARKING_STRUCTURE_REQUEST,
  GET_CARPARK_CODE_REQUEST,
  GET_PARKING_STRUCTURES_REQUEST,
  GET_PARKING_STRUCTURE_REQUEST,
  GET_USER_VEHICALS_REQUEST,
  LIST_PERMIT_CARDS_REQUEST,
  REMOVE_PERMIT_CARD_REQUEST,
  UPDATE_PARKING_STRUCTURE_REQUEST,
} from "./actionTypes";
import {} from "./actions";

import {
  addParkingStructureApi,
  assignPermitCardApi,
  getCarkParkCode,
  getParkingStructureApi,
  getParkingStructuresApi,
  getPermiCardsApi,
  getUserVehicalsApi,
  removeParkingStructureApi,
  removePermitCardApi,
  updateParkingStructureApi,
} from "helpers/backend_helper";

import * as actType from "./actions";

toastr.options = {
  newestOnTop: true,
  progressBar: true,
};

function* getParkingStructuresReq({ payload: { data, callback } }) {
  try {
    const response = yield call(getParkingStructuresApi, data);

    if (response.status == "failure") {
      return yield put(actType.getParkingStructuresFail(response.message));
    }

    yield put(actType.getParkingStructuresSuccess(response));
    callback && callback(response);
  } catch (error) {
    yield put(actType.getParkingStructuresFail(error));
  }
}

function* getParkingStructureReq({ payload: { data, callback } }) {
  try {
    console.log(data, "<======data");
    const response = yield call(getParkingStructureApi, data);

    console.log(response, "<========response");
    if (response.status == "failure") {
      return yield put(actType.getParkingStructureFail(response.message));
    }
    console.log("responseresponse saga", response);
    yield put(actType.getParkingStructureSuccess(response));
    callback && callback(response);
  } catch (error) {
    console.log(error, "<=======error");
    yield put(actType.getParkingStructureFail(error));
  }
}

function* addParkingStructureReq({ payload: { data, callback } }) {
  try {
    const response = yield call(addParkingStructureApi, data);

    if (response.status == "failure") {
      toastr.error(response.message);
      return yield put(actType.addParkingStructureFail(response.message));
    }

    yield put(actType.addParkingStructureSuccess(response));
    callback && callback(response);
    toastr.success("Added successfully!");
  } catch (error) {
    toastr.error("Something went wrong");
    yield put(actType.addParkingStructureFail(error));
  }
}

function* updateParkingStructureReq({ payload: { data, callback } }) {
  try {
    const response = yield call(updateParkingStructureApi, data);

    if (response.status == "failure") {
      toastr.error(response.message);
      return yield put(actType.updateParkingStructureFail(response.message));
    }
    yield put(actType.updateParkingStructureSuccess(response));
    callback && callback(response);
    toastr.success("Updated successfully!");
  } catch (error) {
    toastr.error("Something went wrong");
    yield put(actType.updateParkingStructureFail(error));
  }
}

function* deleteParkingStructureReq({ payload: { data, callback } }) {
  try {
    const response = yield call(removeParkingStructureApi, data);

    if (response.status == "failure") {
      toastr.error(response.message);
      return yield put(actType.deleteParkingStructureFail(response.message));
    }

    yield put(actType.deleteParkingStructureSuccess(response));
    toastr.success("Deleted successfully!");
    callback && callback();
  } catch (error) {
    toastr.error("Something went wrong!");
    yield put(actType.deleteParkingStructureFail(error));
  }
}

function* getPermitCardsReq({ payload: { data, callback } }) {
  try {
    const response = yield call(getPermiCardsApi, data);

    if (response.status == "failure") {
      toastr.error(response.message);
      return yield put(actType.getPermitCardsFail(response.message));
    }

    yield put(actType.getPermitCardsSuccess(response));
    callback && callback();
  } catch (error) {
    toastr.error("Something went wrong!");
    yield put(actType.getPermitCardsFail(error));
  }
}

function* assignPermitCardsReq({ payload: { data, callback } }) {
  try {
    const response = yield call(assignPermitCardApi, data);

    if (response.status == "failure") {
      toastr.error(response.message);
      return yield put(actType.assignPermitCardFail(response.message));
    }

    yield put(actType.assignPermitCardSuccess(response));
    toastr.success("Card assigned successfully!");
    callback && callback();
  } catch (error) {
    toastr.error("Something went wrong!");
    yield put(actType.assignPermitCardFail(error));
  }
}

function* removePermitCardsReq({ payload: { data, callback } }) {
  try {
    const response = yield call(removePermitCardApi, data);

    if (response.status == "failure") {
      toastr.error(response.message);
      return yield put(actType.removePermitCardFail(response.message));
    }

    yield put(actType.removePermitCardSuccess(response));
    toastr.success("Card removed successfully!");
    callback && callback();
  } catch (error) {
    toastr.error("Something went wrong!");
    yield put(actType.removePermitCardFail(error));
  }
}

function* getUserVehicalsReq({ payload: { data, callback } }) {
  try {
    const response = yield call(getUserVehicalsApi, data);

    if (response.status == "failure") {
      toastr.error(response.message);
      return yield put(actType.getUserVehicalsFail(response.message));
    }

    yield put(actType.getUserVehicalsSuccess(response));
    callback && callback(response);
  } catch (error) {
    toastr.error("Something went wrong!");
    yield put(actType.getUserVehicalsFail(error));
  }
}

function* getCarParkCodeSaga({ payload: { data, callback } }) {
  try {
    const response = yield call(getCarkParkCode, data);

    if (response.status == "failure") {
      toastr.error(response.message);
      return yield put(actType.getCarParkCodesFail(response.message));
    }

    yield put(actType.getCarParkCodesSuccess(response));
  } catch (error) {
    toastr.error("Something went wrong!");
    yield put(actType.getUserVehicalsFail(error));
  }
}

function* parkingsSaga() {
  yield takeLatest(GET_PARKING_STRUCTURES_REQUEST, getParkingStructuresReq);
  yield takeLatest(GET_PARKING_STRUCTURE_REQUEST, getParkingStructureReq);
  yield takeLatest(ADD_PARKING_STRUCTURE_REQUEST, addParkingStructureReq);
  yield takeLatest(UPDATE_PARKING_STRUCTURE_REQUEST, updateParkingStructureReq);
  yield takeLatest(DELETE_PARKING_STRUCTURE_REQUEST, deleteParkingStructureReq);

  yield takeLatest(LIST_PERMIT_CARDS_REQUEST, getPermitCardsReq);
  yield takeLatest(ASSIGN_PERMIT_CARD_REQUEST, assignPermitCardsReq);
  yield takeLatest(REMOVE_PERMIT_CARD_REQUEST, removePermitCardsReq);
  yield takeLatest(GET_USER_VEHICALS_REQUEST, getUserVehicalsReq);
  yield takeLatest(GET_CARPARK_CODE_REQUEST, getCarParkCodeSaga);
}

export default parkingsSaga;
