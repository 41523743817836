import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter,useParams } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import {  Form} from "reactstrap";


import Welcomegreet from "components/Welcomegreet";



//i18n
import { withTranslation } from "react-i18next";

import {  Col, Container, Row, Spinner } from "reactstrap";

//Import Breadcrumb

import { getSubscriptionUsersRequest } from "store/actions";
import UsersColumns from "./Columns";


const SubscribedCompanies = (props) => {
  const {
    history,
    subscriptionUsers,
    totalSubscriptionUsers,
    getSubscriptionUsersRequest,
    loading,
  } = props;
  const {planId} = useParams();
  const [filter, setFilter] = useState({
    orderBy: "createdAt",
    order: -1,
    page: 1,
    limit: 10,
  });

  const pageOptions = {
    sizePerPage: 10,
    totalSize: totalSubscriptionUsers,
    custom: true,
    onPageChange: (page) => setFilter((prevState) => ({ ...prevState, page })),
  };

  useEffect(() => {
    if(planId){
       getSubscriptionUsersRequest({ ...filter,subscription_id : planId });
    }
  }, [JSON.stringify(filter),planId]);


  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { searchText, sortField, sortOrder }) => {
  

    if (type === "sort") {
      if (sortField == "createdAt") {
        sortField = "date_created_utc";
      }

      if (sortOrder == "desc") {
        sortOrder = -1;
      } else if (sortOrder == "asc") {
        sortOrder = 1;
      }

      setFilter((prevState) => ({
        ...prevState,
        orderBy: sortField,
        order: sortOrder,
      }));
    }
  };



  return (
    <React.Fragment>

      <div className="page-content">
        <Container fluid>
          <Welcomegreet />
        
          <div className="fillter_ssection mt-5">
            <a
            href="javascript:void(0)"
            className="backto_censor"
            onClick={()=>props.history.push("/subscription")}
            >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
            >
                <path
                d="M17.77 3.77L16 2L6 12L16 22L17.77 20.23L9.54 12L17.77 3.77Z"
                fill="#2E3A59"
                />
            </svg>
            {props.t("subscribed")} {props.t("companies")}
            </a>
            <Form>
              <div className="latest_cust_table">
                {/* <Newlist/> */}
                 
                <Row>
                  <Col xs="12"> 
                        <PaginationProvider
                          pagination={paginationFactory(pageOptions)}
                        >
                          {({ paginationProps, paginationTableProps }) => (
                            <ToolkitProvider
                              keyField="_id"
                              data={subscriptionUsers}
                              columns={UsersColumns(props.t)}
                              bootstrap4
                              search
                            >
                              {(toolkitProps) => (
                                <React.Fragment>
                          
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive spinner-content mt-3">
                                  <BootstrapTable
                                    responsive
                                    remote
                                    bordered={false}
                                    striped={false}
                                    classes={
                                      "table table-centered table-nowrap"
                                    }
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    onTableChange={handleTableChange}
                                    {...paginationTableProps}
                                    defaultSorted={[
                                      {
                                        dataField: "createdAt",
                                        order: "desc",
                                      },
                                    ]}
                                    sort={{
                                      sortCaret: (order) =>
                                        order === "asc" ? (
                                          <span className="caret">
                                            <i className="bx bx-caret-up" />
                                          </span>
                                        ) : order === "desc" ? (
                                          <span className="caret">
                                            <i className="bx bx-caret-down" />
                                          </span>
                                        ) : null,
                                    }}
                                  />

                                  {loading && (
                                    <div className="spinner">
                                      <Spinner color="primary" />
                                    </div>
                                  )}
                                </div>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
            </Col>
          </Row>
              </div>
            </Form>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};



const mapStateToProps = ({ Subscriptions }) => ({
  loading: Subscriptions.loading,
  subscriptionUsers: Subscriptions.subscriptionUsers,
  totalSubscriptionUsers: Subscriptions.totalSubscriptionUsers,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getSubscriptionUsersRequest: (data) => {
      dispatch(getSubscriptionUsersRequest(data));
    }
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SubscribedCompanies))
);
