import { call, put, retry, takeEvery, takeLatest } from "redux-saga/effects";
import toastr from "toastr";

// Login Redux States
import {
  GET_SUBSCRIPTIONS_REQUEST,
  GET_SUBSCRIPTIONS_SUCCESS,
  GET_SUBSCRIPTIONS_FAIL,
  REMOVE_SUBSCRIPTIONS_FAIL,
  REMOVE_SUBSCRIPTIONS_SUCCESS,
  REMOVE_SUBSCRIPTIONS_REQUEST,
  DELETE_SUBSCRIPTION_PLAN_REQUEST,
  GET_SUBSCRIPTION_PLAN_REQUEST,
  UPDATE_SUBSCRIPTION_PLAN_REQUEST,
  ADD_SUBSCRIPTION_PLAN_REQUEST,
  GET_SUBSCRIPTIONS_PLAN_REQUEST,
  CANCEL_SUBSCRIPTION_REQUEST,
  GET_SUBSCRIPTION_USERS_REQUEST,
  ASSIGN_SUBSCRIPTION_TO_USER_REQUEST
} from "./actionTypes";
import {
  getSubscriptionsRequest,
  getSubscriptionsSuccess,
  getSubscriptionsFail,
  removeSubscriptionsRequest,
  removeSubscriptionsFail,
  removeSubscriptionsSuccess,
  addSubscriptionPlanFail,
  addSubscriptionPlanSuccess,
  deleteSubscriptionPlanFail,
  deleteSubscriptionPlanSuccess,
  updateSubscriptionPlanFail,
  updateSubscriptionPlanSuccess,
  getSubscriptionsPlanFail,
  getSubscriptionsPlanSuccess,
  getSubscriptionPlanFail,
  getSubscriptionPlanSuccess,
  cancelSubscriptionFail,
  cancelSubscriptionSuccess,
  getSubscriptionUsersFail,
  getSubscriptionUsersSuccess,
  assignSubscriptionToUserFail,
  assignSubscriptionToUserSuccess,
} from "./actions";

import {
  addSubscriptionPlanApi,
  cancelSubscriptionApi,
  deleteSubscriptionPlanApi,
  getSubscriptionPlanApi,
  getSubscriptionUsersApi,
  getSubscriptionsApi,
  getSubscriptionsPlanApi,
  removeSubscriptionsApi,
  updateSubscriptionPlanApi,
  assignSubscriptionToUserApi
} from "helpers/backend_helper";



function* getSubscriptionsReq({ payload: {data, callback } }) {
  try {
    const response = yield call(getSubscriptionsApi,data)

    if (response.status === "failure") {
      return yield put(getSubscriptionsFail(response.message))
    }
    yield put(getSubscriptionsSuccess(response,data))
  } catch (error) {
    yield put(getSubscriptionsFail(error.message))
  }
}



function* removeSubscriptionsReq({ payload: {data, callback } }) {
    try {
      const response = yield call(removeSubscriptionsApi,data)
  
      if (response.status === "failure") {
        toastr.error(response.message);
        return yield put(removeSubscriptionsFail(response.message))
      }
      yield put(removeSubscriptionsSuccess(data))
      toastr.success("Subscription removed successfully!");
      callback && callback()
    } catch (error) {
        toastr.error("Something went wrong!");
      yield put(removeSubscriptionsFail(error.message))
    }
  }
  

function* addSubscriptionPlanReq({ payload : {data,callback} }) {
  try {
    const response = yield call(addSubscriptionPlanApi, data)

    if (response.status == "failure") {
        toastr.error(response.message)
      return yield put(addSubscriptionPlanFail(response.message))
    }

    toastr.success("Plan added successfully")
    yield put(addSubscriptionPlanSuccess(response))
    callback && callback(response);
    return response;
  } catch (error) {
    toastr.error("Internal error!")
    yield put(addSubscriptionPlanFail(error))
  }
}


function* deleteSubscriptionPlanReq({ payload : {data,callback} }) {
    try {
      const response = yield call(deleteSubscriptionPlanApi, data)
  
      if (response.status == "failure") {
        toastr.error(response.message)
        return yield put(deleteSubscriptionPlanFail(response.message))
      }
  
      yield put(deleteSubscriptionPlanSuccess(response))
      toastr.success("Plan deleted successfully ")
      callback && callback(response);
      return response;
    } catch (error) {
        toastr.error("Internal error!")
      yield put(deleteSubscriptionPlanFail(error))
    }
  }

function* updateSubscriptionPlanReq({ payload : {data,callback} }) {
    try {
      const response = yield call(updateSubscriptionPlanApi, data)
  
      if (response.status == "failure") {
        toastr.error(response.message)
        return yield put(updateSubscriptionPlanFail(response.message))
      }
  
      yield put(updateSubscriptionPlanSuccess(response))
      toastr.success("Plan updated successfully ")
      callback && callback(response);
      return response;
    } catch (error) {
        toastr.error("Internal error!")
      yield put(updateSubscriptionPlanFail(error))
    }
  }


function* getSubscriptionsPlanReq({ payload : {data,callback} }) {
    try {
      const response = yield call(getSubscriptionsPlanApi, data)
  
      if (response.status == "failure") {
        return yield put(getSubscriptionsPlanFail(response.message))
      }
  
      yield put(getSubscriptionsPlanSuccess(response))
      callback && callback(response);
      return response;
    } catch (error) {
      yield put(getSubscriptionsPlanFail(error))
    }
  }


function* getSubscriptionPlanReq({ payload : {data,callback} }) {
    try {
      const response = yield call(getSubscriptionPlanApi, data)
  
      if (response.status == "failure") {
        return yield put(getSubscriptionPlanFail(response.message))
      }
  
      yield put(getSubscriptionPlanSuccess(response))
      callback && callback(response);
      return response;
    } catch (error) {
      yield put(getSubscriptionPlanFail(error))
    }
  }

  //cancel subscription

  
function* cancelSubscriptionReq({ payload : {data,callback} }) {
  try {
    const response = yield call(cancelSubscriptionApi, data)

    if (response.status == "failure") {
      toastr.error(response.message)
      return yield put(cancelSubscriptionFail(response.message))
    }

    yield put(cancelSubscriptionSuccess(response))
    callback && callback(response);
    toastr.success("Subscription cancel successfully!")
    return response;
  } catch (error) {
    yield put(cancelSubscriptionFail(error))
    toastr.error("Something went wrong!")
  }
}

function* getSubscriptionUsersReq({ payload: {data, callback } }) {
  try {
    const response = yield call(getSubscriptionUsersApi,data)

    if (response.status === "failure") {
      return yield put(getSubscriptionUsersFail(response.message))
    }
    yield put(getSubscriptionUsersSuccess(response))
    callback && callback(response);
  } catch (error) {
    yield put(getSubscriptionUsersFail(error.message))
  }
}

function* assignSubscriptionToUserReq({ payload: {data, callback } }) {
  try {
    const response = yield call(assignSubscriptionToUserApi, data)
    if (response.status === "failure") {
      return yield put(assignSubscriptionToUserFail(response.message))
    }
    yield put(assignSubscriptionToUserSuccess(response))
    callback && callback(response);
  } catch (error) {
    yield put(assignSubscriptionToUserFail(error.message))
  }
}


function* authSaga() {
  yield takeEvery(GET_SUBSCRIPTIONS_REQUEST, getSubscriptionsReq),
  yield takeEvery(REMOVE_SUBSCRIPTIONS_REQUEST, removeSubscriptionsReq),
  yield takeLatest(ADD_SUBSCRIPTION_PLAN_REQUEST, addSubscriptionPlanReq),
  yield takeLatest(UPDATE_SUBSCRIPTION_PLAN_REQUEST, updateSubscriptionPlanReq),
  yield takeLatest(GET_SUBSCRIPTIONS_PLAN_REQUEST, getSubscriptionsPlanReq),
  yield takeLatest(GET_SUBSCRIPTION_PLAN_REQUEST, getSubscriptionPlanReq),
  yield takeLatest(DELETE_SUBSCRIPTION_PLAN_REQUEST, deleteSubscriptionPlanReq),
  yield takeLatest(CANCEL_SUBSCRIPTION_REQUEST, cancelSubscriptionReq),
  yield takeLatest(GET_SUBSCRIPTION_USERS_REQUEST, getSubscriptionUsersReq),
  yield takeLatest(ASSIGN_SUBSCRIPTION_TO_USER_REQUEST, assignSubscriptionToUserReq)

}

export default authSaga;
